import React,{useState,useEffect} from 'react';
import { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import NavigationbarLight from '../components/NavigationbarLight';
import SignaturePad from 'react-signature-canvas';
import Nav from 'react-bootstrap/Nav';
import {RiEraserLine} from 'react-icons/ri'
import Navbar from 'react-bootstrap/Navbar';
import Toast from 'react-bootstrap/Toast'
import { dynamicPostApi,dynamicGetApi } from '../api';
import Button from "react-bootstrap/Button";
import Swal from 'sweetalert2';
import { useCookies } from 'react-cookie';
import Modal from 'react-bootstrap/Modal';
import styled from "styled-components";

import {
    BrowserRouter as Router,
    Switch,
    useNavigate,
    Route,
    Link
  } from "react-router-dom";
import moment from 'moment';
const IphoneBtn = styled.button`
  text-align: center;
  padding:10px;
  background-color:transparent;
  color:#ff1941;
  border: 0;
  border-top:1px solid #ccc;
  &:hover {
    background-color: #cccccc29
  }
  &:last-child(){
    border-top:0;
    border-radius:0 0 15px 15px !important;
  }
`;
function EmployeeTimeline(props) {
    const navigate=useNavigate();
    const [ShowHideInToast, setShowHideInToast] = useState(false);
    const [TimeTrackobj, setTimeTrackobj] = useState("");
    const [TimeTrackStatus, setTimeTrackStatus] = useState("");
    const [signatue, setSignatue] = useState("");
    const [cookies, setCookie,removeCookie] = useCookies(['continueAs','FirstLoad','EmployeeList']);
    const [ShowPasswordModel, setShowPasswordModel] = useState(false);
    const [ShowNewPasswordModel, setShowNewPasswordModel] = useState(false);
    useEffect(async() => {
        var TimeTrackId= localStorage.getItem("TimeTrackId")
        setTimeTrackStatus(localStorage.getItem("TimeTrackStatus"))
        if(!!TimeTrackId)
        {
            var data={
                id:TimeTrackId.toString()
            }
            var response = await dynamicPostApi(data,'api/Mobile/Home/StopStartTimeTrckingDetail')
            if(!!response&&response.status===200)
            {
                setTimeTrackobj(response.data)
            }
        }
    }, []);
    const CheckPassword=async(navigateto)=>{
        if(cookies.continueAs==='single'||cookies.Settings.use_employee_PIN===false)
        {
            save()
        }else{
            var data={
                EmpID:localStorage.getItem("SelectedEmpId"),
            }
            var response = await dynamicPostApi(data,'api/Mobile/Home/CheckSettingPin')
            if(!!response&&response.data===true)
            {
                setShowPasswordModel(true)
            }else if(!!response&&response.data===false)
            {
                setShowNewPasswordModel(true)
            }
        }
    }
    function dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }
    const save=async()=>{
        var file ="";
        if(signatue.getSignaturePad().points.length>0)
        {
            file = dataURLtoFile(signatue.toDataURL(), "signature.png");
        }
        var data={
            id:localStorage.getItem("TimeTrackId"),
            signature:file,
            EmployeeAutomaticBreaksID:cookies.SelectedPause!==undefined?cookies.SelectedPause.id:"",
            TrackingViea:'Stamp-App',
            CurrentDatetime:new Date().toUTCString()

        }
        var form_data = new FormData();
        for (var key in data) {
            if(typeof(data[key])==="object"&&!!data[key]&&data[key].length>0)
            {
                for (var i = 0; i < data[key].length; i++) {
                    form_data.append(key+[], data[key][i]);
                }
            }else{
                form_data.append(key, data[key]);
            }        
        }
        // if(data.signature!=blankCanves)
        if(signatue.getSignaturePad().points.length>0)
        {
            if(TimeTrackStatus==="newrecord")
            {
                var response = await dynamicPostApi(form_data,'api/Mobile/Home/StartTimeTrcking')
                if(!!response&&response.data===true)
                {
                    setCookie("TimeTrackStatusMessages","Start",{ path: '/' })
                    navigate("/homepage")
                }else{
                    Swal.fire({
                        icon: 'info',
                        title: 'Tracking via Roster...',
                      })
                }
            }
            else if(TimeTrackStatus==="endPause"||TimeTrackStatus==="startPause")
            {
                var response = await dynamicPostApi(form_data,'api/Mobile/Home/StopStartTimeTrcking')
                if(!!response&&!!response.data)
                {
                    if(TimeTrackStatus==='endPause')
                    {
                        var timestring="";
                        if(response.data.hours>0)
                        {
                            timestring=timestring+response.data.hours+" hour,";
                        } 
                        if(response.data.minutes>0)
                        {
                            timestring=timestring+response.data.minutes+" minute,";
                        } 
                        if(response.data.seconds>0)
                        {
                            timestring=timestring+response.data.seconds+" seconds";
                        }
                        setCookie("TimeTrackBreakDuration",timestring,{ path: '/' })
                    }
                    setCookie("TimeTrackStatusMessages",TimeTrackStatus,{ path: '/' })
                    navigate("/homepage")
                }
                else{
                    Swal.fire({
                        icon: 'info',
                        title: 'Tracking via Roster...',
                      })
                }
            }
            else if(TimeTrackStatus==="endtracking")
            {
              
                var response = await dynamicPostApi(form_data,'api/Mobile/Home/FinishTimeTrcking')
                if(!!response&&response.data===true)
                {
                    setCookie("TimeTrackIdForStatusInfo",localStorage.getItem("TimeTrackId"),{ path: '/' })
                    setCookie("TimeTrackStatusMessages",TimeTrackStatus,{ path: '/' })
                    navigate("/homepage")
                }
                else{
                    Swal.fire({
                        icon: 'info',
                        title: 'Tracking via Roster...',
                      })
                }
            }
            if(!!cookies.continueAs&&cookies.continueAs==='organisation')
            {
                var output = [];
                for (var i=0; i < cookies.EmployeeList.length ; ++i)
                    output.push(cookies.EmployeeList[i].empId);
        
                var dataList={
                    Ids:output
                }
                var responses = await dynamicPostApi(dataList,'api/Mobile/Home/EmployeeswithTimeTrackingDetail')
                if(!!responses&&responses.data.length>0)
                {
                    let y = [];
                    responses.data.map(x => y.push({...x , selected:true}))
                    setCookie('EmployeeList', y,{path:'/'})

                    // props.setReloadListtrigger(!props.ReloadListtrigger)
                    // props.setDetailView(false)
                }
            }
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Signature required...',
              })
        }
      
        // var response = await dynamicPostApi(data,'api/Mobile/Home/StopStartTimeTrckingDetail')
        // if(!!response&&response.status===200)
        // {
        //     setTimeTrackobj(response.data)
        // }
    }
    return(
        <>
         {
            ShowPasswordModel===true?
            <PasswordModel show={ShowPasswordModel} setshow={setShowPasswordModel} save={save}/>
            :null
        }
        {
            ShowNewPasswordModel===true?
            <NewPasswordModel show={ShowNewPasswordModel} setshow={setShowNewPasswordModel} save={save}/>
            :null
        }
        <Col lg={12}>
            <Navbar className="navigationLightDesgin">
                <Nav className="justify-content-between w-100">
                    <div className="d-flex">
                    <Nav.Item>
                         <Link to="/homepage" className='nav-link'>
                            <span className="textSpanDesgin btnDesgin" onClick={() => setShowHideInToast(!ShowHideInToast)}>Abort</span>
                        </Link>
                        {/* <Button variant="danger" className="btnDesgin btnt textSpanDesgin" onClick={()=>props.setEmployeetimeLineModel(false)}> Abort</Button> */}
                    </Nav.Item>
                    </div>
            
                    <div>
                   <Nav.Item>
                        <Link to="#" className='nav-link'>
                            <span className="textDesginLight">{TimeTrackStatus==="newrecord"?"Start time tracking":TimeTrackStatus==="endPause"?"End Pause":TimeTrackStatus==="startPause"?"Start Break":TimeTrackStatus==="endtracking"?"End time tracking":""} </span>
                        </Link>
                    </Nav.Item>
                    </div>

                    <div>
                    <Nav.Item>
                        <a href='javasecript:;' className='nav-link'>
                            <span className="textSpanDesgin" onClick={() => CheckPassword()}>Finished</span>
                            {/* {
                               ShowHideInToast ?
                                    <>  
                                         <Toast  show={ShowHideInToast} onClose={() => setShowHideInToast(!ShowHideInToast)} delay={3000}>
                                        <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
                                        </Toast>
                                   </>
                                : null
                            } */}
                        </a>
                    </Nav.Item>
                    </div>
                </Nav>
            </Navbar>
        </Col>
        <Container>
            <Col md={12}>
                <Row>
                    <span className="HeaderText">{localStorage.getItem("empName")}</span>
                </Row>
            </Col>

            <Col md={12}>
                <Row>
                    <Table className="border-top border-dark">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Beginning</th>
                                <th>End</th>
                                <th>Pause</th>
                                <th>Gross</th>
                                <th>Net</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {
                                    !!TimeTrackobj?
                                    <>
                                        <td>{TimeTrackobj.date}</td>
                                        <td>{TimeTrackobj.startTime}</td>
                                        <td>{TimeTrackStatus==="endtracking"?moment().format("HH:mm"):"" }</td>
                                        <td>{TimeTrackobj.pause} min</td>
                                        <td>{TimeTrackobj.gross} h</td>
                                        <td>{TimeTrackobj.net} h</td>
                                    </>
                                    :
                                    <>
                                        <td>{moment().format("DD.MM.YYYY")}</td>
                                        <td>{moment().format("HH:mm")}</td>
                                        <td></td>
                                        <td>0 min</td>
                                        <td>0.0 h</td>
                                        <td>0.0 h</td>
                                    </>

                                }
                            </tr>
                        </tbody>
                    </Table>
                </Row>
            </Col>
            <Col md={12}>
                <Row>
                    <Col lg={12}>
                    <div className="float-end">
                        <RiEraserLine onClick={()=>signatue.clear()} />
                    </div>
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
                <Row>
                    <Col lg={12}>
                    <div className="border-bottom border-dark signpad">
                        <SignaturePad ref={(ref) => setSignatue(ref)}/>
                    </div>
                    <span className="d-flex justify-content-end">Please Sign</span>
                    </Col>
                </Row>
            </Col>
        </Container>
        </>
        )
}

export default EmployeeTimeline;

function PasswordModel(props)
{
    const [pin, setPin] = useState(null);

  const handleClick =async () => {
    var data={
        EmpID:localStorage.getItem("SelectedEmpId"),
        pin:parseInt(pin)
    }
    var response = await dynamicPostApi(data,'api/Mobile/Home/VerifySettingPin')
    if(!!response&&response.data===true)
    {
        props.save();
        props.setshow(false)
    }
    else{
        Swal.fire({
            icon: 'error',
            title: 'Pin does not match.',
          })
    }
  }
  const onChangepin = (e) => {

        setPin(e.target.value.replace(/\D/g, ""))
    
    }

    return(
        <Modal  size="sm" show={props.show} onHide={() => props.setshow(false)} centered>
       
        <Modal.Body className=' px-0 py-1'>
            <div className='p-3'>
            <h4 className='fw-bold text-center'>PIN eingeben</h4>
            <p className='text-center' style={{fontSize:"14px"}}>
                Gib die Admin-PIN an, um den Admin-Bereich zu betreten 
            </p>
                <div className='p-3'> 
                   <input maxlength={4} type="text" className='form-control form-control-sm border-dark' value={pin} pattern="^0[1-9]|[1-9]\d$" onChange={(e)=>onChangepin(e)} placeholder='PIN' />
                </div>
            </div>
            <IphoneBtn className=' btn-sm w-100' onClick={() => handleClick()}>OK</IphoneBtn>
            <IphoneBtn className=' w-100'>PIN vergessen</IphoneBtn>
            <IphoneBtn className=' w-100 fw-bold' onClick={() =>  props.setshow(false)}>Abbrechen</IphoneBtn>

        </Modal.Body>
      
      </Modal>
    )
}

function NewPasswordModel(props)
{
    const [pin, setPin] = useState("");
    const [confirmpin, setConfirmpin] = useState("");

  const handleClick = async() => {
    if(!!pin&&!!confirmpin)
    {
        if(confirmpin===pin)
        {
            if(pin.length==4)
            {
                var data={
                    EmpID:localStorage.getItem("SelectedEmpId"),
                    pin:parseInt(pin)
                }
                var response = await dynamicPostApi(data,'api/Mobile/Home/CreateSettingPin')
                if(!!response&&response.data===true)
                {
                    props.save();
                    props.setshow(false)
                }
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Pin must be 4 numbers.',
                  })    
            }
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Pin & Confirm Pin are not match.',
              })
        }
    }else{
        Swal.fire({
            icon: 'error',
            title: 'Pin & Confirm Pin fields are required.',
          })
    }
  }

  const onCreatepin = (e) => {

    setPin(e.target.value.replace(/\D/g, ""))

  }

  const onConfirmpin = (e) => {

    setConfirmpin(e.target.value.replace(/\D/g, ""))

  }

    return(
        <Modal  size="sm" show={props.show} onHide={() => props.setshow(false)} centered>
       
        <Modal.Body className=' px-0 py-1'>
            <div className='p-3'>
            <h4 className='fw-bold text-center'>Create PIN</h4>
            <p className='text-center' style={{fontSize:"14px"}}>
                Gib die Admin-PIN an, um den Admin-Bereich zu betreten
            </p> 
                <div className='p-3'> 
                 <input maxlength={4} type="text" className='form-control form-control-sm border-dark' value={pin} pattern="^0[1-9]|[1-9]\d$" onChange={(e)=>onCreatepin(e)} placeholder='PIN' />
                </div>
                <div className='p-3'> 
                    <input maxlength={4} type="text" className='form-control form-control-sm border-dark' value={confirmpin} pattern="^0[1-9]|[1-9]\d$" onChange={(e)=>onConfirmpin(e)} placeholder='Confirm PIN' />
                </div>
            </div>
            <IphoneBtn className=' btn-sm w-100' onClick={() => handleClick()}>OK</IphoneBtn>
            <IphoneBtn className=' w-100 fw-bold' onClick={() =>  props.setshow(false)}>Abbrechen</IphoneBtn>

        </Modal.Body>
      
      </Modal>
    )
}
