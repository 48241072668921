const INITIAL_STATE = {
    isLoading : false,
    companyname : "",
    continueAs : ""
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case "isLoading":
            return { ...state, isLoading: action.data }
        case "companyname":
            return { ...state, companyname: action.data }
        case "continueAs":
            return { ...state, continueAs: action.data }

      default:
            return state
    }
}
