import axios from "axios";
import {APIServerURL} from './Config';
import Swal from 'sweetalert2';

  export async function LoginUser(e) {
    const data = {
        UserName: e.email,
        Password: e.password,
      };
    
      return await axios({
      method: "Post",
      url: APIServerURL + "api/Mobile/Account/login",
      data: data,
    }).then((response) => {
      if (response.status !== 200) {
        localStorage.removeItem("token");

        // localStorage.setItem("token", response.data.data.token);
        // getEmployeeName()
        // setIsAuthorizeUser(true)
        // setCookie("roles" ,response.data.userRoles,3)
        // if(response.data.userRoles.includes("Admin")){
        //   navigate('/companies/' + response.data.locationId)
        // } else {
         
        // }
      } 
      // else 
      // {
      //   localStorage.removeItem("token");
      //   // setIsAuthorizeUser(false)
      // }
      return response;
    }).catch(function (error) {
        
    });
  }
//   async function getEmployeeName()
//  {
//        var responsee = await dynamicGetApi('api/Mobile/Home/GetCurrentEmployeeName')
//         if(!!responsee && responsee.status===200)
//         {
//           localStorage.setItem("empName",responsee.data)
//         }
//  }
  export async function dynamicGetApi(Url) {
    const token = localStorage.getItem("token");
    return await axios({
      method: "Get",
      url: APIServerURL + Url,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        
        return response;
      })
      .catch(function (error) {
      
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          //window.location.reload();
        }
        else if(error.response.status === 500)
        {
          Swal.fire({
              icon: 'error',
              title: 'Something went wrong...',
            })
        }
      });
  }

  export async function dynamicPostApi(data,Url) {
    const token = localStorage.getItem("token");
    return await axios({
      method: "Post",
      url: APIServerURL + Url,
      headers: {
        Authorization: "Bearer " + token,
      },
      data:data
    })
      .then((response) => {
        
        return response;
      })
      .catch(function (error) {
      if(!!error.response)
      {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
         // window.location.reload();
        }
        else if(error.response.status === 500)
        {
          Swal.fire({
              icon: 'error',
              title: 'Something went wrong...',
            })
        }
      }
      });
  }
