import React , {useState} from 'react';
import { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Container, Form, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import AddIconRed from '../asset/images/NavbarIcons/AddIconRed.svg'
import CloudIconRed from '../asset/images/NavbarIcons/CloudIconRed.svg';
import InfoIconRed from '../asset/images/NavbarIcons/InfoIconRed.svg';
import SettingIconRed from '../asset/images/NavbarIcons/SettingIconRed.svg';
import {AiOutlineClose} from 'react-icons/ai';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Modal from 'react-bootstrap/Modal';
import greencircle from '../asset/images/NavbarIcons/greencircle.svg';
import {IonItemSliding, IonItem, IonLabel, IonItemOptions, IonItemOption} from '@ionic/react';
import SettingAutomaticBreaks from '../components/SettingAutomaticBreaks';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

const SettingPopover = (
    <Popover id="popover-basic"  className="SidebarPopover">
         <Popover.Content className="p-0">
                <Container fluid className="p-0">
                    <Col md={12}>
                        <Row className="mb-2">
                            <Col md={12}>
                                <div>
                                    <span className="PopupText">Existing Employee</span>
                                </div>
                            </Col>
                           
                        </Row>
                        <div ></div>
                        <Row className="border-top "> 
                            <Col md={12}>
                                <div className="mt-2">
                                    <span className="PopupText">New Colleague</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Popover.Content>
    </Popover>
    );


const names = ['Alica Gojani','Anna Behm','Cara Bianco','Diana Lauser','Ellen Klotz','Hoa Thi Le Trinh','Irene Gantert','Nadine Haring','Sven Gallenbach','Vanessa Fehr'];


function SettingCommunity() {
    const [ProfileChanges, setProfileChanges] = useState(false);
    const [CloudChangesData, setCloudChangesData] = useState(false);
    const [AnotherCloudFunction, setAnotherCloudFunction] = useState(false);
    const [EmployeeReaddCloud, setEmployeeReaddCloud] = useState(false);

    return(
        <>
        <Col md={12} className="border-bottom border-dark">
            <Navbar className="navigationDesginForm p-0">
                <Nav className="justify-content-start w-100">
                   
                   {/* All three col are used in navigation bar  */}
                    <Col md={3} className="border-end border-dark p-1">
                        <div className="d-flex">
                            <div>
                                <Nav.Item>
                                    <Link to="#"  className="p-1 mt-1">
                                        <img src={AddIconRed} onClick={() => setProfileChanges(!ProfileChanges)} className="NavIcon Normal"/>
                                    </Link>
                                </Nav.Item>
                            </div>
                            <div>
                                <Nav.Item>
                                    <Link to="#" className="p-2 mt-1">
                                        <span className="textCustForm ">Dres H u S Gallenbach</span>
                                    </Link>
                                </Nav.Item>
                            </div>
                            <div> 
                                <Nav.Item>
                                    <OverlayTrigger trigger="focus" placement="bottom" overlay={SettingPopover}>
                                        <Nav.Link  className="p-1 mt-1">
                                            <img src={AddIconRed} className="NewIconDesgin Normal"/>
                                        </Nav.Link>
                                    </OverlayTrigger>
                                </Nav.Item>
                            </div>
                        </div>
                    </Col>

                    <Col md={7}>                       
                        <div className="d-flex justify-content-center mt-2">
                            <Nav.Item>
                                <Link to="/homepage"  className='nav-link'>
                                <span className="textCustFormFirst ">Alicia Gojani</span>
                                </Link>
                            </Nav.Item>
                        </div>
                    </Col>

                    <Col md={2}>
                    <div className="d-flex justify-content-around">
                            <Nav.Item>
                                <Nav.Link>
                                    <img src={CloudIconRed} onClick={() => setCloudChangesData(!CloudChangesData)} className="OtherSideIcon Normal"/>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link to="/homepage"  className='nav-link'>
                                    <img src={InfoIconRed} className="OtherSideIcon Normal"/>
                                </Link>
                            </Nav.Item>

                            <Nav.Item>
                                    <Nav.Link>
                                        <img src={SettingIconRed} className="OtherSideIcon Normal"/>
                                    </Nav.Link>
                            </Nav.Item>
                        </div>                            
                    </Col>
                </Nav>
            </Navbar>
        </Col>
        
        <Container fluid> 
            <Col md={12}>
                <Row>
                {/* This col is for list of all employee */}
                <Tab.Container>
                    <Col md={3} className="border-end border-dark communityDesgin">
                        <Nav  variant="pills" className="flex-column custMainBox .nav-link.active" defaultActiveKey="link-1">
                             
                            <Nav.Item>
                                <Link className="CustNavDesgin nav-link"   to="/settingCommunitty" eventKey="link-1">Alicia Gojani</Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link className="CustNavDesgin nav-link"  to="/settingCommunitty" eventKey="link-2">Anna Behm<img src={greencircle} className="listIcon"/></Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link className="CustNavDesgin nav-link"  to="/settingCommunitty" eventKey="link-3">Cara Bianco</Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link className="CustNavDesgin nav-link"  to="/settingCommunitty" eventKey="link-4">Diana Lauser <img src={greencircle} className="listIcon"/></Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link className="CustNavDesgin nav-link"  to="/settingCommunitty" eventKey="link-5">Ellen Klotz</Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link className="CustNavDesgin nav-link"  to="/settingCommunitty" eventKey="link-6">Hoa Thi Le Trinh <img src={greencircle} className="listIcon"/></Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link className="CustNavDesgin nav-link"  to="/settingCommunitty" eventKey="link-7">Irene Gantert<img src={greencircle} className="listIcon"/></Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link className="CustNavDesgin nav-link"  to="/settingCommunitty" eventKey="link-8">Nadine Haring</Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link className="CustNavDesgin nav-link"   onClick={() => setEmployeeReaddCloud(!EmployeeReaddCloud)} eventKey="link-9">Sven Gallenbach</Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link className="CustNavDesgin nav-link"  onClick={() => setAnotherCloudFunction(!AnotherCloudFunction)} eventKey="link-10">Vanessa Fehr</Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Button className="btnDesginForm">Time recording mode</Button>
                            </Nav.Item>
                        </Nav>
                    </Col>
                   
                </Tab.Container>
                
                {/* This col is for floating banner type */}
                <Col md={9}>
                    <Row className="d-flex justify-content-end DesginRow mt-1">
                    <Col md={1} >
                            <AiOutlineClose className="IconsDesginSpecial"/>
                        </Col>
                    </Row>
                    <Row className="FloatBanner">
                        
                        <Col md={3}>
                            Image Will come here
                        </Col>
                        <Col md={4}>
                            <span>
                            Tell us how we use our apps
                            adapt to your needs!
                            </span>
                        </Col>
                        <Col md={5}>
                            <Button className="BtnEditDesgin">To the community forum</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="d-flex justify-content-end">
                            
                        </Col>
                    </Row>
                </Col>
                </Row>
            </Col>
        </Container>
        
        {/* Modal Pop-Up start from here */}
        <Modal  show={ProfileChanges} onHide={() => setProfileChanges(!ProfileChanges)}> 
           <Modal.Header className="HeadDesginFrom">
                <Container>
                    <Col md={12}>
                        <Row>
                            <Col md={3} className="d-flex justify-content-start">
                                <div>
                                    <span className="ModalText" onClick={() => setProfileChanges(!ProfileChanges)}>Abort</span>
                                </div>
                            </Col>

                            <Col md={5} className="d-flex justify-content-center">
                                <div>
                                    <span className="ModalTextHName">
                                        Alicia Gojani
                                    </span>
                                </div>
                            </Col>

                            <Col md={4} className="d-flex justify-content-end">
                                <div>
                                    <span className="ModalText" onClick={() => setProfileChanges(!ProfileChanges)}>To Back Up</span>
                                </div>
                                
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Modal.Header>
            <Modal.Body className="tModalDesginFrom" >
                <Container fluid className="CustRowModal">
                    <Col md={12}>
                        <Row className="border-bottom border-dark">
                            <Col md={2}>
                                <span className="spanTextForm">Name</span>
                                
                            </Col>
                            <Col md={10}>
                                <Form>
                                    <Form.Control type="text" className="CustDesginTextBox CustTextBoxForm" placeholder="Alicia Gojani"/>
                                </Form>
                                <div ></div>
                            </Col>
                            
                        </Row>
                    </Col>
                        
                    <Col md={12}>
                        <Row>
                            <Col md={2}>
                                <span  className="spanTextForm">Pin</span>
                            </Col>
                            <Col md={10}>
                                <Form>
                                    <Form.Control type="text" className="CustDesginTextBox CustTextBoxForm" placeholder="8807"/>
                                </Form>
                            </Col>
                        </Row>
                    </Col>   
                </Container>

                <Container fluid className="p-0">
                    <Row>
                    <Col md={12}>
                        <Button className="CustButtonForm">Delete Employee</Button>
                    </Col>
                    </Row>
                </Container>
            </Modal.Body>
            </Modal>


        {/* This Modal pop up is of Cloud Sync */}
        <Modal  show={CloudChangesData} onHide={() => setCloudChangesData(!CloudChangesData)}> 
           <Modal.Header className="SecondModalDesgin">
             <Container fluid>
                 <Col md={12}>
                     <Row>
                        <Col md={8}>
                            <div>
                                <span className="d-flex justify-content-end">Synchronize employees</span>
                            </div>
                        </Col>

                        <Col md={4}>
                            <div>
                                <span className="ModalText d-flex justify-content-end">Finished</span>
                            </div>
                        </Col>
                     </Row>
                 </Col>
             </Container>
            </Modal.Header>
            <Modal.Body className="MainModalForm">
                <Container>
                    <Col md={12}>
                        <Row>
                            <Col md={4}>
                                <span>All Employees</span>
                            </Col>
                            <Col md={8}>
                                <Form.Check className="CustCheckForm d-flex justify-content-end" type="checkbox"/> 
                            </Col>
                        </Row>
                    </Col>
                    <div className="border-bottom mt-3"></div>

                    <Col md={12}>
                        <Row className="mt-4">
                            <Col md={4}>
                                <span>Vanessa Fehr</span>
                            </Col>
                            <Col md={8}>
                                <Form.Check className="CustCheckForm d-flex justify-content-end" type="checkbox"/> 
                            </Col>
                        </Row>
                    </Col>

                </Container>
            </Modal.Body>
        </Modal>

        {/* This Modal Pop up of Another Cloud Function */}
        <Modal size="sm" show={AnotherCloudFunction} onHide={() => setAnotherCloudFunction(!AnotherCloudFunction)} centered> 
           {/* <Modal.Header className="SecondModalDesgin">
            </Modal.Header> */}
            <Modal.Body className="MainModalAnotherCloud p-1">  
                <Container fluid className="CustModalcloudFunction">
                    <Col sm={12}>
                        <Row>
                            <Col sm={12} className="">
                                <div className="sm-12">
                                    <span className="modalSpanTextFirstPage">Separate employees</span><br/>
                                    <span>
                                        Would you like this employee's
                                        connection to Papershift Cloud <br/>
                                        separate?
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} className="">
                        <Row className="mt-4 border-top">
                            <Col sm={6} >
                                <Row className="border-right CloudBtnDesgin">
                                    <Button onClick={() => setAnotherCloudFunction(!AnotherCloudFunction)} className="modalButtonTextFormFirst">Abort</Button>
                                </Row>
                            </Col>
                            
                            <Col sm={6}>
                                <Button onClick={() => setAnotherCloudFunction(!AnotherCloudFunction)} className="modalButtonTextFormFirst">Separate</Button>
                            </Col>
                        </Row>
                    </Col>

                </Container>
            </Modal.Body>
        </Modal>

        
        {/* This is Modal pop up for Readd Employee in Cloud  */}
        <Modal size="sm" show={EmployeeReaddCloud} onHide={() => setEmployeeReaddCloud(!EmployeeReaddCloud)} centered> 
           {/* <Modal.Header className="SecondModalDesgin">
            </Modal.Header> */}
            <Modal.Body className="MainModalAnotherCloud p-1">  
                <Container fluid className="CustModalcloudFunction">
                    <Col sm={12}>
                        <Row>
                            <Col sm={12} className="">
                                <div className="sm-12">
                                    <span className="modalSpanTextFirstPage">Connect again</span><br/>
                                    <span>
                                        Would you like to reconnect this
                                        employee to Papershift Cloud?
                                        
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} className="">
                        <Row className="mt-4 border-top">
                            <Col sm={6} >
                                <Row className="border-right CloudBtnDesgin">
                                    <Button onClick={() => setEmployeeReaddCloud(!EmployeeReaddCloud)} className="modalButtonTextFormFirst">Abort</Button>
                                </Row>
                            </Col>
                            
                            <Col sm={6}>
                                <Button onClick={() => setEmployeeReaddCloud(!EmployeeReaddCloud)} className="modalButtonTextFormFirst">Connect</Button>
                            </Col>
                        </Row>
                    </Col>

                </Container>
            </Modal.Body>
        </Modal>

        </>
    )
}

export default SettingCommunity;

