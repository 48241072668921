import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { login } from '../store/actions/appActions';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { FiUser } from "react-icons/fi"
import { CgOrganisation } from "react-icons/cg"
import { setContinueAs } from '../store/actions/appActions';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { LoginUser } from '../api';
import { useNavigate } from 'react-router-dom';
import { dynamicPostApi, dynamicGetApi } from '../api';
import Swal from 'sweetalert2';
import { PaperShiftWebURL } from '../Config';
// const Cryptr = require('cryptr');
// const cryptr = new Cryptr('myTotallySecretKey');

const WhiteBg = styled.div`
background: #fff;
width: 100%;
position: ${props => props.home === true ? "fixed" : "absolute"};
top: -57px;
bottom: 0;
left: 0;
right: 0;
z-index:999
`
const TopBottomMargin = styled.div`
margin: 80px 20%;

`
const Abmeldenbtn = styled.button`
background : #E9455D;
color:#fff;
padding:10px;
border: 1px solid #E9455D;
width:100%;
border-radius:5px;
margin-top:20px;
&:hover{
    background:#cb3b50
}
`
const Abmeldenbtnsuccess = styled.button`
background : #48c9b0;
color:#fff;
padding:10px;
border: 1px solid #48c9b0;
width:100%;
border-radius:5px;
margin-top:20px;
&:hover{
    background:#48c9b0
}
`
const BottomText = styled.div`
margin-top: 70px;
text-align: center;
color:#ababab
`



function PaperShiftCloud(props) {

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const [SelectionModalshow, setSelectionModalshow] = useState(false);
    const [IsLogin, setIsLogin] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['continueAs', 'AccountEmail', 'Settings']);
    const [confirmLogout, setConfirmLogout] = useState(false);


    let navigate = useNavigate();
    const login = async () => {
        if (!!email && !!password) {
            var data = {
                email: email,
                password: password
            }
            var response = await LoginUser(data)
            debugger

            if (!!response && response.status === 200 && response.data.message === 'true') {
                localStorage.setItem("token", response.data.data.token);
                localStorage.setItem("empName", response.data.employeeName)

                setCookie("EmployeeID", response.data.data.employeeId, 3)

                var setting = await getSetting(response.data.data.employeeId)
                // if(response.data.userRoles.length===1&&response.data.userRoles[0].label==="Employee")
                // {
                //     if(setting.employee_app===false)
                //     {
                //         dynamicGetApi("api/Mobile/Home/GetCurrentEmployeeName")
                //         logout()
                //         return
                //     }
                // }
                // var data={
                //     id:cookies.EmployeeID
                // }
                // var responsee = await dynamicGetApi('api/Mobile/Home/Getroles')
                // if(!!responsee && responsee.status===200)
                // {
                debugger
                if (response.data.data.userRoles.split(",").find(x => x === 'Account admin' || x === "Workspace admin")) {
                    setSelectionModalshow(true)
                    setCookie('FirstLoad', true, { path: '/' })
                    // setCookie('EmployeeID', cookies.EmployeeID, { path: '/' })
                    setCookie('AccountEmail', email, { path: '/' })
                }
                else {
                    setCookie('continueAs', "single", { path: '/' });
                    setCookie('FirstLoad', true, { path: '/' })
                    // setCookie('EmployeeID', cookies.EmployeeID, { path: '/' })
                    setCookie('AccountEmail', email, { path: '/' })
                    navigate("/")
                }
                // var Setting = await dynamicGetApi('api/Mobile/Home/GetSettings')
                // if(!!Setting && Setting.status===200)
                // {
                //     debugger
                // }
                // }
                // setSelectionModalshow(true)

                // setCookie('FirstLoad', true, { path: '/' })
                // setCookie('EmployeeID', response.data.employeeId, { path: '/' })
                // setCookie('AccountEmail', email, { path: '/' })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: response.data.message,
                })
            }
        } else {
            Swal.fire({
                icon: 'info',
                title: 'Email & password are required...',
            })
        }
    }
    const getSetting = async (id) => {
        var data = {
            id: id.toString()
        }
        var responses = await dynamicPostApi(data, 'api/Mobile/GeneralSetting/GetGeneralSetting')
        debugger

        if (responses.status === 200) {
            // const encryptedString = cryptr.encrypt(JSON.stringify(responses.data));
            setCookie('Settings', responses.data, { path: '/' })
            return responses.data
        }
    }
    const logout = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("empName")
        removeCookie('FirstLoad', { path: '/' })
        removeCookie('EmployeeID', { path: '/' })
        removeCookie('locationId', { path: '/' })
        removeCookie('CompanyName', { path: '/' })
        removeCookie('continueAs', { path: '/' })
        removeCookie('EmployeeList', { path: '/' })
        removeCookie('Settings', { path: '/' })
        removeCookie('SelectedPause', { path: '/' })
        removeCookie('AccountEmail', { path: '/' })
        navigate("/")
    }
    useEffect(() => {
        var token = localStorage.getItem("token")
        if (!!token) {
            setIsLogin(true)
        }
    }, []);
    const forgetpassword = () => {
        window.location.href = PaperShiftWebURL
    }
    useEffect(() => {
        setEmail(cookies.AccountEmail)
    }, [cookies.AccountEmail]);
    return (
        <>
            {
                !!SelectionModalshow ? <SelectionModal show={SelectionModalshow} handleModal={setSelectionModalshow} /> : null
            }
            {
                !!confirmLogout ? <LogOutConfirmationModal show={confirmLogout} handleModal={setConfirmLogout} logout={logout} /> : null
            }
            <WhiteBg home={!!props.home}>
                <TopBottomMargin>
                    <div className='text-center'>
                        <img src="https://www.papershift.com/content/themes/papershift/img/papershift-logo.svg" className='img-fluid' width={250} alt="" />
                    </div>
                    <div className='mt-5'>
                        <div className='form-group mb-3'>
                            <label className='mb-2'>E-Mail</label>
                            <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} className='form-control form-control-sm form-control-solid' />
                        </div>

                        {
                            IsLogin === false ?
                                <>
                                    <div className='form-group mb-3'>
                                        <label className='mb-2'>Password</label>
                                        <input type="password" onChange={(e) => setPassword(e.target.value)} className='form-control form-control-sm form-control-solid' />
                                    </div>
                                    <Abmeldenbtnsuccess onClick={() => login()}>
                                        Anmelden
                                    </Abmeldenbtnsuccess>
                                </>
                                :
                                <Abmeldenbtn onClick={() => setConfirmLogout(true)}>
                                    Abmelden
                                </Abmeldenbtn>
                        }

                    </div>
                    {
                        IsLogin === false ?
                            <>
                                <BottomText onClick={() => forgetpassword()}>forget password</BottomText>
                                <BottomText>AGB</BottomText>
                            </>
                            : null
                    }
                </TopBottomMargin>

            </WhiteBg>
        </>
    );
}

export default PaperShiftCloud;


const SingleUserButton = styled.button`
width:100%;
background:transparent;
border: none;
border-right : 1px solid #ccc;
margin:0;
font-size:14px;
padding:10px 15px;
border-bottom-left-radius : 20px;
&:hover{
    background:#f1f1f1
}
`
const OrganisationButton = styled.button`
width:100%;
background:transparent;
border: none;
margin:0;
font-size:14px;
padding:10px 15px;
border-bottom-right-radius:20px;
&:hover{
    background:#f1f1f1
}
`
const CenterIcon = styled.div`
font-size:24px;
`

function SelectionModal(props) {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['continueAs']);


    let check = useSelector(state => state.app.continueAs);

    return (
        <Modal size='sm' centered show={props.show} onHide={() => props.handleModal(false)}>
            <Modal.Body className='text-center py-2'>Continue as {check}</Modal.Body>
            <Modal.Footer className='p-0 d-flex flex-nowrap'>
                <SingleUserButton onClick={() => { props.handleModal(false); dispatch(setContinueAs("single")); setCookie('continueAs', "single", { path: '/' }); navigate("/") }}>
                    <CenterIcon>
                        <FiUser />
                    </CenterIcon>
                    Single User
                </SingleUserButton>
                <OrganisationButton onClick={() => { props.handleModal(false); dispatch(setContinueAs("organisation")); setCookie('continueAs', "organisation", { path: '/' }); navigate("/") }}>
                    <CenterIcon>
                        <CgOrganisation />
                    </CenterIcon>
                    Organisation
                </OrganisationButton>
            </Modal.Footer>
        </Modal>
    );
}

const LogoutConfirmBtn1 = styled.button`
width:100%;
background:transparent;
border: none;
border-right : 1px solid #ccc;
margin:0;
font-size:16px;
padding:10px 15px;
border-bottom-left-radius : 20px;
color:#E9455D;
&:hover{
    background:#f1f1f1
}
`
const LogoutConfirmBtn2 = styled.button`
width:100%;
background:transparent;
border: none;
margin:0;
font-size:16px;
padding:10px 15px;
border-bottom-right-radius:20px;
color:#E9455D;
&:hover{
    background:#f1f1f1
}
`

function LogOutConfirmationModal(props) {

    return (
        <Modal size='sm' centered show={props.show} onHide={() => props.handleModal(false)}>
            <Modal.Body className='text-center py-2'>Bist Du sichar, dass Du Dich abenden Mochest?</Modal.Body>
            <Modal.Footer className='p-0 d-flex flex-nowrap'>
                <LogoutConfirmBtn1 onClick={() => props.handleModal()}>
                    Abbrechen
                </LogoutConfirmBtn1>
                <LogoutConfirmBtn2 onClick={() => props.logout()}>
                    Abmelden
                </LogoutConfirmBtn2>
            </Modal.Footer>
        </Modal>
    );
}


