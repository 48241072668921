



// export const APIServerURL="https://localhost:44362/"
// export const PaperShiftWebURL="http://localhost:3000/"


export const APIServerURL = "https://api.basta-shift.de/"
export const PaperShiftWebURL = "https://app.basta-shift.de/"




// export const APIServerURL="https://api.right-shift.de/"
// export const PaperShiftWebURL="https://app.right-shift.de/"

// export const APIServerURL="https://papershift.startech.pk/"
// export const PaperShiftWebURL="https://papershift.surge.sh/"