import React from 'react';
import { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';


function SettingTerms() {
    return(
        <>
          <Container>
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            <div>
                                <span className="SpanTextSameDesgin">General terms and conditions of business</span>                        
                            </div>                        
                        </Col>
                    </Row>    

                    <Row className="mt-2">
                        <Col md={12}>
                            <div>
                                <span className="SpanTextDiff">. 1 Subject of the General Terms and Conditions - GTC</span>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col md={12}>
                            <div>
                                <span>
                                    (1) Papershift GmbH (hereinafter referred to as "Papershift") sets up
                                    www.papershift.com has a website available (hereinafter referred to as "the website"
                                    called), via which the users after registering for themselves and their business online
                                    Can make duty scheduling, vacation planning and time recording.
                                    (2) These terms and conditions regulate the provision of the services by Papershift
                                    and the use of these services by you as properly registered
                                    Attendees.
                                    (3) Information on Papershift can be found in the imprint on the website.
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col md={12}>
                            <div>
                                <span className="SpanTextDiff">. 2 Changes to the Conditions of Participation and Terms of Use</span>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col md={12}>
                            <div>
                                <span>
                                Papershift reserves the right to change these terms and conditions at any time if there is an important reason
                                is present. Papershift will notify the participant in writing of such changes by
                                Fax or email at least 30 days before the planned entry into force of the
                                Notify changes. Papershift will show which specific
                                Passages of the terms and conditions are to be changed. Unless the participant is within

                                30 days from receipt of the notification contradicts and the use of the
                                If you continue to use the website even after the objection period has expired, the
                                Changes from the deadline agreed as effective. In the event of an objection, the
                                Contract continued under the previous conditions. The change notice will
                                Papershift inform the participant of their right to object and the consequences.
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                
          </Container>
        </>
    )
}

export default SettingTerms;


