import React from 'react';
import { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import LeftSideArrowIcon from '../asset/images/NavbarIcons/LeftSidePreviousIcon.svg';
import RightSideArrowIcon from '../asset/images/NavbarIcons/RightSideNextIcon.svg';
import LockCenterIcon from '../asset/images/NavbarIcons/LockCenterIcon.svg';
import ReloadPageIcon from '../asset/images/NavbarIcons/ReloadPageIcon.svg';
import ExportIcon from '../asset/images/NavbarIcons/ExportIcon.svg';
import SafariBrowserIcon from '../asset/images/NavbarIcons/SafariBrowserIcon.svg';
import '../css/Navbar.css';
import '../css/site.css';
import { Col, Container, Row } from 'react-bootstrap';
import '../css/Navbar.css';
import Clock from 'react-live-clock';
import Link from 'react-bootstrap/NavLink';
import ListGroup from 'react-bootstrap/ListGroup';
import greencircle from '../asset/images/NavbarIcons/greencircle.svg';
import Modal from 'react-bootstrap/Modal';


  


class CommunityMainPage extends Component{

    constructor(){
        super();
        this.state = {
            TermsandConditionPage : true,
        }
    }

    handleModalEditProfile() { 
        this.setState({ TermsandConditionPage: !this.state.TermsandConditionPage}) 
    }


    render(){
        return(
            <>
            {/* THis code is of navigation bar  */}
                 <Col lg={12}>
                    <Navbar className="navigationDesgin">
                    <Nav className="justify-content-between w-100">
                        <div className="d-flex">
                        <Nav.Item>
                            <Link to="/setting">
                                <span className="NavCustDesginNew">Fertig</span>
                            </Link>
                        </Nav.Item>
                        
                        <Nav.Item>
                            <Link to="#">
                                <img src={LeftSideArrowIcon} className="NavIcon Normal CustIconDesgin"/>
                            </Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Link to="#">
                                <img src={RightSideArrowIcon} className="NavIcon Normal"/>
                            </Link>
                        </Nav.Item>

                        </div>

                        
                        <div className="d-flex justify-content-around">
                        <div className="flex">
                       <Nav.Item>
                            <Link to="#" >
                            <img src={LockCenterIcon} className="NavIcon Normal"/>
                            </Link>
                        </Nav.Item>
                        </div>

                        <div>
                        <Nav.Item>
                            <Link to="#" >
                                <span className="textDesgin">community.papershift.com</span>
                            </Link>
                        </Nav.Item>
                        </div>
                        </div>
                        
                        <div className="d-flex justify-content-end">
                        <div>
                        <Nav.Item>
                            <Link to="#">
                                <img src={ReloadPageIcon} className="NavIcon Normal CustIconDesgin"/>
                            </Link>
                        </Nav.Item>
                        </div>
                        <div>
                        <Nav.Item>
                            <Link to="#">
                                <img src={ExportIcon} className="NavIcon Normal CustIconDesgin"/>
                            </Link>
                        </Nav.Item>
                        </div>
                        <div>
                        <Nav.Item>
                            <Link to="#">
                                <img src={SafariBrowserIcon} className="NavIcon Normal CustIconDesgin"/>
                            </Link>
                        </Nav.Item>
                        </div>
                        </div>
                    </Nav>
                </Navbar>
                </Col>   
            

            <Container fluid>
                <Col md={12}>
                    <Row>
                        <Col md={4} className="mb-3 border-end border-dark">
                            <ListGroup variant="flush" className="listDesign">
                                <ListGroup.Item>
                                    <Link to="/details" className="NavLink">Alicia Gojani</Link>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Link to="#" className="NavLink">Anna Behm <img src={greencircle} className="listIcon"/></Link>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Link to="#" className="NavLink">Cara Bianco</Link>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Link to="#" className="NavLink">Diana Lauser <img src={greencircle} className="listIcon"/></Link>
                                </ListGroup.Item>
                                
                                <ListGroup.Item>
                                    <Link to="#" className="NavLink">Ellen Klotz</Link>                                    
                                </ListGroup.Item>
                                
                                <ListGroup.Item>
                                    <Link to="#" className="NavLink">Hoa Thi Le Trinh <img src={greencircle} className="listIcon"/></Link>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Link to="#" className="NavLink" onClick={() => this.handleModalAfterFullDetailBreak("ModalAfterFullDetail")}>Irene Gantert <img src={greencircle} className="listIcon"/></Link>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Link to="#" className="NavLink" onClick={() => this.handleModalAfterPauseEnded("ModalAfterPauseEnded")}>Nadine Haring</Link>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Link to="#" className="NavLink" onClick={() => this.handleModalAfterPauseStarted("ModalAfterPauseStarted")}>Sven Gallenbach</Link>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Link to="#" className="NavLink" onClick={() => this.handleModalAfterSignDone("ModalAfterSignDone")}>Vanessa Fehr</Link>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>

                        <Col md={8} className="mb-3 ">
                            <Row>
                                <Clock format={'HH:mm'} ticking={true}  className="desginClock d-flex justify-content-center "/>

                                <div className="d-flex justify-content-center textdesgin">
                                    <span>Tap on your name on the left in the list select an action and confirm it </span>
                                </div>
                            </Row>
                        </Col> 
                    </Row>
                </Col>
               </Container>


            {/* This is Modal Pop up of terms and condition */}
            <Modal  show={this.state.TermsandConditionPage} size="lg"> 
               <Modal.Header className="HeadDesginMainPage">
                    <Container>
                        <Col md={12}>
                            <Row>

                                <Col md={7} className="d-flex justify-content-end">
                                    <div>
                                        <span className="ModalTextNameMainPage">
                                            Guided access
                                        </span>
                                    </div>
                                </Col>

                                <Col md={5} className="d-flex justify-content-end">
                                    <div>
                                        <Link to="/settingcommunity" className="ModalTextMainPage">
                                        <span className="ModalTextMainPage">Finished</span></Link>
                                    </div>
                                    
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </Modal.Header>
                <Modal.Body className="ModalBodyMainPage">
                   <Container>
                       <Col md={12}>
                           <Row>
                               <Col md={12}>
                                   <span className="spanTextMainPageHead">
                                            Activate the guided mode!
                                    </span>
                               </Col>
                           </Row>

                           <Row className="mt-3">
                               <Col md={12}>
                                   <span className="spanTextMainPageSubHead">
                                        Enable Guided Acess at the Home button to deactivate
                                   </span>
                               </Col>
                           </Row>

                           <Row className="mt-3">
                               <Col md={12}>
                                   <span className="spanTextModalLines">
                                    The guided mode prevents employees from goinginto admin mode
                                    and edit settings and past time records there. In the guided mode
                                    it is aslo not possible to exit the app and e.g. B. to start the 
                                    browser
                                   </span>
                               </Col>
                           </Row>

                           <Row className="mt-3">
                               <Col md={12}>
                                   <span className="SpanTextModalSubLines">1.&nbsp; Activate the settings for the guided mode<br/></span>
                                   <span className="SpanTextModalSubHeadingLines">Activate the function in the Settings app of your iPad via General - <br/></span>
                                   <span className="SpanTextModalSubHeadingLines">Accessibility - Guided Access</span>
                                    
                               </Col>
                           </Row>

                           <Row className="mt-3">
                               <Col md={12}>
                                <span className="SpanTextModalSubLines">2.&nbsp;Activate the guided mode<br/></span>
                                <span className="SpanTextModalSubHeadingLines">Go back to the Papershift app.You can now activate the guided mode by <br/></span>
                                <span className="SpanTextModalSubHeadingLines">pressing the home button on ypur iPad three times in row.</span>
                               </Col>
                           </Row>
                       </Col>
                   </Container>
                </Modal.Body>
                </Modal>


            </>
        )
    }
}export default CommunityMainPage;