import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/react-bootstrap/dist/react-bootstrap';
import './css/site.css';
import Layout from './components/layout';
import reportWebVitals from './reportWebVitals';
import {ApiProvider} from "./Context/apiProvider" 
import {CookiesProvider} from "react-cookie";
import store from "./store/store";
import { Provider } from 'react-redux'

ReactDOM.render(
  <>
  <CookiesProvider>
    <BrowserRouter>
      <Provider store={store}>
      <ApiProvider>
      <Layout/>
      </ApiProvider>
      </Provider>
    </BrowserRouter>

  </CookiesProvider>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
