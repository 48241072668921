import { createContext } from "react";

export const ApiContext = createContext({
    passwordModal:false,
    showPasswordModal : () => {},
    setSignalRroles:(data)=>{}
});
export const SignalRContext = createContext({
    RoleHubConnection:null,
    setRoleHubConnection:(connection)=>{}
    
  });