import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import GlobeIcon from '../asset/images/NavbarIcons/globe.svg';
import CancelMark from '../asset/images/NavbarIcons/cancelIconWhite.svg';
import { Container, Row } from 'react-bootstrap';
import SettingGenral from '../components/SettingGenral';
import SettingTag from '../components/SettingTags';
import SettingAutomaticBreaks from '../components/SettingAutomaticBreaks';
import Tab from 'react-bootstrap/Tab';
import NavLink from 'react-bootstrap/NavLink';
import {TextField} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { BsSearch } from 'react-icons/bs';
import { AiOutlineGlobal } from 'react-icons/ai';
import '../css/Navbar.css';
import '../css/site.css';


class SettingTagsSecond extends Component{
    render(){
        return(
            <>
            
            </>
        )
    }
}export default SettingTagsSecond;