import { Component, useState} from "react";
import React,{useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container'
import '../css/Navbar.css';
import Clock from 'react-live-clock';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import NvaigationBar from '../components/Navigationbar';
import greencircle from '../asset/images/NavbarIcons/greencircle.svg';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { BsSearch } from 'react-icons/bs';
import '../css/site.css';
import styled from 'styled-components';
import {TextField, useForkRef} from '@material-ui/core';
import ReactMulitSelectDropdown from 'react-multiselect-dropdown-bootstrap';
import UsersList from "../components/usersSidebarList";
import { dynamicGetApi,dynamicPostApi } from "../api";
import moment from "moment";
import EmployeeTimeline from "./EmployeeTimeline";
import { useCookies } from "react-cookie";
import { CgChevronLeft } from 'react-icons/cg'; 
import Swal from 'sweetalert2';
import Switch from 'react-ios-switch';
import { Portal } from 'react-portal';

import {
    BrowserRouter as Router,
    useNavigate,
    Link
  } from "react-router-dom";
import { MdEditNote } from "react-icons/md";
 
const TagsSelectArray = [
    { key: "jc", label: "Junior Chef" },
    { key: "sc", label: "Senior Chef" },
    { key: "r1", label: "Room 1" },
    { key: "r2", label: "Room 2" },
    { key: "r3", label: "Room 3" },
  ];

  const BottomReactDivWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #48c9b0;
  padding: 15px;
  `
const BottomDivTableBg = styled.div`
background: #1C1B1B;
padding: 10px;
border-radius: 10px;
`

function EmployeeDetails(props) {
    let navigate=useNavigate()
    const [cookies,setCookie] = useCookies(['continueAs','FirstLoad','EmployeeID']);
    const [empId, setEmpId] = useState(0);
    const [empName, setEmpName] = useState("");
    const [WorkingTime, setWorkingTime] = useState("");
    const [BreakTime, setBreakTime] = useState("");
    const [TimeTrackId, setTimeTrackId] = useState(0);
    const [RunningStatus, setRunningStatus] = useState("");
    const [TrackingDetailmodel, setTrackingDetailmodel] = useState(false);
    const [currentStartbreakDuration, setCurrentStartbreakDuration] = useState("");
    useEffect(() => {
        if(!!props.EmployeeIdFordetail)
        {
            getTimetrackingDetail(props.EmployeeIdFordetail)
        }
    }, [props.EmployeeIdFordetail]);
    // useEffect(() => {
    //     if(!!cookies.continueAs&&cookies.continueAs==='single')
    //     {
    //         debugger
    //         getTimetrackingDetail(cookies.EmployeeID)
    //     }
    // }, []);
    const getTimetrackingDetail=async(EmployeeIdFordetail)=>{
        var data={
            id:EmployeeIdFordetail.toString()
        }
        var response = await dynamicPostApi(data,'api/Mobile/Home/EmployeeDetail')
        
        debugger
        if(!!response&&response.data==="")
        {
            // setEmpName(localStorage.getItem("empName"))
            setTrackingDetailmodel(false)
        }
        else
        {
            setEmpName(response.data.empName)
            setWorkingTime(response.data.workingTimeDuration)
            setBreakTime(response.data.breakDuration)
            setTimeTrackId(response.data.timetrackId)
            setRunningStatus(response.data.runningStatus)
            setCurrentStartbreakDuration(response.data.currentStartbreakDuration)
            setTrackingDetailmodel(true)
        }
    }
    const StartTracking=async()=>{
        const decryptedString = cookies.Settings;

        if(decryptedString.request_employee_signature===true)
        {
            localStorage.setItem("SelectedEmpId",props.EmployeeIdFordetail.toString())
            localStorage.setItem("TimeTrackId",props.EmployeeIdFordetail.toString())
            localStorage.setItem("TimeTrackStatus","newrecord")
            navigate("/timeline");
        }else{
             var data={
                id:props.EmployeeIdFordetail.toString(),
                TrackingViea:'Stamp-App',
                CurrentDatetime:new Date().toUTCString()

            }
            var form_data = new FormData();
            for (var key in data) {
                if(typeof(data[key])==="object"&&!!data[key]&&data[key].length>0)
                {
                    for (var i = 0; i < data[key].length; i++) {
                        form_data.append(key+[], data[key][i]);
                    }
                }else{
                    form_data.append(key, data[key]);
                }            }
            var response = await dynamicPostApi(form_data,'api/Mobile/Home/StartTimeTrcking')
                if(!!response&&response.data===true)
                {
                    if(!!cookies.continueAs&&cookies.continueAs==='organisation')
                    {
                        var output = [];
                        for (var i=0; i < cookies.EmployeeList.length ; ++i)
                            output.push(cookies.EmployeeList[i].empId);
                
                        var dataList={
                            Ids:output
                        }
                        var responses = await dynamicPostApi(dataList,'api/Mobile/Home/EmployeeswithTimeTrackingDetail')
                        if(!!responses&&responses.data.length>0)
                        {
                            let y = [];
                            responses.data.map(x => y.push({...x , selected:true}))
                            setCookie('EmployeeList', y,{path:'/'})
            
                            // props.setReloadListtrigger(!props.ReloadListtrigger)
                            // props.setDetailView(false)
                        }
                        setCookie("TimeTrackStatusMessages","Start",{ path: '/' })
                        props.setDetailView(false)
                    }else
                    {
                        setCookie("TimeTrackStatusMessages","Start",{ path: '/' })
                        getTimetrackingDetail(props.EmployeeIdFordetail)

                        // props.setDetailView(false)
                    }
                    // navigate("/homepage")
                }else{
                    Swal.fire({
                        icon: 'info',
                        title: 'Something went wrong...',
                      })
                }
        }
    }
    return(
        <>
            <Col md={cookies.continueAs==='single'?12:8} className="mb-3 ">
                <Row>
                {/* <LiveClock /> */}
                    <Clock format={'HH:mm'} ticking={true}  className="desginClock d-flex justify-content-center "/>
                   <Container>
                       <Row>
                           {
                             TrackingDetailmodel===true?
                             <TrackingDetail empid={props.EmployeeIdFordetail} EmployeeIdFordetail={props.EmployeeIdFordetail} getTimetrackingDetail={getTimetrackingDetail} CurrentStartbreakDuration={currentStartbreakDuration} empName={empName} setDetailView={props.setDetailView} setReloadListtrigger={props.setReloadListtrigger}  ReloadListtrigger={props.ReloadListtrigger} RunningStatus={RunningStatus} TimeTrackId={TimeTrackId} BreakTime={BreakTime} WorkingTime={WorkingTime} />
                            :
                            <Form className="formDesgin">
                                       <Col md={12}>
                                                <div className="d-flex justify-content-center">
                                                    <span> {!!props.empName?props.empName:localStorage.getItem("empName")} </span>
                                                </div>
                                        </Col>
                                <Col md={12}>
                                   <div className="d-flex justify-content-center">
                                      <Button variant="secondary" onClick={()=>StartTracking()} className="btnDesgin">Start time tracking</Button>
                                   </div>
                                </Col>
                            </Form>
                           }
                       </Row>
                   </Container>
                </Row>
            </Col> 
           {/* Modal Popup starts from here  */}
        </>
    )
}

export default EmployeeDetails; 
function TrackingDetail(props)
{
    const [NotesChangesModal, setNotesChangesModal] = useState(false);
    const [cookies,setCookie,removeCookie]=useCookies(['Settings','EmployeeID','SelectedPause'])
    const [BreaksList, setBreaksList] = useState([]);
    const [showBreakList, setShowBreakList] = useState(false);
    const [clocktime, setClocktime] = useState("");
    let navigate=useNavigate();
    const StartPauseTracking=async(timetrackid,status)=>{
        const decryptedString = cookies.Settings;
        debugger
        if(decryptedString.request_employee_signature===true)
        {
            localStorage.setItem("SelectedEmpId",props.EmployeeIdFordetail.toString())
            localStorage.setItem("TimeTrackId",timetrackid)
            localStorage.setItem("TimeTrackStatus",status)
            navigate("/timeline");
        }else{
            var data={
                id:timetrackid.toString(),
                EmployeeAutomaticBreaksID:cookies.SelectedPause!==undefined?cookies.SelectedPause.id:"",
                CurrentDatetime:new Date().toUTCString()

            }
            var form_data = new FormData();
            for (var key in data) {
                if(typeof(data[key])==="object"&&!!data[key]&&data[key].length>0)
                {
                    for (var i = 0; i < data[key].length; i++) {
                        form_data.append(key+[], data[key][i]);
                    }
                }else{
                    form_data.append(key, data[key]);
                }            }
            var response = await dynamicPostApi(form_data,'api/Mobile/Home/StopStartTimeTrcking')
            
            if(!!response&&!!response.status===200)
            {
                // navigate("/homepage")
                if(!!cookies.continueAs&&cookies.continueAs==='organisation')
                {
                    var output = [];
                    for (var i=0; i < cookies.EmployeeList.length ; ++i)
                        output.push(cookies.EmployeeList[i].empId);
            
                    var dataList={
                        Ids:output
                    }
                    var responses = await dynamicPostApi(dataList,'api/Mobile/Home/EmployeeswithTimeTrackingDetail')
                    if(!!responses&&responses.data.length>0)
                    {
                        let y = [];
                        responses.data.map(x => y.push({...x , selected:true}))
                        setCookie('EmployeeList', y,{path:'/'})
        
                        // props.setReloadListtrigger(!props.ReloadListtrigger)
                        // props.setDetailView(false)
                    }
                    if(status==='endPause')
                    {
                        var timestring="";
                        if(response.data.hours>0)
                        {
                            timestring=timestring+response.data.hours+" hour,";
                        } 
                        if(response.data.minutes>0)
                        {
                            timestring=timestring+response.data.minutes+" minute,";
                        } 
                        if(response.data.seconds>0)
                        {
                            timestring=timestring+response.data.seconds+" seconds";
                        }
                        setCookie("TimeTrackBreakDuration",timestring,{ path: '/' })
                    }
                    setCookie("TimeTrackStatusMessages",status,{ path: '/' })
                    props.setDetailView(false)
                }else
                {
                    if(status==='endPause')
                    {
                        var timestring="";
                        if(response.data.hours>0)
                        {
                            timestring=timestring+response.data.hours+" hour,";
                        } 
                        if(response.data.minutes>0)
                        {
                            timestring=timestring+response.data.minutes+" minute,";
                        } 
                        if(response.data.seconds>0)
                        {
                            timestring=timestring+response.data.seconds+" seconds";
                        }
                        setCookie("TimeTrackBreakDuration",timestring,{ path: '/' })
                    }
                    setCookie("TimeTrackStatusMessages",status,{ path: '/' })
                    props.getTimetrackingDetail(props.EmployeeIdFordetail)

                }
                
            }
            else{
                Swal.fire({
                    icon: 'info',
                    title: 'Tracking via Roster...',
                  })
            }
           
        }
        
    }
    const EndTracking=async(timetrackid,status)=>{
        const decryptedString = cookies.Settings;
        if(decryptedString.request_employee_signature===true)
        {
            localStorage.setItem("TimeTrackId",timetrackid)
            localStorage.setItem("TimeTrackStatus",status)
            navigate("/timeline");
         }else{

            var data={
                id:timetrackid.toString(),
                CurrentDatetime:new Date().toUTCString()

            }
            var form_data = new FormData();
                for (var key in data) {
                    if(typeof(data[key])==="object"&&!!data[key]&&data[key].length>0)
                    {
                        for (var i = 0; i < data[key].length; i++) {
                            form_data.append(key+[], data[key][i]);
                        }
                    }else{
                        form_data.append(key, data[key]);
                    }                }
            var response = await dynamicPostApi(form_data,'api/Mobile/Home/FinishTimeTrcking')
            if(!!response&&response.data===true)
            {
                if(!!cookies.continueAs&&cookies.continueAs==='organisation')
                {
                    // navigate("/homepage")
                    var output = [];
                    for (var i=0; i < cookies.EmployeeList.length ; ++i)
                        output.push(cookies.EmployeeList[i].empId);
            
                    var dataList={
                        Ids:output
                    }
                    var responses = await dynamicPostApi(dataList,'api/Mobile/Home/EmployeeswithTimeTrackingDetail')
                    if(!!responses&&responses.data.length>0)
                    {
                        let y = [];
                        responses.data.map(x => y.push({...x , selected:true}))
                        setCookie('EmployeeList', y,{path:'/'})
        
                        // props.setReloadListtrigger(!props.ReloadListtrigger)
                        // props.setDetailView(false)
                    }
                    setCookie("TimeTrackIdForStatusInfo",timetrackid,{ path: '/' })
                    setCookie("TimeTrackStatusMessages",status,{ path: '/' })

                    props.setDetailView(false)
                }else
                {
                    setCookie("TimeTrackIdForStatusInfo",timetrackid,{ path: '/' })
                    setCookie("TimeTrackStatusMessages",status,{ path: '/' })
                    props.getTimetrackingDetail(props.EmployeeIdFordetail)

                }
            }
            else{
                Swal.fire({
                    icon: 'info',
                    title: 'Tracking via Roster...',
                })
            }
           
        }
    }
    useEffect(async() => {
            // var data={
            //     id:props.empid.toString()
            // }
            var response = await dynamicGetApi('api/Mobile/AutomaticBreaks/AutomaticBreaksList')
            if(!!response&&response.status=== 200)
            {
                setBreaksList(response.data)
            }
    }, []);
    const Selectpause=(obj)=>{
        setCookie('SelectedPause',obj,{path:'/'})
    }
    const updateAutoPause=(checked)=>{
        if(checked===true)
        {
            setCookie('SelectedPause',BreaksList[0],{path:'/'})
        }else{
            removeCookie('SelectedPause',{path:'/'})
        }
    }
    return(
            <>
                {
                    NotesChangesModal===true?
                    <NotesModel NotesChangesModal={NotesChangesModal} TimeTrackId={props.TimeTrackId} setNotesChangesModal={setNotesChangesModal}/>
                    :null
                }
                <Form className="formDesgin">
                    <Col md={12}>
                            <div className="d-flex justify-content-center">
                                <span> {props.empName} </span>
                            </div>
                    </Col>
                    {
                        !!cookies.Settings&&cookies.Settings.show_current_work_and_break===true?
                        <>
                             <Col md={12}>
                                <div className="d-flex justify-content-center mt-3">    
                                        <Form.Text className="fromTextdesgin">
                                        Working Time: 
                                            {
                                                props.RunningStatus==="Start"?
                                                <LiveClock WorkingTime={props.WorkingTime}/>
                                                :
                                                !!props.WorkingTime?props.WorkingTime.split(":")[0]+" hours, "+props.WorkingTime.split(":")[1]+" minutes, "+props.WorkingTime.split(":")[2].split(".")[0]+" seconds," :""
                                            }
                                        </Form.Text>
                                    </div>
                            </Col>
                            <Col md={12}>
                                <div className="d-flex justify-content-center">
                                <Form.Text className="fromtextDesgin2"> 
                                            Breaks: 
                                            {
                                                props.RunningStatus==="Stop"?
                                                <LiveClock WorkingTime={props.BreakTime}/>
                                                :
                                                !!props.BreakTime?props.BreakTime.split(":")[0]+" hours, "+props.BreakTime.split(":")[1]+" minutes, "+props.BreakTime.split(":")[2].split(".")[0]+" seconds," :""
                                            }
                                </Form.Text>
                                </div>
                            </Col>
                        </>
                        :
                        null

                    }
                    <Col md={12}>
                        <div className="d-flex justify-content-center">
                            {
                                    props.RunningStatus==="Stop"?
                                        <Button variant="danger" className="btnDesgin btnt" onClick={()=>StartPauseTracking(props.TimeTrackId,"endPause")}> End Pause </Button>
                                    :
                                    <>
                                        <Button variant="secondary" onClick={()=>StartPauseTracking(props.TimeTrackId,"startPause")} className="btnDesgin">Start Pause</Button>
                                        <Button variant="danger" className="btnDesgin btnt" onClick={()=>EndTracking(props.TimeTrackId,"endtracking")}> End time recording</Button>
                                    </>

                            }
                        </div>
                        </Col>
                        <Col md={12}>
                            <div className="d-flex justify-content-center">
                                <a><Button variant="outline-success" className="btnDesgin btnCustDesgin" 
                                onClick={() => setNotesChangesModal(!NotesChangesModal)}> Tags/Notes add change</Button></a>
                            </div>
                        </Col>
                        {
                            !!BreaksList && BreaksList.length > 0 ?
                            <Portal>
                                <BottomReactDivWrapper className="reactBottomDiv" >
                                    <div className="d-flex justify-content-between align-items-center mb-2"> 
                                             <div className="d-flex justify-content-between align-items-center">
                                                <Switch 
                                                        checked={cookies.SelectedPause!==undefined?true:false}
                                                        onChange={(e) => updateAutoPause(e)}
                                                    /> 
                                                <span className="ms-3 text-white">{cookies.SelectedPause!==undefined?'Automatically end break after '+cookies.SelectedPause.durationMinutes +' min':'Chose a pause'} </span>
                                             </div>
                                             <div>
                                                <button className="btn btn-transparent text-white" onClick={() => setShowBreakList(!showBreakList)}>{showBreakList === true ? "CLOSE" :"SHOW"}</button>
                                             </div>
                                    </div>
                                    {showBreakList === true ? 
                                    <BottomDivTableBg className="d-flex justify-content-center">
                                        <table width="100%" className="table " style={{backgroundColor:"#182223"}}>
                                                <tbody>
                                                {
                                                    BreaksList.map((val,key)=>{
                                                        return  <tr className="text-center" onClick={()=>Selectpause(val)}>
                                                                    <td width="50%" style={{color:"#c1c1c1"}}>{val.name}</td>
                                                                    <td width="50%" style={{color:"#c1c1c1"}}>{val.durationMinutes} min</td>
                                                                    <td>{!!cookies.SelectedPause && cookies.SelectedPause.id === val.id ? <img src="/static/media/greencircle.18c4fac4.svg" class="listIcon" /> : ""}</td>
                                                                </tr>
                                                    })
                                                }
                                                </tbody>
                                        </table>
                                    </BottomDivTableBg>
                                    : null}
                                </BottomReactDivWrapper>
                                </Portal>
                            :null
                        }
                        {/* <Col md={12}>
                            <div className="d-flex justify-content-center">
                                <a><Button variant="outline-success" className="btnDesgin btnCustDesgin" 
                                onClick={() => setNotesChangesModal(!NotesChangesModal)}> Quick Start TimeTrack</Button></a>
                            </div>
                        </Col> */}
                </Form>
            </>

    )
}
export function LiveClock(props) {
    const spanRef = React.useRef();

    const timerupdate = (date) => {
        if(!!spanRef.current){
            
            let newDate=date.add(1,"seconds");
            spanRef.current.innerHTML =  `${newDate.format("HH")} hours, ${newDate.format("mm")} minutes, ${newDate.format("ss")} seconds`;  
        }
    }

    useEffect(() => {
        if(!!props.WorkingTime)
        {  
            // let date = moment().set({"hour":props.WorkingTime.hours,"minute":props.WorkingTime.minutes,"seconds":props.WorkingTime.seconds});
            let date = moment().set({"hour":props.WorkingTime.split(":")[0],"minute":props.WorkingTime.split(":")[1],"seconds":props.WorkingTime.split(":")[2]});
            // let date = moment().set({"hour":1,"minute":10,"seconds":20});
            setInterval(() => {
                timerupdate(date)
            }, 1000);
        }
    }, [props.WorkingTime]);

    return ( 
        <span ref={spanRef}></span>
     );
}
const TransparentBtn = styled.button`
background:transparent;
border:0;
font-size:14px;
color:#fff;
`
function NotesModel(props)
{
    const [steps, setSteps] = useState(1);
    const [AddEditNote, setAddEditNote] = useState("");
    const [TagsList, setTagsList] = useState([]);
    const [FilterTagsList, setFilterTagsList] = useState([]);
    const [SelectedTags, setSelectedTags] = useState([]);
    const [NotesList, setNotesList] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [noteIdforedit, setNoteIdforedit] = useState("");
    const [SearchTags, setSearchTags] = useState("");
    const [EnableSearch, setEnableSearch] = useState(false);
    useEffect(async() => {
        var data={
            id:props.TimeTrackId.toString()
        }
        var response = await dynamicPostApi(data,'api/Mobile/Home/TimeTrackingTags')
        if(!!response&&response.status===200)
        {
            setSelectedTags(response.data.selectedTags);
            let selectedTagsList = response.data.selectedTags;
            let newAllList = response.data.allTags;

            for (let j = 0; j < selectedTagsList.length; j++) {
                const id = selectedTagsList[j];
                let index = newAllList.findIndex(x => x.value === id);
                if(index != -1){
                    newAllList[index].selected = true;
                    if(newAllList.filter(x => x.selected === true).length === response.data.allTags.length){
                        setIsSelectAll(true);
                    } 
                }
            }
            setTagsList(newAllList)
            setFilterTagsList(newAllList)

        }
        var datas = {
            noteEntity: "TimeTrack",
            ObjectId:props.TimeTrackId.toString()
        };
        var responses = await dynamicPostApi(
          datas,
          "api/Notes/getnotes"
        );
        if (!!responses && responses.data.length>0) {
            // var list=[];
            // responses.data.map((value,key)=>{
            //     list.push(value.noteText)
            // })
            setNotesList(responses.data)
       }
    }, []);
    useEffect(()=>{
        if(!!SearchTags)
        {
            console.log(TagsList.filter(x=>x.text.toLowerCase().includes(SearchTags.toLowerCase())))
            setFilterTagsList(TagsList.filter(x=>x.text.toLowerCase().includes(SearchTags.toLowerCase())))
        }else{
            setFilterTagsList(TagsList)
        }
    },[SearchTags]);

    const EditNote=(noteid,note)=>{
        setAddEditNote(note)
        setNoteIdforedit(noteid)
        setSteps(1)
    }
    const save=async()=>{
        if(!!AddEditNote)
        {
            // if(!!noteIdforedit)
            // {
            //     var data={
            //         NoteId:noteIdforedit,
            //         NoteText:AddEditNote
            //     }
            //     var response = await dynamicPostApi(data, "api/Notes/updatenotes")
            // }else{
                var data={
                    ObjectId:props.TimeTrackId.toString(),
                    noteEntity:"TimeTrack",
                    noteType:"User",
                    NoteText:AddEditNote
                }
                var response = await dynamicPostApi(data, "api/Notes/addnotes")
                if(!!response&&response.status===200)
                {
                    Swal.fire({
                        icon: 'info',
                        title: 'Note addedd successfully...',
                      })               
                }
            // }
        }
        var datas={
            timetrackId:props.TimeTrackId.toString(),
            tagIds:SelectedTags,
        }
        var responses = await dynamicPostApi(datas, "api/Mobile/Home/AddEditTimeTrackingTags")
        if(!!responses&&responses.status===200)
        {
            props.setNotesChangesModal(!props.NotesChangesModal)
        }
    }
    const SelectTag=(tag,checked)=>{
        debugger
        if(checked)
        {
            let prevState = [...FilterTagsList];
            let index = FilterTagsList.findIndex(x => x.value ===  tag.value);
            if(index != -1){
                prevState[index].selected = true;
                setFilterTagsList(prevState);
    
               if(FilterTagsList.filter(x => x.selected === true).length ===  FilterTagsList.length){
                setIsSelectAll(true)
               }
            }
            setSelectedTags([...SelectedTags,tag.value]);
        }else{
           let newList= SelectedTags.filter(function(tag) { 
                return tag.value !== tag.value 
            })
            setSelectedTags(newList);
            let prevState = [...FilterTagsList];
            let index = FilterTagsList.findIndex(x => x.value ===  tag.value);
            if(index != -1){
                prevState[index].selected = false;
                setFilterTagsList(prevState);
                setIsSelectAll(false)
            }
        }}
    return(
        <Modal className="desginCustModal " show={props.NotesChangesModal} onHide={() => props.setNotesChangesModal(!props.NotesChangesModal)}> 
        { steps === 1 ? 
              <Modal.Header className="desginModalHead">
                    <Modal.Title className="desginModalTitle ">Add/Edit tags & Note</Modal.Title>
                </Modal.Header>
        :
            <Modal.Header className="desginModalHead py-3 px-2 justify-content-between">
                <TransparentBtn onClick={() => setSteps(1)}>
                       <CgChevronLeft fontSize={20} /> Add/Edit tags & Note
                    </TransparentBtn>
                <TransparentBtn onClick={() => setSteps(1)}>
                       Add Note
                    </TransparentBtn>
            </Modal.Header>
        }
 
            <Modal.Body>
                <Container fluid>
                {
                    steps === 1 ?
                    <>
                        <Row className="mt-4">
                            <Col md={8}>
                                <Row>
                                    <Form>
                                        <Form.Control type="text" defaultValue={AddEditNote} onChange={(e)=>setAddEditNote(e.target.value)} className="CustDesginTextBox" placeholder="Add Note here (optional)"/>
                                    </Form>
                                </Row>
                            </Col>
                            <Col md={4}>
                            {
                                !!NotesList&&NotesList.length>0?
                                    <Row>
                                        <Link to="#" className="CustHrefText" onClick={()=>setSteps(2)}>previous note</Link>
                                    </Row>
                                :null
                            }
                            </Col>
                            <Col md={12} className="mt-2 ">
                                <Form>
                                    <Row>
                                    <TextField/>
                                    </Row>
                                </Form>
                            </Col>
                            <Col md={6} className="mt-3">
                                <Row>
                                    <span>Add Tags</span>
                                </Row>
                            </Col>
                            {
                                !!TagsList&&TagsList.length>0?
                                <>
                                    <Col md={4} className="mt-3">
                                        <Row>
                                            {EnableSearch === true ?
                                                <input type="text" className='customEmployeeFilter' onInput={(e) => setSearchTags(e.target.value)} placeholder='Filter Tags' />
                                            : null}
                                        </Row>
                                    </Col>
                                    <Col md={2} className="mt-3 d-flex justify-content-end">
                                        <Row>
                                            <BsSearch  onClick={() => setEnableSearch(true)} />
                                        </Row>
                                    </Col>
                                </>
                                :null
                            }
                            <Col md={12} className="mt-4">
                                <Row>
                                    <Form>
                                        {
                                            !!TagsList&&TagsList.length>0?
                                                <div className='d-flex justify-content-between align-items-center bg-white pb-3 border-bottom border-dark'>
                                                    <h6 className='mb-0'>Select All</h6>
                                                    <Form.Group controlId="SelectAllCheckbox">
                                                        <Form.Check type="checkbox" checked={isSelectAll} onChange={(e) => { 
                                                            setIsSelectAll(e.target.checked);
                                                            let prevState = [...FilterTagsList];
                                                            
                                                            let newList = prevState.map(x => { return {...x, selected: e.target.checked}});
                                                            setFilterTagsList(newList);

                                                            if(e.target.checked){
                                                                setSelectedTags(newList.map(x=>x.value));
                                                            } else {

                                                                let newList2 = newList.filter(function(tag) { 
                                                                    return tag.selected === true 
                                                                })
                                                                setSelectedTags(newList2);
                                                            }
                                                        
                                                            }} label="" />
                                                    </Form.Group>
                                                </div> 
                                                :null

                                        }
                                            {/* <Row className="justify-content-between align-items-center">
                                                <div className="d-flex col-md-11">
                                                    <Form.Label>
                                                        Select All
                                                    </Form.Label>
                                                </div>
                                                
                                                <div className="col-md-1 justify-content-end ">
                                                    <Form.Check type="checkbox" className="CustomDesginCheck"/> 
                                                </div>  
                                            </Row> */}
                                            {
                                                !!FilterTagsList&&FilterTagsList.length>0?FilterTagsList.map((value,key)=>{
                                                    return  <Row className="justify-content-between align-items-center">
                                                                <div className="d-flex col-md-11">
                                                                    <Form.Label>
                                                                        {value.text}
                                                                    </Form.Label>
                                                                </div>
                                                                
                                                                <div className="col-md-1 justify-content-end">
                                                                    <Form.Check checked={!!value.selected && value.selected} type="checkbox" onChange={(e)=>SelectTag(value,e.currentTarget.checked)}/> 
                                                                </div>  
                                                            </Row>
                                                })
                                                :
                                                <Row className="text-center">
                                                   <div> no tags availiable </div> 
                                                </Row>
                                            }
                                        
                                    </Form>
                                </Row>
                            </Col>
                        </Row>
                    </>
                    : 
                    <>
                        <table className='table'>
                            {
                                !!NotesList&&NotesList.length>0?NotesList.map((value,index) =>{
                                    return <tbody>
                                                <tr>
                                                    <td onClick={()=>EditNote(value.noteId,value.noteText)}>{value.noteText}</td>
                                                </tr>
                                            </tbody>
                                    })
                                :
                                   null
                            }
                        </table>
                    </>
                }
                    
                </Container>
            </Modal.Body> 
            <Modal.Footer>
                <Button className="btn btn-sm btn-danger" onClick={() => props.setNotesChangesModal(!props.NotesChangesModal)}>Cancel</Button>
                <Button className="btn btn-sm btn-success" onClick={() => save()}>Done</Button>
            </Modal.Footer>     
           </Modal>
    )
}
