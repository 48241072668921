import React from 'react';
import { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import settingActiveIcon  from '../asset/images/NavbarIcons/settingsActiveWhite.svg';
import informationwhite  from '../asset/images/NavbarIcons/informationwhite.svg';
import usersetting from '../asset/images/NavbarIcons/usersetting.svg';
import greencircle from '../asset/images/NavbarIcons/greencircle.svg';
import LeftSideArrowIcon from '../asset/images/NavbarIcons/LeftSidePreviousIcon.svg';
import RightSideArrowIcon from '../asset/images/NavbarIcons/RightSideNextIcon.svg';
import LockCenterIcon from '../asset/images/NavbarIcons/LockCenterIcon.svg';
import ReloadPageIcon from '../asset/images/NavbarIcons/ReloadPageIcon.svg';
import ExportIcon from '../asset/images/NavbarIcons/ExportIcon.svg';
import SafariBrowserIcon from '../asset/images/NavbarIcons/SafariBrowserIcon.svg';
import BackgroundImage from '../asset/images/NavbarIcons/BackgroundImage.PNG';
import StarIcon from '../asset/images/NavbarIcons/StarIcon.PNG';
import '../css/Navbar.css';
import '../css/site.css';
import { Col, Container, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  

function SettingWhatNew() {
    return(
        <>
            <Col lg={12}>
            <Navbar className="navigationDesgin">
                <Nav className="justify-content-between w-100">
                    <div className="d-flex">
                    <Nav.Item>
                        <Link to="/setting">
                            <span className="NavCustDesginNew">Fertig</span>
                        </Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Link to="#">
                            <img src={LeftSideArrowIcon} className="NavIcon Normal CustIconDesgin"/>
                        </Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Link to="#">
                            <img src={RightSideArrowIcon} className="NavIcon Normal"/>
                        </Link>
                    </Nav.Item>

                    </div>

                    
                    <div className="d-flex justify-content-around">
                    <div className="flex">
                   <Nav.Item>
                        <Link to="#" >
                        <img src={LockCenterIcon} className="NavIcon Normal"/>
                        </Link>
                    </Nav.Item>
                    </div>

                    <div>
                    <Nav.Item>
                        <Link to="#" >
                            <span className="textDesgin">notion.so</span>
                        </Link>
                    </Nav.Item>
                    </div>
                    </div>
                    
                    <div className="d-flex justify-content-end">
                    <div>
                    <Nav.Item>
                        <Link to="#">
                            <img src={ReloadPageIcon} className="NavIcon Normal CustIconDesgin"/>
                        </Link>
                    </Nav.Item>
                    </div>
                    <div>
                    <Nav.Item>
                        <Link to="#">
                            <img src={ExportIcon} className="NavIcon Normal CustIconDesgin"/>
                        </Link>
                    </Nav.Item>
                    </div>
                    <div>
                    <Nav.Item>
                        <Link to="#">
                            <img src={SafariBrowserIcon} className="NavIcon Normal CustIconDesgin"/>
                        </Link>
                    </Nav.Item>
                    </div>
                    </div>
                </Nav>
            </Navbar>
        </Col>   
        
        <Container fluid className="baiscBoxSix">
            <Col md={12}>
                <Row>
                    <Col md={6}>
                        <img src={StarIcon} className="NavIcon Normal SearchIconDesgin"/>
                        <span>What's new in Station-iOS?</span>
                    </Col>

                    <Col md={6}>
                        <div className="d-flex justify-content-end">
                            <BsSearch className="SearchIconDesgin"/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <img src={BackgroundImage} className="w-100"/>
                </Row>
               
                <Row className="BoxSimple">
                    <Row>
                        <Col md={12}>
                            <span className="SpanTextFi">Was ist neu in Station-iOS ?</span>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col md={12}>
                            <span className="SpanTextSe">Planned soon<br/></span>
                        </Col>
                     </Row>

                    <Row className="mb-4">
                        <Col md={12}>
                            <li><span className="SpanTextThir"> New design from scratch</span></li>
                        </Col>
                    </Row>

                    <Row className="border-bottom"></Row>

                    <Row className="mt-4">
                        <Col md={12}>
                            <span className="SpanTextSe">3.12.10 @ 2021-03-31</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <span className="SpanTextFour">Bug fixes & improvements:</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <li><span className="SpanTextThir">Fixed an issue where the app would unnecessarily display unchanged data in the list of times
                                shipped.</span></li>
                        </Col>
                        </Row>

                    <Row>
                        <Col md={12}>
                            <li><span className="SpanTextThir">Fixed a bug with truncated names in the signature view.   </span></li>
                        </Col>
                    </Row>

                    <Row className="mb-5">
                        <Col md={12}>
                            <li><span className="SpanTextThir">Fixed a problem with the display of the time recording in the organization mode. </span></li>
                        </Col>
                    </Row>
                </Row>
            </Col>
        </Container>                     
        </>
    )
}

export default SettingWhatNew;

