import React,{useState} from 'react';
import {ApiContext} from "./api";

export const ApiProvider = (props) => {

const showPasswordModal = (value) => {

    updateData((prevState) => {
        const newState = { ...prevState }
        return Object.assign(newState, {
            passwordModal: value
        })
      })
}


  const apiState = {
    passwordModal:false,
    showPasswordModal,
  }


const [ApiValue, updateData] = useState(apiState);

    return (
        <ApiContext.Provider value={ApiValue}>
          {props.children}
        </ApiContext.Provider>
      )
    
    }