import React , {useState} from 'react';

import '../css/Navbar.css';

import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

const IphoneBtn = styled.button`
  text-align: center;
  padding:10px;
  background-color:transparent;
  color:#ff1941;
  border: 0;
  border-top:1px solid #ccc;
  &:hover {
    background-color: #cccccc29
  }
  &:last-child(){
    border-top:0;
    border-radius:0 0 15px 15px !important;
  }
`;

export function PasswordModal(props) {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/collection");
    props.setshow(false)
  }

    return(
        <Modal  size="sm" show={props.show} onHide={() => props.setshow(false)} centered>
       
        {/* <Modal.Body className=' px-0 py-1'>
            <div className='p-3'>
            <h4 className='fw-bold text-center'>PIN eingeben</h4>
            <p className='text-center' style={{fontSize:"14px"}}>
                Gib die Admin-PIN an, um den Admin-Bereich zu betreten passwordModal
            </p> 
                <div className='p-3'> <input type="text" className='form-control form-control-sm border-dark' placeholder='PIN' /></div>
            </div>
            <IphoneBtn className=' btn-sm w-100' onClick={() => handleClick()}>OK</IphoneBtn>
            <IphoneBtn className=' w-100'>PIN vergessen</IphoneBtn>
            <IphoneBtn className=' w-100 fw-bold' onClick={() =>  props.setshow(false)}>Abbrechen</IphoneBtn>

        </Modal.Body>
       */}
      </Modal>
    )
} 

