import React, { Component } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import '../css/Navbar.css';
import '../css/site.css';


function SettingSupport() {

    return(
        <>
            <Container className="BasicBoxFour">
                <Row>
                    <Col md={12}>
                        <ListGroup variant="flush" className="CustListBoxSupport">
                            <ListGroup.Item>
                                <span className="ListofSupport">Report a bug</span>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <span className="ListofSupport">Support contact</span>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <span className="ListofSupport">FAQ</span>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <span className="ListofSupport">Tel: (+49)(0721) 7540234-4</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SettingSupport;

