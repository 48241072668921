import React, { useState,useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import settingActiveIcon from '../asset/images/NavbarIcons/settingsActiveWhite.svg';
import informationwhite from '../asset/images/NavbarIcons/informationwhite.svg';
import usersetting from '../asset/images/NavbarIcons/usersetting.svg';
import '../css/Navbar.css';
import { Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { ApiContext } from "../Context/api";
import { FaUserEdit, FaPlus, FaArrowLeft} from "react-icons/fa"
import { BsCloudCheckFill, BsExclamationCircle, BsFillNutFill } from "react-icons/bs"
import { MdOutlineDeleteOutline } from "react-icons/md"
import { BiCog } from "react-icons/bi"
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { dynamicGetApi,dynamicPostApi } from '../api';
import Swal from 'sweetalert2';
import { useCookies } from 'react-cookie';
import { MdGroups , MdClose } from 'react-icons/md';
import { Portal } from 'react-portal';
import { UsersList } from '../pages/EmployeeeEditDetails';
import ListGroup from 'react-bootstrap/ListGroup';

function Navigation() {
    var compnayname=useSelector(state=> state.app.companyname)
    const { showPasswordModal } = React.useContext(ApiContext);
    const [ShowPasswordModel, setShowPasswordModel] = useState(false);
    const [ShowNewPasswordModel, setShowNewPasswordModel] = useState(false);
    const [NavigationRoute, setNavigationRoute] = useState("");
    const [cookies] = useCookies(['CompanyName','continueAs']);
    const navigate = useNavigate();
    const CheckPassword=async(navigateto)=>{
        if(cookies.continueAs==='single')
        {
            navigate(navigateto)
        }else{
            setNavigationRoute(navigateto)
            var data={
                EmpID:cookies.EmployeeID,
            }
            var response = await dynamicPostApi(data,'api/Mobile/Home/CheckSettingPin')
            if(!!response&&response.data===true)
            {
                setShowPasswordModel(true)
            }else if(!!response&&response.data===false)
            {
                setShowNewPasswordModel(true)
            }
        }
    }
    
    return (
        <>
        {
            ShowPasswordModel===true?
            <PasswordModel show={ShowPasswordModel} setshow={setShowPasswordModel} NavigationRoute={NavigationRoute}/>
            :null
        }
        {
            ShowNewPasswordModel===true?
            <NewPasswordModel show={ShowNewPasswordModel} setshow={setShowNewPasswordModel} NavigationRoute={NavigationRoute}/>
            :null
        }
            <Col lg={12}>
                <Navbar className="navigationDesgin">
                    <Nav className="justify-content-between w-100">
                        <div className="d-flex">
                            <Nav.Item>
                                {/* <Nav.Link href="/setting">
                            <img src={settingActiveIcon } className="NavIcon Normal"/>
                        </Nav.Link> */}
                                <a to="#" className='nav-link' onClick={() => CheckPassword('/setting')}>
                                    <img src={settingActiveIcon} className="NavIcon Normal" />
                                </a>
                            </Nav.Item>

                            <Nav.Item>
                                <a to="#" className='nav-link'>
                                    <img src={informationwhite} className="NavIcon Normal" />
                                </a>
                            </Nav.Item>
                        </div>

                        <div>
                            <Nav.Item>
                                <a to="#" className='nav-link'>
                                    <span className="textDesgin">{cookies.CompanyName}</span>
                                </a>
                            </Nav.Item>
                        </div>
                        <div>
                            <Nav.Item>
                                <a to="#" className='nav-link' onClick={() => CheckPassword('/collection')}>
                                    <img src={usersetting} className="NavIcon Normal" />
                                </a>
                            </Nav.Item>
                        </div>
                    </Nav>
                </Navbar>
            </Col>
        </>
    )
}
export default Navigation;

function PasswordModel(props)
{
    const [pin, setPin] = useState(null);
  const navigate = useNavigate();
  const [cookies] = useCookies(['EmployeeID','continueAs']);
    const [ShowForGetPasswordModel, setShowForGetPasswordModel] = useState(false);
    const [cloudpin, setCloudpin] = useState("");
  const handleClick =async () => {
    var data={
        EmpID:cookies.EmployeeID,
        pin:parseInt(pin)
    }
    var response = await dynamicPostApi(data,'api/Mobile/Home/VerifySettingPin')
    if(!!response&&response.data===true)
    {
        navigate(props.NavigationRoute);
        props.setshow(false)
    }
    else{
        Swal.fire({
            icon: 'error',
            title: 'Pin does not match.',
          })
    }
  }
  const onChangepin = (e) => {

        setPin(e.target.value.replace(/\D/g, ""))
    
    }
  const ResetPassword = async () => {

        var data={
            EmpId:cookies.EmployeeID,
            password:cloudpin
        }
        var response = await dynamicPostApi(data,'api/Mobile/Home/VerifyCloudPin')
        if(!!response&&response.data===true)
        {
            setCloudpin("");
            navigate("/collection");
        }
    }


    return(
        <>
        {
            ShowForGetPasswordModel===true?
                <Modal  size="sm" show={ShowForGetPasswordModel} onHide={() => {setCloudpin("");setShowForGetPasswordModel(false)}} centered>
                    <Modal.Header   className="position-relative flex-column absoluteModalClose">
                        <Modal.Title>Forgot admin pin</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=' px-0 py-1'>
                        <div className='p-3'>
                        <h4 className='fw-bold text-center'>Enter your Rightshift Cloud password</h4>
                        <p className='text-center' style={{fontSize:"14px"}}>
                            Your Rightshift cloud password is your login / registration password. In case you do not have a login yet, please contact support.
                        </p>
                            <div className='p-3'> 
                                <input type="password" className='form-control form-control-sm border-dark' value={cloudpin} onChange={(e)=>setCloudpin(e.target.value)} placeholder='Rightshift password' />
                            </div>
                        </div>
                        <IphoneBtn className=' btn-sm w-100' onClick={() => ResetPassword()}>OK</IphoneBtn>
                    </Modal.Body>
                </Modal>
            :null
        }
        
        <Modal  size="sm" show={props.show} onHide={() => props.setshow(false)} centered>
            <Modal.Body className=' px-0 py-1'>
                <div className='p-3'>
                <h4 className='fw-bold text-center'>PIN eingeben</h4>
                <p className='text-center' style={{fontSize:"14px"}}>
                    Gib die Admin-PIN an, um den Admin-Bereich zu betreten 
                </p>
                    <div className='p-3'> 
                    <input maxlength={4} type="text" className='form-control form-control-sm border-dark' value={pin} pattern="^0[1-9]|[1-9]\d$" onChange={(e)=>onChangepin(e)} placeholder='PIN' />
                    </div>
                </div>
                <IphoneBtn className=' btn-sm w-100' onClick={() => handleClick()}>OK</IphoneBtn>
                <IphoneBtn className=' w-100' onClick={()=>setShowForGetPasswordModel(true)}>PIN vergessen</IphoneBtn>
                <IphoneBtn className=' w-100 fw-bold' onClick={() =>  props.setshow(false)}>Abbrechen</IphoneBtn>
            </Modal.Body>
        </Modal>
        </>
    )
}

function NewPasswordModel(props)
{
    const [pin, setPin] = useState("");
    const [confirmpin, setConfirmpin] = useState("");
    const [cookies,setCookie] = useCookies(['EmployeeID']);

  const navigate = useNavigate();

  const handleClick = async() => {
    if(!!pin&&!!confirmpin)
    {
        if(confirmpin===pin)
        {
            if(pin.length==4)
            {
                var data={
                    EmpID:cookies.EmployeeID,
                    pin:parseInt(pin)
                }
                var response = await dynamicPostApi(data,'api/Mobile/Home/CreateSettingPin')
                if(!!response&&response.data===true)
                {
                    navigate(props.NavigationRoute);
                    props.setshow(false)
                }
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Pin must be 4 numbers.',
                  })    
            }
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Pin & Confirm Pin are not match.',
              })
        }
    }else{
        Swal.fire({
            icon: 'error',
            title: 'Pin & Confirm Pin fields are required.',
          })
    }
  }

  const onCreatepin = (e) => {

    setPin(e.target.value.replace(/\D/g, ""))

  }

  const onConfirmpin = (e) => {

    setConfirmpin(e.target.value.replace(/\D/g, ""))

  }

    return(
        <Modal  size="sm" show={props.show} onHide={() => props.setshow(false)} centered>
       
        <Modal.Body className=' px-0 py-1'>
            <div className='p-3'>
            <h4 className='fw-bold text-center'>Create PIN</h4>
            <p className='text-center' style={{fontSize:"14px"}}>
                Gib die Admin-PIN an, um den Admin-Bereich zu betreten
            </p> 
                <div className='p-3'> 
                 <input maxlength={4} type="text" className='form-control form-control-sm border-dark' value={pin} pattern="^0[1-9]|[1-9]\d$" onChange={(e)=>onCreatepin(e)} placeholder='PIN' />
                </div>
                <div className='p-3'> 
                    <input maxlength={4} type="text" className='form-control form-control-sm border-dark' value={confirmpin} pattern="^0[1-9]|[1-9]\d$" onChange={(e)=>onConfirmpin(e)} placeholder='Confirm PIN' />
                </div>
            </div>
            <IphoneBtn className=' btn-sm w-100' onClick={() => handleClick()}>OK</IphoneBtn>
            <IphoneBtn className=' w-100 fw-bold' onClick={() =>  props.setshow(false)}>Abbrechen</IphoneBtn>

        </Modal.Body>
      
      </Modal>
    )
}


const IphoneBtn = styled.button`
  text-align: center;
  padding:10px;
  background-color:transparent;
  color:#ff1941;
  border: 0;
  border-top:1px solid #ccc;
  &:hover {
    background-color: #cccccc29
  }
  &:last-child(){
    border-top:0;
    border-radius:0 0 15px 15px !important;
  }
`;
const IconBtn = styled.div`
color:#fff;
font-size: 20px;

`;
const DropDownContainer = styled("div")`
  width: 10.5em;
  margin: 0 auto;
`;


const DropDownListContainer = styled("div")`
position: relative;
`;

const DropDownList = styled("ul")`
position:absolute;
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  z-index:999;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  width: 190px;
  
`;

const ListItem = styled("li")`
  list-style: none;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  text-align:center;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #ccc;
  &:hover{
      background-color:#f8f6f6;
  }
  &:last-child{
    border-bottom: 0;

  }
`;

const options = ["Bestehender Mitarbeiter","Neuer Mitarbeiter"];

const Offcanvas = styled.div`
background:#fff;
position:fixed;
top:0;
bottom:0;
left:${props => props.show === true ? "0" : "-350px"};
width:350px;
z-index:99999;
transition:.5s ease;
display:flex;
flex-direction:column;
justify-content:space-between;
border-right:1px solid #000;
`
const OffcanvasHeader = styled.div`

`
const OffcanvasBody = styled.div`
height: 80%;
overflow:auto
`
const OffcanvasFooter = styled.div`
padding:10px;
border-top:1px solid #000
`
const BottomButtonGreen = styled.button`
border:1px solid #48c9b0;
background:#48c9b0;
color:#fff;
padding:5px 15px;
width:100%;
border-radius:5px;
`

export function NavigationEditEmployee(props) {

    const { showPasswordModal } = React.useContext(ApiContext);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [newEmployeeModal, setNewEmployeeModal] = useState(false);
    const [cookies,setCookie] = useCookies(['CompanyName','continueAs']);
    const [showCanvas, setshowCanvas] = useState(false);
    const [employeename, setEmployeename] = useState("");
    const [AddexistingEmployeeModel, setAddexistingEmployeeModel] = useState(false);
    const [UpdateEmpListTrigger, setUpdateEmpListTrigger] = useState(false);
    const [EmployeeIdForDetail, setEmployeeIdForDetail] = useState("");
    const navigate = useNavigate();

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
        
        if(value === "Neuer Mitarbeiter"){
            showNewEmployeeModal()
        }else if(value === "Bestehender Mitarbeiter"){
            setAddexistingEmployeeModel(true)
        }
        setSelectedOption(value);
        setIsOpen(false);

    };
    const deleteEmployee = ()=>{
        var newList= cookies.EmployeeList.filter(function(employee){
          return employee.empId!==props.EmployeeIdForDetail
         })
         setCookie('EmployeeList',newList,{path:'/'})
      }


   const showNewEmployeeModal = () => {
        setNewEmployeeModal(!newEmployeeModal)
    }
    return (
        <>
        {newEmployeeModal === true ? 
          <NewEmployeeModal UpdateEmpListTrigger={props.UpdateEmpListTrigger} setUpdateEmpListTrigger={props.setUpdateEmpListTrigger} show={newEmployeeModal} setShow={showNewEmployeeModal}/>
        : null}

        {AddexistingEmployeeModel === true ? 
          <AddexistingEmployee show={AddexistingEmployeeModel} setShow={setAddexistingEmployeeModel}/>
        : null}
        
            <Col lg={12}>
                <Navbar className="navigationDesgin">
                    <Nav className="justify-content-between w-100">
                        <div className="d-flex justify-content-between align-items-center w-25">
                            {
                                cookies.continueAs==='organisation'?
                                <>
                                        <Nav.Item >
                                            <Link to="#" onClick={() => !!props.setedituser ? props.setedituser(true) : null} className='nav-link p-0'>
                                                <IconBtn>
                                                    <FaUserEdit />
                                                </IconBtn>
                                            </Link>
                                        </Nav.Item>

                                        <Nav.Item >
                                            <Link to="#" className='nav-link fw-bold p-0'>
                                            {cookies.CompanyName}
                                            </Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Link to="#" className='nav-link p-0'>
                                                <DropDownContainer style={{width:"auto"}}>
                                                    
                                                        <IconBtn  onClick={toggling}>
                                                            <FaPlus />

                                                        </IconBtn>
                                                    {isOpen && (
                                                        <DropDownListContainer>
                                                            <DropDownList>
                                                                {options.map(option => (
                                                                    <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                                                                        {option}
                                                                    </ListItem>
                                                                ))}
                                                            </DropDownList>
                                                        </DropDownListContainer>
                                                    )}
                                                </DropDownContainer>

                                            </Link>
                                    </Nav.Item>
                                </>
                                :
                                <Nav.Item >
                                    <IconBtn>
                                        <FaArrowLeft onClick={() => {navigate("/")}} />
                                        <span>Session Time</span>
                                    </IconBtn>
                                </Nav.Item>
                            }
                        </div>

                        <div>
                            <Nav.Item>
                                <Link to="#" className='nav-link'>
                                    <span className="textDesgin">{props.employeename}</span>
                                </Link>
                            </Nav.Item>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            {/* <Nav.Item className='pe-3'>

                                <IconBtn>
                                    <BsCloudCheckFill />

                                </IconBtn>


                            </Nav.Item> 
                             </div>

                            <div>
                                <Nav.Item>
                                    <Link to="#" className='nav-link'>
                                        <span className="textDesgin">{props.employeename}</span>
                                    </Link>
                                </Nav.Item>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                            {/* <Nav.Item className='pe-3'>
                                <IconBtn>
                                    <MdOutlineDeleteOutline onClick={() => deleteEmployee()}/>

                                </IconBtn>
                            </Nav.Item> */}
                            {/* <Nav.Item className='pe-3'>
                                <IconBtn>
                                    <BsExclamationCircle />

                                </IconBtn>
                            </Nav.Item> */}

                            <Nav.Item className='pe-3'>
                                <Link to="/setting">
                                <IconBtn>
                                    <BiCog />

                                </IconBtn>
                                </Link>
                            </Nav.Item>

                        </div>
                    </Nav>
                </Navbar>
            </Col>
           
        </>
    )
}
const TransparentBtn = styled.button`
background:transparent;
border:0;
font-size:14px;
color:#fff;
`
function AddexistingEmployee(props)
{
    const [SelectedEmployee, setSelectedEmployee] = useState([]);
    const [EmployeeList, setEmployeeList] = useState([]);
    const [cookies,setCookie] = useCookies(['CompanyName','continueAs']);
    const [isSelectAll, setIsSelectAll] = useState(false);

    const handleClose = () => props.setShow(false);
    useEffect(async() => {
        setSelectedEmployee(cookies.EmployeeList)
        var responses = await dynamicGetApi('api/Mobile/Home/EmployeeList')
        if(!!responses && responses.data.length > 0)
        {
            var resArr = [];
            responses.data.forEach(function(item){
            var i = cookies.EmployeeList.findIndex(x => x.empId == item.empId);
            if(i <= -1){
                resArr.push(item);
            }
            });
            if(resArr.length===0)
            {
                Swal.fire({
                    icon: 'info',
                    title: 'No more employee availiable...',
                  })
                  handleClose()
            }else{
                let y = [];
                resArr.map(x => y.push({...x , selected:false}))
                setEmployeeList(y)
            }
        }
    }, []);
    const SelectEmployee=(emp,checked)=>{
        
        if(checked)
        {
            let prevState = [...EmployeeList];
            let index = EmployeeList.findIndex(x => x.empId ===  emp.empId);
            if(index != -1){
                prevState[index].selected = true;
                setEmployeeList(prevState);
    
               if(EmployeeList.filter(x => x.selected === true).length ===  EmployeeList.length){
                setIsSelectAll(true)
               }
            }

            setSelectedEmployee([...SelectedEmployee,emp])
        }else{
           let newList= SelectedEmployee.filter(function(employee) { 
                return employee.empId !== emp.empId 
            })
            setSelectedEmployee(newList)
            let prevState = [...EmployeeList];
            let index = EmployeeList.findIndex(x => x.empId ===  emp.empId);
            if(index != -1){
                prevState[index].selected = false;
                setEmployeeList(prevState);
                setIsSelectAll(false)
            }
        }}
        useEffect(() => {
            setCookie('EmployeeList',SelectedEmployee,{path:'/'})
        }, [SelectedEmployee]);
        const finish=()=>{
            if(cookies.EmployeeList.length>0)
            {
                props.setShow(false);
            }
        }
    return(
        <Modal
         show={props.show} onHide={handleClose} contentClassName="bg-transparent rounded-0 border-0 ">
            <Modal.Header style={{backgroundColor:"#48c9b0"}} className='py-3 px-2 justify-content-between'>
          <Modal.Title className='h6 text-center text-white border-bottom-0'> Mitarbeiter synchronisieren</Modal.Title>
          <TransparentBtn onClick={()=>finish()}>
            Fertig
          </TransparentBtn>
        </Modal.Header> 
        <Modal.Body className='p-0'>
        <div className='d-flex justify-content-between align-items-center bg-white p-3 border-bottom border-dark'>
            <h6 className='mb-0'>Alle Mitarbeiter</h6>
            <Form.Group controlId="SelectAllCheckbox">
            <Form.Check type="checkbox" checked={isSelectAll} onChange={(e) => { 
                                
                                setIsSelectAll(e.target.checked);
                                let prevState = [...EmployeeList];
                                
                                let newList = prevState.map(x => { return {...x, selected: e.target.checked}});
                                 setEmployeeList(newList);

                                if(e.target.checked){
                                    let newl = [...SelectedEmployee, ...newList];
                                    setSelectedEmployee(newl);
                                } else {
                                    let filterList = [];
                                    let stateprev = [...SelectedEmployee];
                                    for (let i = 0; i < newList.length; i++) {
                                        const element = newList[i];
                                        let index = stateprev.findIndex(x => x.empId === element.empId);
                                        if(index != -1){
                                            stateprev.splice(index,1);
                                        }
                                    }
                                    setSelectedEmployee(stateprev)
                                }
                                }} label="" />
            </Form.Group>
        </div> 
        <ListGroup style={{maxHeight:"430px" , overflowY:"auto"}} variant="flush">
            {
                !!EmployeeList&&EmployeeList.length>0?EmployeeList.map((value,key)=>{
                    return  <ListGroup.Item className='py-3 addgreenHover d-flex justify-content-between align-items-center'  >
                        {value.empName}
                        <Form.Group controlId={value.empId}>
                            <Form.Check type="checkbox" checked={value.selected} label="" onChange={(e)=>SelectEmployee(value,e.currentTarget.checked)}/>
                        </Form.Group>
                        </ListGroup.Item>
                })
                :null
            }
    
    </ListGroup>
        
        </Modal.Body>
       
      </Modal>
    )
}
function NewEmployeeModal (props) {
    const [Name, setName] = useState("");
    const [pin, setPin] = useState(Math.floor(1000 + Math.random() * 9000).toString());
    const [cookies, setCookie,removeCookie] = useCookies(['continueAs','FirstLoad','EmployeeList']);
      
    const save=async()=>{
        if(!!Name&&!!pin)
        {
            var data={
                name:Name,
                pin:parseInt(pin), 
            }
            var response = await dynamicPostApi(data,'api/Mobile/Admin/CreateEmployee')
            if(!!response&&response.status===200)
            {
                var output = [];
                for (var i=0; i < cookies.EmployeeList.length ; ++i)
                {
                    output.push(cookies.EmployeeList[i].empId);
                }
                
                output.push(response.data)
                var dataList={
                    Ids:output
                }
                var responses = await dynamicPostApi(dataList,'api/Mobile/Home/EmployeeswithTimeTrackingDetail')
                if(!!responses&&responses.data.length>0)
                {
                    let y = [];
                    responses.data.map(x => y.push({...x , selected:true}))
                    setCookie('EmployeeList', y,{path:'/'})
    
                    // props.setReloadListtrigger(!props.ReloadListtrigger)
                    // props.setDetailView(false)
                }

                props.setShow()
            }
        }else{
            Swal.fire({
                icon: 'info',
                title: 'Name & pin are required...',
              })
        }
    }
    return(
        <Modal  show={props.show} onHide={() => props.setShow()}> 
        <Modal.Header className="HeadDesginFrom">
             <Container>
                 <Col md={12}>
                     <Row>
                         <Col md={3} className="d-flex justify-content-start">
                             <div>
                                 <span className="ModalText" onClick={() => props.setShow()}>Abbrechen</span>
                             </div>
                         </Col>

                         <Col md={6} className="d-flex justify-content-center">
                             <div>
                                 <span className="ModalTextHName">
                                     New User
                                 </span>
                             </div>
                         </Col>

                         <Col md={3} className="d-flex justify-content-end">
                             <div>
                                 <span className="ModalText" onClick={() => save()}>Sichern</span>
                             </div>
                             
                         </Col>
                     </Row>
                 </Col>
             </Container>
         </Modal.Header>
         <Modal.Body className="tModalDesginFrom" >
             <Container fluid className="CustRowModal">
                 <Col md={12}>
                     <Row>
                         <Col md={2} className="mb-2">
                             <span className="spanTextForm">Name</span>
                             
                         </Col>
                         <Col md={10}>
                             <Form>
                                 <Form.Control type="text" className="CustDesginTextBox CustTextBoxForm" onChange={(e)=>setName(e.target.value)} placeholder="Name"/>
                             </Form>
                             <div></div>
                         </Col>
                     </Row>
                     
                 </Col>
                 <div className="border-top border-dark"></div>
                     
                 <Col md={12}>
                     <Row className="mt-3 ">
                         <Col md={6}>
                             <span  className="spanTextForm">Pin</span>
                         </Col>
                         <Col md={6}>
                             <Form>
                                 <Form.Control type="text" className="CustDesginTextBox CustTextBoxForm" value={pin} onChange={(e)=>setPin(e.target.value)} placeholder="0"/>
                             </Form>
                         </Col>
                     </Row>
                 </Col>   
             </Container>

             
         </Modal.Body>
         </Modal>
    )
}