import React,{useState,useEffect} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import greencircle from '../asset/images/NavbarIcons/greencircle.svg';
import {GoPrimitiveDot} from "react-icons/go";
import { dynamicGetApi } from '../api';
import { useCookies } from 'react-cookie';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { Cookies } from 'react-cookie';

function UsersList(props) {
    const [EmployeeList, setEmployeeList] = useState({});
    const [cookies, setCookie, removeCookie] = useCookies(['continueAs','EmployeeList']);

    useEffect(() => {
        // getEmployeeList()
        setEmployeeList(cookies.EmployeeList)
    }, [cookies.EmployeeList]);

    const getEmployeeList=async()=>{
        
        var response = await dynamicGetApi('api/Mobile/Home/EmployeeList')
        if(!!response&&response.data.length>0)
        {
            setEmployeeList(response.data)
        }
    }
    const Detail=async(id,name)=>{
        if(props.EmployeeIdFordetail!==id)
        {
            props.fnEmployeeIdFordetail(id)
            props.setEmpName(name)
            props.fnDetailView(false)
            
        }else{
            props.fnDetailView(!props.DetailView)
        }
    }
    useEffect(() => {
        if(!!props.ReloadListtrigger)
        {
            getEmployeeList()
        }
    }, [props.ReloadListtrigger]);

    return ( 

        <ListGroup variant="flush" className="listDesign">
            {/* <ListGroup.Item onClick={()=>getEmployeeList()}>
                <Link to="#"  className="NavLink">
                    Update List Manually
                </Link>
            </ListGroup.Item> */}
               
           {
                !!EmployeeList && EmployeeList.length > 0 ? EmployeeList.map((value,key)=>{
                    return <ListGroup.Item onClick={()=>Detail(value.empId,value.empName)}>
                          
                                <Link to="#"  className="NavLink">{value.empName}
                                    {
                                        !!value.timeTrackrunningStatus&&value.timeTrackrunningStatus==="Start"?<img src={greencircle} className="listIcon"/>:
                                        !!value.timeTrackrunningStatus&&value.timeTrackrunningStatus==="Stop"?<GoPrimitiveDot  style={{color:"yellow",fontSize: "25px",float: "right"}} />
                                        :null
                                    } 
                                </Link>
                            </ListGroup.Item>
                })
                :null
            }
    </ListGroup>

     );
}

export default UsersList;