import React, { Component,useState,useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/site.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import GlobeIcon from '../asset/images/NavbarIcons/globe.svg';
import CancelMark from '../asset/images/NavbarIcons/cancelIconWhite.svg';
import greencircle from '../asset/images/NavbarIcons/greencircle.svg';
import checkedMarkedRed from '../asset/images/NavbarIcons/checkedMarkedRed.svg';
import { Form, InputGroup, ListGroup } from 'react-bootstrap';
import { BsSearch,BsArrowLeft } from 'react-icons/bs';
import { dynamicPostApi,dynamicGetApi } from '../api';




function SettingTags() {
    const [SettingSecondModal, setSettingSecondModal] = useState(false);
    const [TagsList, setTagsList] = useState([]);
    const [filterList, setfilterList] = useState([]);
    useEffect(async() => {
        if(SettingSecondModal === true)
        {
            var response = await dynamicGetApi('api/Mobile/Tags/TagsList')
            if(!!response&&response.data.allTags.length>0)
            {
                let addselectedOptionInList = response.data.allTags;
                 let ew = addselectedOptionInList.map(x => {return {...x,selected:false}});

                 for (let j = 0; j < response.data.selectedTags.length; j++) {
                    const id = response.data.selectedTags[j];
                    let index = ew.findIndex(x => x.value === id);
                    if(index != -1){
                        ew[index].selected = true;
                    }
                }
                 setTagsList(ew)
                 setfilterList(ew)
            }
        }
    }, [SettingSecondModal]);

    const selectDataFromList = async(id,index,isSelected) => {
        var data={
            id:id.toString(),
            addorRemove:isSelected
        }
        var response = await dynamicPostApi(data,'api/Mobile/Tags/AddRemoveTags')
        if(!!response&&response.status===200)
        {
            let prevstate = [...TagsList];
            prevstate[index].selected = isSelected;
            setTagsList(prevstate)
        }
    }

    const SearchfromList = (value) => {
        
        let list = filterList.filter(x => x.text.includes(value));
        setTagsList(list)
    }
      return(
            <>
               <div>
                        <Container fluid className="BasicBoxTwo">
                    <Row className="mx-0">
                        <Col md={12}>
                       
                            {
                                SettingSecondModal ?
                                <>  
                                    <Col md={12}>
                                        <BsArrowLeft onClick={() => setSettingSecondModal(!SettingSecondModal)}/>
                                        <Form className="CustFormDesgin">
                                            <InputGroup>
                                                <InputGroup.Text className="custDesginInputGroup commomDesgin"><BsSearch/></InputGroup.Text>
                                                <Form.Control onChange={(e) => SearchfromList(e.target.value)} className="CustDesginTextBox commomDesgin" type="text" placeholder="Search Tags"/>
                                            </InputGroup>
                                        </Form>                            
                                    </Col>
                                    <Col md={12}>
                                        <ListGroup variant="flush" className="CustListDesgin">
                                            {TagsList.map((x,index) =>
                                                <ListGroup.Item onClick={() => selectDataFromList(x.value,index,!x.selected)}>
                                                    <span>{x.text} {x.selected === true ? <img src={checkedMarkedRed} className="CustlistIcon"/> : null} </span>
                                                </ListGroup.Item>
                                                 )}
                                            {/* <ListGroup.Item>
                                                <span>Senior Chef <img src={checkedMarkedRed} className="CustlistIcon"/> </span>
                                            </ListGroup.Item>   
                                            <ListGroup.Item>
                                                <span>Junior Chef <img src={checkedMarkedRed} className="CustlistIcon"/></span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <span>Room 1 <img src={checkedMarkedRed} className="CustlistIcon"/> </span>
                                            </ListGroup.Item>   
                                            <ListGroup.Item>
                                                <span>Room 2 <img src={checkedMarkedRed} className="CustlistIcon"/> </span>
                                            </ListGroup.Item>   
                                            <ListGroup.Item>
                                                <span>Room 3 <img src={checkedMarkedRed} className="CustlistIcon"/> </span>
                                            </ListGroup.Item> */}
                                        </ListGroup>                         
                                    </Col>
                               </>
                            :   <Row className="DesginTextTags">
                                    <a className="AddingTagsChange" onClick={() => setSettingSecondModal(!SettingSecondModal)} ><span  className="AddingTagsChange">Available Tags</span></a>
                                </Row>
                            }                            
                        </Col>
                    </Row>
                    
                </Container>
                    </div>
            </>
        )
}

export default SettingTags;


