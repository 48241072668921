import React, {useState,useEffect} from 'react';
import {NavigationEditEmployee} from "../components/Navigationbar";
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {IoCloudyOutline} from "react-icons/io5"
import {BiChevronRightCircle , BiChevronLeftCircle} from "react-icons/bi"
import Modal from 'react-bootstrap/Modal';
import { dynamicGetApi ,dynamicPostApi} from '../api'; 
import { Link,useNavigate } from 'react-router-dom';
import { GoPrimitiveDot } from 'react-icons/go';
import greencircle from '../asset/images/NavbarIcons/greencircle.svg';
import ListGroup from 'react-bootstrap/ListGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { BsCheckCircle } from 'react-icons/bs';
import styled from 'styled-components';
import { CgChevronLeft,CgChevronRight } from 'react-icons/cg'; 
import { APIServerURL } from '../Config';

import imgsrc from "../download.jpg"

const TransparentBtn = styled.button`
background:transparent;
border:0;
font-size:14px;
color:red;
`
const BottomButtonGreen = styled.button`
border:1px solid #48c9b0;
background:#48c9b0;
color:#fff;
padding:5px 15px;
width:100%;
border-radius:5px;
`
function EmployeeEditDetails() {

    const [DetailList, setDetailList] = useState([]);
    const [EmployeeIdForDetail, setEmployeeIdForDetail] = useState("");
    const [editUser, setEditUser] = useState(false);
    const [Gross, setGross] = useState("");
    const [net, setNet] = useState("");
    const [employeename, setEmployeename] = useState("");
    // const [startDate, setStartDate] = useState(new Date());
    const [dateRange, setDateRange] = useState([new Date(moment().startOf('month')), new Date(moment().endOf('month'))]);
    const [startDate, endDate] = dateRange;
    const [IsRenderd, setIsRenderd] = useState(false);
    const [UpdateEmpListTrigger, setUpdateEmpListTrigger] = useState(false);
    const [cookies, setCookie, get] = useCookies(['continueAs','FirstLoad','EmployeeID']);
    const [EditRecordModel, setEditRecordModel] = useState(false);
    const [EditRowRecord, setEditRowRecord] = useState({});
    const navigate = useNavigate();

    useEffect(async() => {
        if(!!EmployeeIdForDetail)
        {
            getdata()
        }
    }, [EmployeeIdForDetail]);
    useEffect(() => {
        if(cookies.continueAs==='single')
        {
            setEmployeeIdForDetail(parseInt(cookies.EmployeeID))
        }
    }, []);
    const getdata=async()=>{
        var data={
            empid:parseInt(EmployeeIdForDetail),
            Startdate:startDate, 
            enddate:endDate
        }
        var response = await dynamicPostApi(data,'api/Mobile/Admin/EmployeeTimeTrackingList')
        if(!!response&&response.status===200)
        {
            setDetailList(response.data);
            let listofgrossmins = [];
            let listofnetmins = [];
            response.data.map(x => {
                listofgrossmins.push(convertH2M(x.gross)) 
                listofnetmins.push(convertH2M(x.net)) 
            });
            setGross(convertTime(listofgrossmins));
            setNet(convertTime(listofnetmins))
            setIsRenderd(true)
        }
        
    }
    function convertH2M(timeInHour){
        var timeParts = timeInHour.split(":");
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
      }
      const convertTime = (data) => {
        let totalmins = data.reduce((a, b) => a + b, 0);
        var m = totalmins % 60;
        var h = (totalmins-m)/60;
        var HHMM = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString()
        return HHMM;
      }

    const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => {
        return <span className="example-custom-input text-center d-block" onClick={onClick} ref={ref}>
          {value}
        </span>
      });

    const Next = () => {
    let currentDate =  startDate;
    let nextdate = moment(currentDate).clone().add(1,"month");
    let check = new Date(moment(nextdate).format());
    // setStartDate(check);
    }
    const Prev = () => {
        let currentDate =  startDate;
        let prevdate = moment(currentDate).clone().subtract(1,"month");
        let check = new Date(moment(prevdate).format());
        // setStartDate(check);
    }
    useEffect(() => {
        if(IsRenderd)
        {
            if(dateRange[1]!=null)
            {
                getdata()
            }
        }
    }, [dateRange]);
 
    return (<>
    
        <NavigationEditEmployee setUpdateEmpListTrigger={setUpdateEmpListTrigger} EmployeeIdForDetail={EmployeeIdForDetail} employeename={employeename} setEmployeename={setEmployeename} setEmployeeIdForDetail={setEmployeeIdForDetail} UpdateEmpListTrigger={UpdateEmpListTrigger} setedituser={setEditUser} />
        <Container fluid>
            <Row>
          {
            cookies.continueAs==='organisation'?
                <Col md={3} >
                    <UsersList setEmployeename={setEmployeename} UpdateEmpListTrigger={UpdateEmpListTrigger} setEmployeeIdForDetail={setEmployeeIdForDetail} />
                    <BottomButtonGreen onClick={() => {navigate("/")}}>Zeiterfassungs-Modus</BottomButtonGreen>
                </Col>
            :null
          }
            <Col md={cookies.continueAs==='organisation'?9:12} >
                <Row>
                    <Col md={12} className="mb-3 ">
                        <Row>
                            <Col md={12} className="mb-3 bg-white">
                                <div className='showTable'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'><IoCloudyOutline /></th>
                                                <th className='text-center'>Datum</th>
                                                <th className='text-center'>Start</th>
                                                <th className='text-center'>Ende</th>
                                                <th className='text-center'>Pause</th>
                                                <th className='text-center'>Netto</th>
                                                <th className='text-center'>Brutto</th>
                                            </tr>
                                        </thead>
                                            {
                                            !!DetailList&&DetailList.length>0?DetailList.map((value,index) =>{
                                                return <tbody>
                                                            <tr onClick={()=>{setEditRowRecord(value);setEditRecordModel(true);}}>
                                                                <td className='text-center'>{value.icon}</td>
                                                                <td className='text-center'>{value.date}</td>
                                                                <td className='text-center'>{value.startTime}</td>
                                                                <td className='text-center'>{value.endTime}</td>
                                                                <td className='text-center'>{value.pause} h</td>
                                                                <td className='text-center'>{value.net} h</td>
                                                                <td className='text-center'>{value.gross} h</td>
                                                            </tr>
                                                        </tbody>
                                                })
                                                
                                                :
                                                <tbody>
                                                       <tr>
                                                          <td colSpan="7" className='text-center'>No time recording in selected period.</td>
                                                       </tr>
                                                </tbody>
                                        }
                                        {
                                            !!DetailList&&DetailList.length>0?
                                            <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className='text-end' colSpan={2}>{net} h (Brutto: {Gross} h)</td>
                                                    </tr>
                                            </tfoot>    
                                            :null
                                        }
                                    </table>
                                </div>
                            </Col>
                        </Row>
                        <div style={{width:"235px"}} className='MonthPickerWrapper ms-auto d-flex justify-content-between align-items-center'>
                            <BiChevronLeftCircle className='hoverit' onClick={() => Prev()} style={{fontSize:"22px"}} />
                            <DatePicker
                                // selected={startDate}
                                // onChange={(date) => setStartDate(date)}
                                // dateFormat="d MMMM,  yyyy"
                                // showMonthYearPicker
                                customInput={<ExampleCustomInput />}
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                  setDateRange(update);
                                }}
                            />
                            <BiChevronRightCircle className='hoverit' onClick={() => Next()} style={{fontSize:"22px"}} />
                        </div> 
                    </Col> 
                </Row>
            </Col>
            </Row>
           </Container>
            {
                editUser===true?
                  <EditUser setEditUser={setEditUser} editUser={editUser} EmployeeIdForDetail={EmployeeIdForDetail} employeename={employeename}/>
                :null
            }
            {
                EditRecordModel===true?
                  <EditRecord show={EditRecordModel} setShow={setEditRecordModel} getdata={getdata} EditRowRecord={EditRowRecord}/>
                :null
            }
            
            </>
      );
}
export default EmployeeEditDetails;

function EditUser(props)
{
    const [pin, setpin] = useState();
    const [cookies,setCookie] = useCookies(['CompanyName','continueAs','EmployeeList']);

    const deleteEmployee = ()=>{
      var newList= cookies.EmployeeList.filter(function(employee){
        return employee.empId!==props.EmployeeIdForDetail
       })
       setCookie('EmployeeList',newList,{path:'/'})
       props.setEditUser(!props.editUser)
    }
    useEffect(async() => {
        var data={
            id:props.EmployeeIdForDetail.toString(),
        }
        var response = await dynamicPostApi(data,'api/Mobile/Admin/GetEmployeePin')
        if(!!response&&response.status===200)
        {
            setpin(response.data)
        }
    }, [props.EmployeeIdForDetail]);
    const save=async()=>{
        var data={
            EmpID:props.EmployeeIdForDetail,
            pin:parseInt(pin)
        }
        var response = await dynamicPostApi(data,'api/Mobile/Admin/CreateEmployeeSettingPin')
        if(!!response&&response.data===true)
        {
            props.setEditUser(!props.editUser)
        }
    }
    return(
        <Modal  show={props.editUser} onHide={() => props.setEditUser(!props.editUser)}> 
           <Modal.Header className="HeadDesginFrom">
                <Container>
                    <Col md={12}>
                        <Row>
                            <Col md={3} className="d-flex justify-content-start">
                                <div>
                                    <span className="ModalText" onClick={() => props.setEditUser(!props.editUser)}>Abbrechen</span>
                                </div>
                            </Col>

                            <Col md={6} className="d-flex justify-content-center">
                                <div>
                                    <span className="ModalTextHName">
                                       {props.employeename}
                                    </span>
                                </div>
                            </Col>

                            <Col md={3} className="d-flex justify-content-end">
                                <div>
                                    <span className="ModalText" onClick={() => save()}>Sichern</span>
                                </div>
                                
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Modal.Header>
            <Modal.Body className="tModalDesginFrom" >
                <Container fluid className="CustRowModal">
                    <Col md={12}>
                        <Row>
                            <Col md={2} className="mb-2">
                                <span className="spanTextForm">Name</span>
                                
                            </Col>
                            <Col md={10}>
                                <Form>
                                    <Form.Control type="text" defaultValue={props.employeename} disabled className="CustDesginTextBox CustTextBoxForm" placeholder="Name"/>
                                </Form>
                                <div ></div>
                            </Col>
                        </Row>
                        
                    </Col>
                    <div className="border-top border-dark"></div>
                        
                    <Col md={12}  >
                        <Row className="mt-3 ">
                            <Col md={6}>
                                <span  className="spanTextForm">Pin</span>
                            </Col>
                            <Col md={6}>
                                <Form>
                   {/* <input maxlength={4} type="text" className='form-control form-control-sm border-dark' value={pin} pattern="^0[1-9]|[1-9]\d$" onChange={(e)=>onChangepin(e)} placeholder='PIN' /> */}

                                    <Form.Control type="text" onChange={(e)=>setpin(e.target.value)} defaultValue={pin} maxlength={4} pattern="^0[1-9]|[1-9]\d$" className="CustDesginTextBox CustTextBoxForm" placeholder="PIN"/>
                                </Form>
                            </Col>
                        </Row>
                    </Col>   
                </Container>

                <Container fluid className="p-0">
                    <Row>
                    <Col md={12}>
                        <Button className="CustButtonForm" onClick={() => deleteEmployee()} >Mitarbeiter(in) Ioschen</Button>
                    </Col>
                    </Row>
                </Container>
            </Modal.Body>
            </Modal>
    )
}
export  function UsersList(props) {
    const [EmployeeList, setEmployeeList] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['continueAs','EmployeeList']);

    useEffect(() => {
        // getEmployeeList()
            setEmployeeList(cookies.EmployeeList)
           
    }, [cookies.EmployeeList]);
// useEffect(() => {
//     getEmployeeList()
// }, [props.UpdateEmpListTrigger]);
    // const getEmployeeList=async()=>{
        
    //     var response = await dynamicGetApi('api/Mobile/Home/EmployeeList')
    //     if(!!response&&response.data.length>0)
    //     {
    //         setEmployeeList(response.data)
    //         props.setEmployeeIdForDetail(response.data[0].empId)
    //         props.setEmployeename(response.data[0].empName)
    //     }
    // }
    useEffect(() => {
        if(!!EmployeeList&&EmployeeList.length>0)
        {
            props.setEmployeeIdForDetail(EmployeeList[0].empId)
            props.setEmployeename(EmployeeList[0].empName)
        }
    }, [EmployeeList]);
    // const Detail=async(id,name)=>{
    //     if(props.EmployeeIdFordetail!==id)
    //     {
    //         props.fnEmployeeIdFordetail(id)
    //         props.setEmpName(name)
    //         props.fnDetailView(false)
            
    //     }else{
    //         props.fnDetailView(!props.DetailView)
    //     }
    // }
    // useEffect(() => {
    //     if(!!props.ReloadListtrigger)
    //     {
    //         getEmployeeList()
    //     }
    // }, [props.ReloadListtrigger]);

    return ( 

        <ListGroup variant="flush" className="listDesign">
           {
                !!EmployeeList && EmployeeList.length > 0 ? EmployeeList.map((value,key)=>{
                    debugger
                    return <ListGroup.Item onClick={()=>{props.setEmployeeIdForDetail(value.empId);props.setEmployeename(value.empName)}}>
                                <Link to="#"  className="NavLink">{value.empName}
                                    {
                                        !!value.timeTrackrunningStatus&&value.timeTrackrunningStatus==="Start"?<img src={greencircle} className="listIcon"/>:
                                        !!value.timeTrackrunningStatus&&value.timeTrackrunningStatus==="Stop"?<GoPrimitiveDot  style={{color:"yellow",fontSize: "25px",float: "right"}} />
                                        :null
                                    } 
                                </Link>
                            </ListGroup.Item>
                })
                :null
            }
    </ListGroup>

     );
}
function EditRecord(props)
{
    const [steps, setSteps] = useState(1);
    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);
    const [NotesList, setNotesList] = useState([]);
    const [SignatursList, setSignatursList] = useState([]);
    const finish=async()=>{
        var data={
            startDate:StartDate,
            endDate:EndDate,
            // startTime:moment(StartDate).format('HH:mm'),
            // endTime:moment(EndDate).format('HH:mm'),
            Id:props.EditRowRecord.timeTrackID
        }
        var response = await dynamicPostApi(data,'api/Mobile/Admin/UpdateTimeTrack')
        if(!!response&&response.data===true)
        {
            props.getdata()
            props.setShow(false)
        }
    }
    useEffect(async() => {
        setStartDate(moment(props.EditRowRecord.date+' '+props.EditRowRecord.startTime,"DD.MM.YYYY HH:mm"))
        setEndDate(moment(props.EditRowRecord.date+' '+props.EditRowRecord.endTime,"DD.MM.YYYY HH:mm"))

        var data={
            id:props.EditRowRecord.timeTrackID.toString()
        }
        var response = await dynamicPostApi(data,'api/Mobile/Admin/GetTimeTrackSignatures')
        if(!!response&&response.data.length>0)
        {
            setSignatursList(response.data)
        }


        var datas = {
            noteEntity: "TimeTrack",
            ObjectId:props.EditRowRecord.timeTrackID.toString()
        };
        var responses = await dynamicPostApi(
          datas,
          "api/Notes/getnotes"
        );
        if (!!responses && responses.status===200) {
            setNotesList(responses.data.notesList)
       }
    }, []);
    return(
        <>
            <Modal
              show={props.show} onHide={() => props.setShow(false)} contentClassName="rounded-0 border-0 " >
                { steps === 1 ? 
                    <Modal.Header className="HeadDesginFrom">
                        <Container>
                            <Col md={12}>
                                <Row>
                                    <Col md={3} className="d-flex justify-content-start">
                                        <div>
                                            <span className="ModalText" onClick={() => props.setShow(false)}>Fertig</span>
                                        </div>
                                    </Col>
            
                                    <Col md={6} className="d-flex justify-content-center">
                                        <div>
                                            <span className="ModalTextHName">
                                            Zeiterfassung
                                            </span>
                                        </div>
                                    </Col>
            
                                    <Col md={3} className="d-flex justify-content-end">
                                        <div>
                                            <span className="ModalText" onClick={() => setSteps(2)}>Bearbeiten</span>
                                        </div>
                                        
                                    </Col>
                                </Row>
                            </Col>
                        </Container>
                    </Modal.Header>
               :
               steps === 2 ?
                    <Modal.Header  className='py-3 px-2 justify-content-between'>
                    <TransparentBtn onClick={() => setSteps(1)}>
                        <CgChevronLeft fontSize={20} /> Abbrechen
                    </TransparentBtn>
                        <Modal.Title className='h6 text-center border-bottom-0 ModalTextHName'>Zeiterfassung Bearbeiten</Modal.Title>
                        <TransparentBtn onClick={()=>finish()}>
                        Sichern
                        </TransparentBtn>
                    </Modal.Header> 
                :
                steps === 3 ?
                    <Modal.Header  className='py-3 px-2 justify-content-between'>
                    <TransparentBtn onClick={() => setSteps(1)}>
                        <CgChevronLeft fontSize={20} /> Back
                    </TransparentBtn>
                        <Modal.Title className='h6 text-center border-bottom-0 ModalTextHName'>Signatures</Modal.Title>
                        <TransparentBtn onClick={()=>props.setShow(false)}>
                        Done
                        </TransparentBtn>
                    </Modal.Header> 
                :
                steps === 4 ?
                    <Modal.Header  className='py-3 px-2 justify-content-between'>
                    <TransparentBtn onClick={() => setSteps(1)}>
                        <CgChevronLeft fontSize={20} /> Working session
                    </TransparentBtn>
                        <Modal.Title className='h6 text-center border-bottom-0 ModalTextHName'></Modal.Title>
                        <TransparentBtn onClick={()=>props.setShow(false)}>
                        Done
                        </TransparentBtn>
                    </Modal.Header> 
                :null
            }
              
            <Modal.Body className='p-0' style={{backgroundColor:"#f9f9f9"}}>
                {
                    steps === 1 ?
                    <>
                        <Container fluid className="CustRowModal p-0">
                            <div className='d-flex justify-content-between align-items-center border-bottom border-gray p-2'>
                            <span className="spanTextForm">Start</span>
                            <span className="spanTextForm text-muted" style={{fontWeight:400}}>{props.EditRowRecord.date}, {props.EditRowRecord.startTime}</span>

                            </div>
                            <div className='d-flex justify-content-between align-items-center border-bottom border-gray p-2'>
                            <span className="spanTextForm">Ende</span>
                            <span className="spanTextForm text-muted" style={{fontWeight:400}}>{props.EditRowRecord.date}, {props.EditRowRecord.endTime}</span>

                            </div>
                            <div className='d-flex justify-content-between align-items-center  border-gray p-2'>
                            <span className="spanTextForm">Pause (Minuten)</span>
                            <span className="spanTextForm text-muted" style={{fontWeight:400}}>{props.EditRowRecord.pause}</span>

                            </div>
                          
                           
                        </Container>
                        <Container fluid className="CustRowModal p-0">
                        <div className='d-flex justify-content-between align-items-center border-bottom border-gray p-2'>
                        <span className="spanTextForm">Brutto</span>
                        <span className="spanTextForm text-muted" style={{fontWeight:400}}>{props.EditRowRecord.gross} h</span>

                            </div>
                        <div className='d-flex justify-content-between align-items-center  border-gray p-2'>
                        <span className="spanTextForm">Netto</span>
                        <span className="spanTextForm text-muted" style={{fontWeight:400}}>{props.EditRowRecord.net} h</span>

                            </div>
                          
                           
                        </Container>
                        <Container fluid className="CustRowModal p-0">
                        <div onClick={()=>setSteps(3)} className='d-flex justify-content-between align-items-center  border-gray p-2'>
                        <span className="spanTextForm">Unterschriften</span>
                        <CgChevronRight fontSize={20} />

                            </div>
                          
                        </Container>
                        <Container fluid className="CustRowModal p-0">
                        <div onClick={()=>setSteps(4)} className='d-flex justify-content-between align-items-center border-gray p-2'>
                        <span className="spanTextForm">Notizen</span>
                        <CgChevronRight fontSize={20} />

                            </div>
                        </Container>
                        <Container  className="CustRowModal">
                        </Container>
                    </>
                    : 
                    steps === 2 ?
                    <>
                        <div className='py-2 px-3'>Zeiterfussung</div>
                        <Container fluid className="CustRowModal p-0 mt-0">
                        <div className='d-flex justify-content-between align-items-center border-bottom border-gray p-2'>
                        <DatePicker
                                        className="CustDesginTextBox CustTextBoxForm"
                                        selected={new Date(StartDate)}
                                        onChange={(date) => setStartDate(date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        injectTimes={[
                                        ]}
                                        dateFormat="MMMM d, yyyy HH:mm"
                                        />

                            </div>
                        <div className='d-flex justify-content-between align-items-center  border-gray p-2'>
                        <DatePicker
                                        className="CustDesginTextBox CustTextBoxForm"
                                        selected={new Date(EndDate)}
                                        onChange={(date) => setEndDate(date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        injectTimes={[
                                        ]}
                                        dateFormat="MMMM d, yyyy HH:mm"
                                        />

                            </div>
                          
                           
                        </Container>
                         {/* <Container fluid className="CustRowModal">
                            <Col md={12}>
                                <Row>
                                    <Col md={12} className="mb-2"> */}
                                    
                                         {/* <span className="spanTextForm">{props.EditRowRecord.date} {props.EditRowRecord.startTime}</span> */}
                                    {/* </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-2"> */}
                                   
                                        {/* <span className="spanTextForm">{props.EditRowRecord.date} {props.EditRowRecord.endTime}</span> */}
                                    {/* </Col>
                                </Row>
                            </Col>
                        </Container> */}
                      
                    </>
                      : 
                      steps === 3 ?
                      <>
                           <Container fluid className="CustRowModal mt-0 p-0">
                            {
                                !!SignatursList&&SignatursList.length>0?SignatursList.map((value,key)=>{
                                  return  <div className='border-bottom p-2 text-center'>
                                            {
                                                key===0?
                                                      <p>Time Tracking {value.text}</p>
                                                :
                                                key===SignatursList.length-1?
                                                    <p>Time Tracking {value.text}</p>
                                                :
                                                      <p>Pause {value.text}</p>
                                            }
                                            {
                                                value.value===null?
                                                    <img src={imgsrc}className='img-fluid' />
                                                :
                                                  <img src={APIServerURL+value.value} className='img-fluid' />
                                            }
                                        </div>  
                                })
                                :null
                            }
                          </Container>
                      </>
                    :
                      steps === 4 ?
                      <>
                              <Container fluid className="CustRowModal mt-0 p-0 mb-5">
                                <Row>
                                    <Col md={12} className="mb-2">
                                    <table className='table'>
                                        {
                                            !!NotesList&&NotesList.length>0?NotesList.map((value,index) =>{
                                                return <tbody>
                                                            <tr>
                                                                <td>{value.noteText}</td>
                                                            </tr>
                                                        </tbody>
                                                })
                                            :
                                            null
                                        }
                                    </table>
                                    </Col>
                                </Row>
                        </Container>
                      </>
                    :null
                }
            
            </Modal.Body>
           
          </Modal>
        
            </>
    )
}