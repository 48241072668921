import {dynamicPostApi,dynamicGetApi,loginApi} from "../../common";

//Redux state setter actions.....

export const setLoading = (data) => async (setDatainStore) => {
    setDatainStore({type: "isLoading" , data : data})
}
export const setContinueAs = (data) => async (setDatainStore) => {
    setDatainStore({type: "continueAs" , data : data})
}

//Api's actions.....

export const getdesiredTreamentDropdown = () => {
    return async setDatainStore => {
        dynamicGetApi("appointmentSearch/desiredTreamentDropdown","getdesiredTreamentDropdown",setDatainStore);
    }
};

export const login = () => {
    const data = {
        UserName: 'abc@gmail.com',
        Password: 'a',
      };
    return async setDatainStore => {
        loginApi(data,"api/Account/loginMobile","companyname",setDatainStore);
    }
};

