import React, { Component,useState } from 'react';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import GlobeIcon from '../asset/images/NavbarIcons/globe.svg';
import CancelMark from '../asset/images/NavbarIcons/cancelIconWhite.svg';
import AddIconWhite from '../asset/images/NavbarIcons/AddIconWhite.svg';
import { Container, Row } from 'react-bootstrap';
// Here all the pages are imported.
import SettingGenral from '../components/SettingGenral';
import SettingTag from '../components/SettingTags';
import SettingAutomaticBreaks from '../components/SettingAutomaticBreaks';
import SettingSupport from '../components/SettingSupport';
import SettingTerms from '../components/SettingTerms';
import PapershiftCloud from '../components/papershiftCloud'; 
import { useCookies } from 'react-cookie';

import Tab from 'react-bootstrap/Tab';
import '../css/Navbar.css';
import '../css/site.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

function Settings() {
    const [AddAutomicPerson, setAddAutomicPerson] = useState(false);
    const [cookies] = useCookies(['continueAs']);
    const [CurrentTab, setCurrentTab] = useState("General");

    
    return(
        <>
        <Col md={12}>
            <Navbar className="navigationDesgin  p-0">
                <Nav className="justify-content-between w-100">
                    <Col md={4} className="border-end border-dark p-2">
                        <div className="d-flex">
                            <div>
                                <Nav.Item>
                                    <Link to="/homepage" className='nav-link'>
                                        <img src={CancelMark} className="NavIcon Normal"/>
                                    </Link>
                                </Nav.Item>
                            </div>
                    
                            <div>
                                <Nav.Item>
                                    <Link to="#" className='nav-link'>
                                        <span className="textDesgin ">{cookies.CompanyName}</span>
                                    </Link>
                                </Nav.Item>
                            </div>
                        </div>
                    </Col>
                    
                    <Col md={6} className="d-flex justify-content-center mt-2">
                        <div>
                            <Nav.Item>
                                <Link to="#"   className='nav-link'>
                                    <span className="textDesgin">{CurrentTab}</span>
                                </Link>
                            </Nav.Item>
                        </div>
                    </Col>
                    
                    <Col md={2}>
                        <div className="d-flex justify-content-end mt-2">
                    {
                        CurrentTab==='Automatic breaks'?
                             <Nav.Item>
                                <Link to="#" className='nav-link'>
                                    <img src={AddIconWhite} onClick={() => setAddAutomicPerson(!AddAutomicPerson)} className="NavIcon Normal"/>
                                </Link>
                            </Nav.Item>
                            :null
                    }       
                        
                        </div>
                    </Col>
                </Nav>
            </Navbar>
        </Col>

        
        <Container fluid>
        <Col md={12}>
                <Row > 
                    <Tab.Container defaultActiveKey="link-1">
                    <Col md={4} className="desginRow border-end border-dark">
                    <Nav variant="pills" className="flex-column custMainBox .nav-link.active" >
                        <Nav.Item onClick={()=>setCurrentTab("General")}>
                            <Nav.Link className="CustNavDesgin" eventKey="link-1" >General</Nav.Link>
                        </Nav.Item>

                        <Nav.Item onClick={()=>setCurrentTab("Cloud")}>
                            <Nav.Link className=".nav-link.active CustNavDesgin" eventKey="link-2">Papershift Cloud</Nav.Link>
                        </Nav.Item>

                        <Nav.Item onClick={()=>setCurrentTab("Automatic breaks")}>
                            <Nav.Link className=".nav-link.active CustNavDesgin" eventKey="link-3">Automatic breaks</Nav.Link>
                        </Nav.Item>

                        <Nav.Item onClick={()=>setCurrentTab("Tags")}>
                            <Nav.Link className=".nav-link.active CustNavDesgin" eventKey="link-4">Tags</Nav.Link>
                        </Nav.Item>

                        <Nav.Item onClick={()=>setCurrentTab("Support")}>
                            <Nav.Link className=".nav-link.active CustNavDesgin" eventKey="link-5">Support</Nav.Link>
                        </Nav.Item >
                            {
                                cookies.continueAs==='organisation'?
                                    <Nav.Item onClick={()=>setCurrentTab("Terms")}>
                                        <Nav.Link className=".nav-link.active CustNavDesgin" eventKey="link-6">Terms</Nav.Link>                                
                                    </Nav.Item>
                                :null
                            }
                        <Nav.Item onClick={()=>setCurrentTab("Whatsnew")}>
                            <Link to="/settingnew" className="nav-link CustNavDesgin" eventKey="link-7">What's new <img className="NavIcon Normal" src={GlobeIcon}/></Link>                                
                        </Nav.Item>

                        <Nav.Item onClick={()=>setCurrentTab("CommunityForum")}>
                            <Link to="/settingload" className="nav-link CustNavDesgin" eventKey="link-8">Community-Forum <img className="NavIcon Normal" src={GlobeIcon}/></Link>                                
                        </Nav.Item>
                    </Nav>
                    </Col>

                    <Col md={8} className="desginRow position-relative">
                        <Tab.Content  defaultActiveKey="link-1">
                            <Tab.Pane eventKey="link-1" >
                                <SettingGenral/>
                            </Tab.Pane>

                            <Tab.Pane eventKey="link-2">
                                <PapershiftCloud/>
                            </Tab.Pane>

                            <Tab.Pane eventKey="link-3">
                                <SettingAutomaticBreaks AddAutomicPerson={AddAutomicPerson} setAddAutomicPerson={setAddAutomicPerson}/>
                            </Tab.Pane>

                            <Tab.Pane eventKey="link-4">
                                <SettingTag/>
                            </Tab.Pane>

                            <Tab.Pane eventKey="link-5">
                                <SettingSupport/>
                            </Tab.Pane>

                            <Tab.Pane eventKey="link-6">
                                <SettingTerms/>
                            </Tab.Pane>

                            <Tab.Pane eventKey="link-7">
                                
                            </Tab.Pane>

                            <Tab.Pane eventKey="link-8">
                                
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                    </Tab.Container>
                </Row>
            </Col>
        
            </Container>
        
        

        {/* Modal Pop up starts from here Of Automatic breaks person */}
      



        </>
    )
}

export default Settings; 

