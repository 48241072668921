import React, { Component,useState } from 'react';
import { BrowserRouter as Router, Route, Routes , Navigate , useLocation ,Outlet } from 'react-router-dom';

//TimeTracking Pages Will be imported here
import HomePage from '../pages/HomePage';
import EmpDetails from '../pages/EmployeeDetails';
import EmployeeTimeline from '../pages/EmployeeTimeline';
import Setting from '../pages/Settings';
import SettingsTags from '../components/SettingTagsSecond';
import SettingWhatNew from '../components/SettingWhatNew';
import SettingCommunityFirstPage from '../components/SettingCommunityMainPage';
import SettingCommunity from '../components/SettingCommunityFrom';
import SettingCommunitySecond from '../components/SettingCommunityFromsecond';
import EmployeeEditDetails from "../pages/EmployeeeEditDetails"
import {PasswordModal} from "./PasswordModal"
import {ApiContext} from "../Context/api"
import { LoginUser } from '../api';
import PaperShiftCloud from './papershiftCloud'; 
import {SignalRContext} from '../Context/api'
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useCookies } from 'react-cookie';
import { dynamicPostApi } from '../api';
import { APIServerURL } from '../Config';

function Layout() {
  const {setRoleHubConnection,RoleHubConnection} = React.useContext(SignalRContext);
  const [ connection, setConnection ] = React.useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['continueAs','EmployeeID']);

    React.useEffect(() => {
      connectToHUb()
    }, []);
    const connectToHUb=()=>{
      const newConnection = new HubConnectionBuilder()
          .withUrl(APIServerURL+'hubs/timetrackBreaksTrigger')
          .withAutomaticReconnect()
          .build();

        setConnection(newConnection)
        setRoleHubConnection(newConnection)
    }
    React.useEffect(async() => {

        // if (!!connection&&connection._connectionState==='Connected') {
        if (!!connection) {
        await connection.start()
            .then(result => {
                connection.on('SendIds', ids => {
                    // if(getEveryThing.empId==message.empid)
                    // {
                      debugger
                        updateEmpList(ids)
                       
                        // updateRole(message.empid)
                    // }
                });
            })
            .catch(e => console.log('Connection failed: ', e));
        }
        // else{
        //   connectToHUb()

        // }
      }, [connection]);
      const updateEmpList=async(ids)=>{
        if(cookies.continueAs==='organisation')
        {
          var output = [];
          for (var i=0; i < cookies.EmployeeList.length ; ++i)
          output.push(cookies.EmployeeList[i].empId);
          
          if(output.filter(x=>ids.employeeids.includes(parseInt(x))).length>0)
          {
            var dataList={
                Ids:output
            }
            var responses = await dynamicPostApi(dataList,'api/Mobile/Home/EmployeeswithTimeTrackingDetail')
            if(!!responses&&responses.data.length>0)
            {
                let y = [];
                responses.data.map(x => y.push({...x , selected:true}))
                setCookie('EmployeeList', y,{path:'/'})
            }
          }
        }
        else if(cookies.continueAs==='single')
        {
          if(ids.employeeids.filter(x=>x===parseInt(cookies.EmployeeID)).length>0)
          {
            // setCookie('EmployeeList',SelectedEmployee,{path:'/'})
          }
        }
      }
    const {showPasswordModal , passwordModal} = React.useContext(ApiContext)    

    return ( <>
    {passwordModal === true ? 
        <PasswordModal show={passwordModal} setshow={showPasswordModal} />
    : null}
           
                {/* All the new routes will come in this switch and router */}
                <div className="page-wrapper">
                <div className="main-content">
                  
                        <Routes>

                            <Route path="/login" exact element={<PaperShiftCloud home={true} />}/>
                            <Route element={<RequireAuth />}>
                              <Route path="/" exact element={<HomePage />}/>
                              <Route path="/homepage" element={<HomePage />}/>
                              <Route path="/details"  element={<EmpDetails />}/>
                              <Route path="/collection"  element={<EmployeeEditDetails />}/>
                              <Route path="/timeline" element={<EmployeeTimeline />}/>
                              <Route path="/setting" element={<Setting />}/>
                              <Route path="/settingtag" element={<SettingsTags />}/>
                              <Route path="/settingnew" element={<SettingWhatNew />}/>
                              <Route path="/settingload" element={<SettingCommunityFirstPage />}/>
                              <Route path="/settingcommunity" element={<SettingCommunity />}/>
                              <Route path="/settingCommunitty" element={<SettingCommunitySecond />}/>
                          </Route>
                           
                        </Routes>
                        {/* All the routes will be in this switch */}
                </div>   
                </div>
            
        </> );
}

export default Layout;

function RequireAuth() {
  let auth = localStorage.getItem("token");
  let location = useLocation();
  if (auth === null) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}


