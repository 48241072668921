import React, { Component,useState,useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Switch from 'react-ios-switch';
import '../css/site.css';
import { dynamicPostApi,dynamicGetApi } from '../api';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';

import DatePicker from "react-datepicker";
// const Cryptr = require('cryptr');

// const cryptr = new Cryptr('myTotallySecretKey');

const Labels = styled.div`
font-size:12px;
color:#939191;
`

function GenralSetting() {
    const [ID, setID] = useState(0);
    const [Use_employee_PIN, setUse_employee_PIN] = useState(false);
    const [Request_employee_signature, setRequest_employee_signature] = useState(false);
    const [Countdown_automatic_clocking_out, setCountdown_automatic_clocking_out] = useState(false);
    const [Allow_quickstart_new_time_recording_with_different_tags, setAllow_quickstart_new_time_recording_with_different_tags] = useState(false);
    const [Show_current_work_and_break, setShow_current_work_and_break] = useState(false);
    const [Send_crash_reports, setSend_crash_reports] = useState(false);
    const [Isrendercomplete, setIsrendercomplete] = useState(false);

    const [changePasswordModal, setchangePasswordModal] = useState(false);
    const [cookies,setCookie]=useCookies(['EmployeeID','continueAs'])
    const [Hours, setHours] = useState("0");
    const [minutes, setMinutes] = useState("1");
    const [selectCountdownTime, setselectCountdownTime] = useState(new Date());

    // const [Motivationalpharases, setMotivationalpharases] = useState(false);
    useEffect(async() => {
        var data={
            id:cookies.EmployeeID
        }
        var response = await dynamicPostApi(data,'api/Mobile/GeneralSetting/GetGeneralSetting')
        if(!!response && response.status===200)
        {
            setID(response.data.id)
            setUse_employee_PIN(response.data.use_employee_PIN)
            setRequest_employee_signature(response.data.request_employee_signature)
            setCountdown_automatic_clocking_out(response.data.countdown_automatic_clocking_out)
            setAllow_quickstart_new_time_recording_with_different_tags(response.data.allow_quickstart_new_time_recording_with_different_tags)
            setShow_current_work_and_break(response.data.show_current_work_and_break)
            setSend_crash_reports(response.data.send_crash_reports)
            setHours(response.data.hour)
            setMinutes((response.data.hour==='0'&&response.data.minute==='0')?"1":response.data.minute)
            // setMotivationalpharases(response.data.Motivational_pharases)
            setIsrendercomplete(true)
        }
    }, []);

    useEffect(() => {
        if(Isrendercomplete)
        {
            submitsetting()
        }
    }, [Hours,minutes,Use_employee_PIN,Request_employee_signature,Countdown_automatic_clocking_out,Allow_quickstart_new_time_recording_with_different_tags,Show_current_work_and_break,Send_crash_reports]);
    
    const submitsetting=async()=>{

        if(Hours==="0"&&minutes==="0"){
            Swal.fire({
                icon: 'info',
                title: 'Length of time span must be greater then 0.',
              })
        }else{
            var data={
                ID:ID,
                EmployeeId:parseInt(cookies.EmployeeID),
                Use_employee_PIN:Use_employee_PIN,
                Request_employee_signature:Request_employee_signature,
                Countdown_automatic_clocking_out:Countdown_automatic_clocking_out,
                Allow_quickstart_new_time_recording_with_different_tags:Allow_quickstart_new_time_recording_with_different_tags,
                Show_current_work_and_break:Show_current_work_and_break,
                Send_crash_reports:Send_crash_reports,
                LocationId:0,
                hour:Hours,
                minute:minutes
            }
           var res= await dynamicPostApi(data,'api/Mobile/GeneralSetting/UpdateGeneralSetting')
           if(!!res&&res.status===200)
           {
                updateSettingCookie()
           }
        }
    }
    const updateSettingCookie=async()=>{
        var data={
            id:cookies.EmployeeID
        }
        var response = await dynamicPostApi(data,'api/Mobile/GeneralSetting/GetGeneralSetting')
        if(!!response && response.status===200)
        {
            // const encryptedString = cryptr.encrypt(JSON.stringify(response.data));
            setCookie('Settings', response.data, { path: '/' })
        }
    }

    const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <input className='form-control form-control-sm' onClick={onClick} ref={ref} value={value}/>
       
      ));
     return(
            <div>
                {
                    changePasswordModal===true?
                    <ChangePasswordModal show={changePasswordModal} setShow={setchangePasswordModal} />
                    :null
                }
                <Container fluid className="BasicBox">
                    
                    <Row>
                        <Col md={12}>
                            <Row className="DesginText">
                                <Col md={6}>
                                    <span>Company Name</span>
                                </Col>

                                <Col md={6} className="d-flex justify-content-end custChange">
                                    <span>{cookies.CompanyName}</span>
                                </Col>
                            </Row>
                        </Col>
                        {
                            cookies.continueAs==="organisation"?
                                <Col md={12}>
                                    <Row className="DesginText additionChanges">
                                        <span className="AdminPinChange" onClick={()=>setchangePasswordModal(true)} style={{cursor:"pointer"}}>Change admin PIN</span>
                                    </Row>
                                </Col>
                            :null
                        }

                        <div className="DesginTextSwitch"> 
                        {
                            cookies.continueAs==="organisation"?
                            <Col md={12}>
                                <Row >
                                    <Col md={7}>
                                        <span>Use employee PIN</span>
                                    </Col>

                                    <Col md={5} className="d-flex justify-content-end">
                                        <Switch
                                            checked={Use_employee_PIN}
                                            onChange={checked => setUse_employee_PIN(checked)}
                                            />
                                    </Col>
                                </Row>
                            </Col>
                            :null
                        }

                            <Col md={12} className="mt-3">
                                <Row >
                                    <Col md={7}>
                                        <span>Request the employee's signature</span>
                                    </Col>

                                    <Col md={5} className="d-flex justify-content-end">
                                        <Switch
                                        checked={Request_employee_signature}
                                        onChange={checked => setRequest_employee_signature(checked)}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={12} className="mt-3">
                                <Row >
                                    <Col md={7}>
                                        <span>Countdown for automatic clocking out</span>
                                    </Col>

                                    <Col md={5} className="d-flex justify-content-end">
                                    <Switch 
                                        checked={Countdown_automatic_clocking_out}
                                        onChange={checkedFive => setCountdown_automatic_clocking_out(checkedFive)}
                                        />                                   
                                     </Col>
                                     {Countdown_automatic_clocking_out === true ? 
                                     <Col md={12}>
                                        <div className='d-flex align-items-center'>
                                        <div className='form-group mb-3 me-2 d-flex align-items-center'>
                                            <select className='form-select form-select-sm' value={Hours} onChange={(e)=>setHours(e.target.value)}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>    
                                            </select>
                                            <Labels className='ms-2'>Hours</Labels>

                                        </div>
                                        <div className='form-group mb-3 d-flex align-items-center'>
                                            <select className='form-select form-select-sm' value={minutes} onChange={(e)=>setMinutes(e.target.value)}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">31</option>
                                            <option value="32">32</option>
                                            <option value="33">33</option>
                                            <option value="34">34</option>
                                            <option value="35">35</option>
                                            <option value="36">36</option>
                                            <option value="37">37</option>
                                            <option value="38">38</option>
                                            <option value="39">39</option>
                                            <option value="40">40</option>
                                            <option value="41">41</option>
                                            <option value="42">42</option>
                                            <option value="43">43</option>
                                            <option value="44">44</option>
                                            <option value="45">45</option>
                                            <option value="46">46</option>
                                            <option value="47">47</option>
                                            <option value="48">48</option>
                                            <option value="49">49</option>
                                            <option value="50">50</option>
                                            <option value="51">51</option>
                                            <option value="52">52</option>
                                            <option value="53">53</option>
                                            <option value="54">54</option>
                                            <option value="55">55</option>
                                            <option value="56">56</option>
                                            <option value="57">57</option>
                                            <option value="58">58</option>
                                            <option value="59">59</option>    
                                            </select>  
                                            <Labels className='ms-2'>Mins</Labels>

                                        </div>
                                        </div>
                                     </Col>
                                     : null}
                                </Row>
                            </Col>

                            <Col md={12} className="mt-3">
                                <Row >
                                    <Col md={7}>
                                        <span>Allow a quick start of a new time recording <br/>with different tags</span>
                                    </Col>

                                    <Col md={5} className="d-flex justify-content-end">
                                    <Switch 
                                        checked={Allow_quickstart_new_time_recording_with_different_tags}
                                        onChange={checkedFive => setAllow_quickstart_new_time_recording_with_different_tags(checkedFive)}
                                        />                                    </Col>
                                </Row>
                            </Col>
                            
                            <Col md={12} className="mt-3">
                                <Row >
                                    <Col md={7}>
                                        <span>Show current work and break</span>
                                    </Col>

                                    <Col md={5} className="d-flex justify-content-end">
                                        <Switch 
                                        checked={Show_current_work_and_break}
                                        onChange={checkedFive => setShow_current_work_and_break(checkedFive)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            {/* {
                                cookies.continueAs==="single"?
                                    <Col md={12} className="mt-3">
                                        <Row >
                                            <Col md={7}>
                                                <span>Motivational pharases</span>
                                            </Col>
                                            <Col md={5} className="d-flex justify-content-end">
                                                <Switch
                                                    checked={Motivationalpharases}
                                                    onChange={checked => setMotivationalpharases(checked)}
                                                    />
                                            </Col>
                                        </Row>
                                    </Col>
                                :null
                           } */}
                            <Col md={12} className="mt-3">
                                <Row >
                                    <Col md={7}>
                                        <span>Send crash reports</span>
                                    </Col>

                                    <Col md={5} className="d-flex justify-content-end">
                                        <Switch 
                                        checked={Send_crash_reports}
                                        onChange={checkedSix => setSend_crash_reports(checkedSix)}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                        </div>
                    </Row>
                </Container>
            </div>
        )
}

export default GenralSetting; 

const IphoneBtn = styled.button`
  text-align: center;
  padding:10px;
  background-color:transparent;
  color:#ff1941;
  border: 0;
  border-top:1px solid #ccc;
  ${props => props.rightborder === true ? "border-right:1px solid #ccc;" : ""}
  &:hover {
    background-color: #cccccc29
  }
  &:last-child(){
    border-top:0;
    border-radius:0 0 15px 15px !important;
  }
`;

const TopInput = styled.input`
border-bottom-left-radius: 0;
border-bottom-right-radius: 0;
border-color: #ccc !important;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
&:focus{
    box-shadow:none
}
`
const BottomInput = styled.input`
border-top-left-radius: 0;
border-top-right-radius: 0;
border-color: #ccc !important;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
&:focus{
    box-shadow:none
}
`



function ChangePasswordModal(props)
{
    const [pin, setPin] = useState("");
    const [confirmpin, setConfirmpin] = useState("");

  const save = async() => {
    if(!!pin&&!!confirmpin)
    {
        if(confirmpin===pin)
        {
            if(pin.length==4)
            {
                var data={
                    pin:parseInt(pin)
                }
                var response = await dynamicPostApi(data,'api/Mobile/GeneralSetting/UpdateAdminSettingPin')
                if(!!response&&response.data===true)
                {
                    props.setShow(false)
                }
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Pin must be 4 numbers.',
                })    
            }
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Pin & Confirm Pin are not match.',
              })
        }
    }else{
        Swal.fire({
            icon: 'error',
            title: 'Pin & Confirm Pin fields are required.',
          })
    }
  }


  const onCreatepin = (e) => {

    setPin(e.target.value.replace(/\D/g, ""))

  }

  const onConfirmpin = (e) => {

    setConfirmpin(e.target.value.replace(/\D/g, ""))

  }
    
    return(
        <Modal  size="sm" show={props.show} onHide={() => props.setShow(false)} centered contentClassName='modalbodyGrey'>
       
        <Modal.Body className=' px-0 py-1'>
            <div className='p-3'>
            <h4 className='fw-bold text-center'>Change PIN</h4>
            <p className='text-center' style={{fontSize:"14px"}}>
                Enter a new PIN for the admin area
            </p> 
                <div className=''> 
                  {/* <TopInput type="number"  maxlength="4" className='form-control border-bottom-0 form-control-sm border-dark ' onChange={(e)=>setPin(e.target.value)}  placeholder='New PIN' /> */}
                  <input maxlength={4} type="text" className='form-control border-bottom-0 form-control-sm' value={pin} pattern="^0[1-9]|[1-9]\d$" onChange={(e)=>onCreatepin(e)} placeholder='New PIN' /> 
                </div>
                <br />
                <div className=''> 
                  {/* <BottomInput  type="number"  maxlength="4" className='form-control form-control-sm border-dark' onChange={(e)=>setConfirmpin(e.target.value)}  placeholder='Confirm PIN' />  */}
                  <input maxlength={4} type="text" className='form-control form-control-sm' value={confirmpin} pattern="^0[1-9]|[1-9]\d$" onChange={(e)=>onConfirmpin(e)} placeholder='Confirm PIN' />
                </div>
                <br />
            </div>
            <div className='d-flex'>
            <IphoneBtn rightborder={true} className=' w-100 fw-bold ' onClick={() =>  props.setShow(false)}>Cancel</IphoneBtn>
            <IphoneBtn className=' btn-sm w-100' onClick={() => save()}>OK</IphoneBtn>

            </div>
        </Modal.Body>
      
      </Modal>
    )
}