import React,{useState} from 'react';
import { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../css/NavbarLight.css';
import { Col, Row } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


function NavigationbarLight(props) {
    const [ShowHideInToast, setShowHideInToast] = useState(false);


    return(
        <>
        <Col lg={12}>
            <Navbar className="navigationLightDesgin">
                <Nav className="justify-content-between w-100">
                    <div className="d-flex">
                    <Nav.Item>
                        <Link to="" onClick={()=>props.setEmployeetimeLineModel(false)} className='nav-link'>
                           <span className="textSpanDesgin">Abort</span>
                        </Link>
                    </Nav.Item>
                    </div>
            
                    <div>
                   <Nav.Item>
                        <Link to="#" className='nav-link'>
                            <span className="textDesginLight">Start time recording</span>
                        </Link>
                    </Nav.Item>
                    </div>

                    <div>
                    <Nav.Item>
                        <Link to="/homepage" className='nav-link'>
                            <span className="textSpanDesgin" onClick={() => setShowHideInToast(!ShowHideInToast)}>Finished</span>
                            {
                               ShowHideInToast ?
                                    <>  
                                         <Toast  show={ShowHideInToast} onClose={() => setShowHideInToast(!ShowHideInToast)} delay={3000}>
                                        <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
                                        </Toast>
                                   </>
                                : null
                            }
                        </Link>
                    </Nav.Item>
                    </div>
                </Nav>
            </Navbar>
          
        
        </Col>
    {/* <Toast>
        <Toast.Header>
  <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
  <strong className="mr-auto">Bootstrap</strong>
  <small>just now</small>
</Toast.Header>
<Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
</Toast> */}
        </>
    )
}

export default NavigationbarLight;

