import React from 'react';
import { Component,useState } from 'react';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Container, Form, Row, Table } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import AddIconRed from '../asset/images/NavbarIcons/AddIconRed.svg'
import CloudIconRed from '../asset/images/NavbarIcons/CloudIconRed.svg';
import InfoIconRed from '../asset/images/NavbarIcons/InfoIconRed.svg';
import SettingIconRed from '../asset/images/NavbarIcons/SettingIconRed.svg';
import CloudBalckIcon from '../asset/images/NavbarIcons/CloudIconBlack.svg';
import CheckedCircleIconGreen from '../asset/images/NavbarIcons/CheckedCircleIconGreen.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Modal from 'react-bootstrap/Modal';
import {IonItemSliding, IonItem, IonLabel, IonItemOptions, IonItemOption, IonPopover, IonButton } from '@ionic/react';
import {IonDatetime} from '@ionic/react';
import greencircle from '../asset/images/NavbarIcons/greencircle.svg';
import GreaterThanIconGray from '../asset/images/NavbarIcons/GreaterThanIconGray.svg'
import LessThanIconGray from '../asset/images/NavbarIcons/LessThanIconGray.svg'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  
const [selectedDate, setSelectedDate] = ('2021-06T10:25:41789+05:30');

const SettingPopover = (
    <Popover id="popover-basic"  className="SidebarPopover">
          <Popover.Content className="p-0">
                <Container fluid className="p-0">
                    <Col md={12}>
                        <Row className="mb-2">
                            <Col md={12}>
                                <div>
                                    <span className="PopupText">Existing Employee</span>
                                </div>
                            </Col>
                           
                        </Row>
                        <div ></div>
                        <Row className="border-top "> 
                            <Col md={12}>
                                <div className="mt-2">
                                    <span className="PopupText">New Colleague</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Popover.Content>
    </Popover>
    );

 

    const DatePickerPopover = (
        <Popover id="popover-basic"  className="SidebarPopover">
              <Popover.Content className="p-0">
                    <Container fluid className="p-0">
                        <Col md={12}>
                        
                            <IonDatetime
                                    monthValues='1,2,3,4,5,6,7,8,9,10,11,12'
                                    min="1990"
                                    max="2025"
                                    displayFormat="MMM/YYYY"
                                    display-timezone="UTC+05:30"
                                    value={selectedDate} onIonChange={e => setSelectedDate()}
                                ></IonDatetime>
                             <span>Hello</span>
                        </Col>
                    </Container>
                </Popover.Content>
        </Popover>
        );

    
    const NameList = 
    [
        { id: 1,name: 'Alicia Gojani' }, { id: 2, name:'Anna Behm'},{id: 3, name: 'Cara Bianco'}, { id: 4, name:'Diana Lauser'}, { id: 5, name:'Ellen Klotz'},
        { id: 6, name:'Hoa Thi Le Trinh'}, { id: 7, name:'Irene Gantert'}, { id: 8, name:'Nadine Haring'}, { id: 9, name:'Sven Gallenbach'}, { id: 10, name:'Vanessa Fehr'},
    ];


function SettingCommunitySecond() {
   const [ProfileChanges, setProfileChanges] = useState(false);
   const [CloudChangesData, setCloudChangesData] = useState(false);
   const [EmployeeInCloud, setEmployeeInCloud] = useState(false);


   const EmployeeNames = (
    <Nav  variant="pills" className="flex-column custMainBox .nav-link.active">
      {NameList.map((NameList) => <IonItemSliding  key={NameList.id}>
          
         <IonItem >                                        
                <IonLabel><Link to="/settingcommunity" className="CustNavDesginForm">{NameList.name}<img src={greencircle} className="listIcon nav-link"/></Link></IonLabel>
            </IonItem>

            <IonItemOptions side="end">
                <IonItemOption className="CustSlideDesgin" onClick={() => this.handleModalSyncCloud("CloudChangesData")} expandable>
                        Delete
                </IonItemOption>
            </IonItemOptions>
    </IonItemSliding> 
       
      )}  
       <Nav.Item>
            <Button className="btnDesginForm">Time recording mode</Button>
        </Nav.Item>                  
    </Nav>
  );



  return(
    <>
    
    <Col md={12} className="border-bottom border-dark">
        <Navbar className="navigationDesginForm p-0">
            <Nav className="justify-content-start w-100">
               
               {/* All three col are used in navigation bar  */}
                <Col md={3} className="border-end border-dark p-1">
                    <div className="d-flex">
                        <div>
                            <Nav.Item>
                                <Link to="#" className="p-1 mt-1">
                                    <img src={AddIconRed} onClick={() => setProfileChanges(!ProfileChanges)} className="NavIcon Normal"/>
                                </Link>
                            </Nav.Item>
                        </div>
                        <div>
                            <Nav.Item>
                                <Link to="#" className="p-2 mt-1">
                                    <span className="textCustForm">Dres H u S Gallenbach</span>
                                </Link>
                            </Nav.Item>
                        </div>
                        <div> 
                            <Nav.Item>
                                <OverlayTrigger trigger="focus" placement="bottom" overlay={SettingPopover}>
                                    <Nav.Link className="p-1 mt-1">
                                        <img src={AddIconRed} className="NewIconDesgin Normal"/>
                                    </Nav.Link>
                                </OverlayTrigger>
                            </Nav.Item>
                        </div>
                    </div>
                </Col>

                <Col md={7}>                       
                    <div className="d-flex justify-content-center mt-2">
                        <Nav.Item>
                            <Link to="/homepage">
                            <span className="textCustForm">Alicia Gojani</span>
                            </Link>
                        </Nav.Item>
                    </div>
                </Col>

                <Col md={2}>
                <div className="d-flex justify-content-around">
                        <Nav.Item>
                            <Nav.Link>
                                <img src={CloudIconRed} onClick={() => setEmployeeInCloud(!EmployeeInCloud)} className="OtherSideIcon Normal"/>
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Link to="/homepage">
                                <img src={InfoIconRed} className="OtherSideIcon Normal"/>
                            </Link>
                        </Nav.Item>

                        <Nav.Item>
                                <Nav.Link>
                                    <img src={SettingIconRed} className="OtherSideIcon Normal"/>
                                </Nav.Link>
                        </Nav.Item>
                    </div>                            
                </Col>
            </Nav>
        </Navbar>
    </Col>
    
    <Container fluid >
        <Col md={12}>
            <Row >
                <Col md={12}>
                    <Row>
                        {/* This col is for list of all employee */}
                        <Tab.Container>
                            <Col md={3} className="border-end border-dark communityDesgin">
                                {EmployeeNames}   
                            </Col>
                        </Tab.Container>
                    
                        {/* This col having table code inside it  */}
                        <Col md={9}>
                            <Row className="border-top border-bottom border-dark">                        
                                <Table className="tblCustDesginSecondPage" borderless={true}>
                                    <thead className="border-bottom border-dark">
                                        <tr>
                                            <th><img src={CloudBalckIcon} className="TableFormIcon Normal"/></th>
                                            <th>Date</th>
                                            <th>Start</th>
                                            <th>End </th>
                                            <th>Pause</th>
                                            <th>Net</th>
                                            <th>Gross </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th>12.04.2021</th>
                                            <th>08:42</th>
                                            <th>13.34</th>
                                            <th>0 min</th>
                                            <th>4.9 h</th>
                                            <th>4.9 h</th>
                                        </tr>

                                        <tr>
                                            <th></th>
                                            <th>1.04.2021</th>
                                            <th>08:45</th>
                                            <th>19.04</th>
                                            <th>1.2 h</th>
                                            <th>9.1 h</th>
                                            <th>10.3 h</th>
                                        </tr>

                                        <tr>
                                            <th><img src={CheckedCircleIconGreen} className="TableFormIcon Normal"/></th>
                                            <th>31.03.2021</th>
                                            <th>08:45</th>
                                            <th>13.34</th>
                                            <th>0 min</th>
                                            <th>4.8 h</th>
                                            <th>4.8 h</th>
                                        </tr>

                                        <tr>
                                            <th><img src={CheckedCircleIconGreen} className="TableFormIcon Normal"/></th>
                                            <th>30.03.2021</th>
                                            <th>08:45</th>
                                            <th>13.34</th>
                                            <th>0 min</th>
                                            <th>4.8 h</th>
                                            <th>4.8 h</th>
                                        </tr>

                                        <tr>
                                            <th><img src={CheckedCircleIconGreen} className="TableFormIcon Normal"/></th>
                                            <th>29.03.2021</th>
                                            <th>08:45</th>
                                            <th>13.34</th>
                                            <th>46 min</th>
                                            <th>9.7 h</th>
                                            <th>10.4 h</th>
                                        </tr>

                                        <tr>
                                            <th><img src={CheckedCircleIconGreen} className="TableFormIcon Normal"/></th>
                                            <th>25.03.2021</th>
                                            <th>08:45</th>
                                            <th>13.34</th>
                                            <th>0 min</th>
                                            <th>4.8 h</th>
                                            <th>4.8 h</th>
                                        </tr>

                                        <tr>
                                            <th><img src={CheckedCircleIconGreen} className="TableFormIcon Normal"/></th>
                                            <th>24.03.2021</th>
                                            <th>08:46</th>
                                            <th>13.34</th>
                                            <th>0 min</th>
                                            <th>4.8 h</th>
                                            <th>4.8 h</th>
                                        </tr>

                                        <tr>
                                            <th><img src={CheckedCircleIconGreen} className="TableFormIcon Normal"/></th>
                                            <th>23.03.2021</th>
                                            <th>08:46</th>
                                            <th>13.34</th>
                                            <th>0 min</th>
                                            <th>4.8 h</th>
                                            <th>4.8 h</th>
                                        </tr>
                                    </tbody>
                                </Table>              
                            </Row>    
                                <Col md={12} className="d-flex justify-content-end">
                                    <Row>
                                       <span className="TableTextDesgin"> 
                                            13.9 h
                                            (Gross: 15.2 h)
                                        </span>    
                                    </Row>                                                    
                                </Col>
                                <Col md={12}>
                                    <Col md={10}>
                                    <div className="d-flex DateTextFormSecond justify-content-end">
                                        
                                        <div> 
                                            <OverlayTrigger trigger="click" placement="top" overlay={DatePickerPopover}>
                                                <img src={LessThanIconGray} className="NavIcon Normal"/>
                                            </OverlayTrigger>
                                        </div>
                                        
                                        <div>
                                        <OverlayTrigger trigger="click" placement="top" overlay={DatePickerPopover}>
                                                <span>Click here </span>
                                            </OverlayTrigger>
                                        </div>
                                        
                                        <div> 
                                           <OverlayTrigger trigger="click" placement="top" overlay={DatePickerPopover}>
                                                   <img src={GreaterThanIconGray} className="NewIconDesgin Normal"/>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    </Col>
                                </Col>
                       
                        </Col>
                    </Row>
                </Col>
               
            </Row>
        </Col>
    </Container>
    
    {/* Modal Pop-Up start from here */}
    <Modal  show={ProfileChanges} onHide={() => setProfileChanges(!ProfileChanges)}> 
       <Modal.Header className="HeadDesginFrom">
            <Container>
                <Col md={12}>
                    <Row>
                        <Col md={3} className="d-flex justify-content-start">
                            <div>
                                <span className="ModalText" onClick={() => setProfileChanges(!ProfileChanges)}>Abort</span>
                            </div>
                        </Col>

                        <Col md={5} className="d-flex justify-content-center">
                            <div>
                                <span className="ModalTextHName">
                                    Alicia Gojani
                                </span>
                            </div>
                        </Col>

                        <Col md={4} className="d-flex justify-content-end">
                            <div>
                                <span className="ModalText" onClick={() => setProfileChanges(!ProfileChanges)}>To Back Up</span>
                            </div>
                            
                        </Col>
                    </Row>
                </Col>
            </Container>
        </Modal.Header>
        <Modal.Body className="tModalDesginFrom p-0" >
            <Container fluid className="CustRowModal">
                <Col md={12}>
                    <Row >
                        <Col md={2}>
                            <span className="spanTextForm">Name</span>
                        </Col>
                        <Col md={10}>
                            <Form>
                                <Form.Control type="text" className="CustDesginTextBox CustTextBoxForm" placeholder="Alicia Gojani"/>
                            </Form>
                        </Col>
                    </Row>
                </Col>
                    <div className="border-bottom border-dark mt-1 mb-1"></div>
                <Col md={12}>
                    <Row>
                        <Col md={2}>
                            <span  className="spanTextForm">Pin</span>
                        </Col>
                        <Col md={10}>
                            <Form>
                                <Form.Control type="text" className="CustDesginTextBox CustTextBoxForm" placeholder="8807"/>
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <div className="border-bottom border-dark"></div>
            </Container>

            <Container fluid className="tModalDesginFrom p-0">
              <Row>
                <Col md={12}>
                    <Button className="CustButtonForm">Delete Employee</Button>
                </Col>
              </Row>
            </Container>
        </Modal.Body>
        </Modal>


    {/* This Modal pop up is of Cloud Sync */}
    <Modal size="sm" show={CloudChangesData} onHide={() => setCloudChangesData(!CloudChangesData)} centered> 
       {/* <Modal.Header className="SecondModalDesgin">
        </Modal.Header> */}
        <Modal.Body className="MainModalDeleteEmployee p-1">
            
            <Container fluid className="CustModalSecondForm">
                <Col sm={12}>
                    <Row>
                        <Col sm={12} className="d-flex p-0 ">
                            <div className="sm-12">
                                <span className="modalSpanForm"> Remove employee Mocot</span><br/>
                                <span>
                                    Do you really want to remove
                                    Vanessa Fehr from the barn?
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} className="">
                    <Row className="mt-4 border-top">
                        <Col sm={12}>
                            <Button onClick={() => setCloudChangesData(!CloudChangesData)} className="modalButtonTextForm">Remove employee</Button>
                        </Col>
                    </Row>
                </Col>
                
                <Col md={12}>
                    <Row  className="border-top ">
                        <Col sm={12}>
                            <Button onClick={() => setCloudChangesData(!CloudChangesData)} className="modalButtonTextForm">Abort</Button>
                        </Col>
                    </Row>
                </Col>

            </Container>
        </Modal.Body>
    </Modal>

    {/* This modal pop up is to add members in cloud  */}
    <Modal  show={EmployeeInCloud} onHide={() => setEmployeeInCloud(!EmployeeInCloud)}> 
       <Modal.Header className="SecondModalDesgin">
         <Container fluid>
             <Col md={12}>
                 <Row>
                    <Col md={8}>
                        <div>
                            <span className="d-flex justify-content-end">Synchronize employees</span>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div>
                            <span className="ModalText d-flex justify-content-end" onClick={() => setEmployeeInCloud(!EmployeeInCloud)}>Finished</span>
                        </div>
                    </Col>
                 </Row>
             </Col>
         </Container>
        </Modal.Header>
        <Modal.Body className="MainModalFormSync">
            <Container>
                <Col md={12}>
                    <Row>
                        <Col md={4}>
                            <span>All Employees</span>
                        </Col>
                        <Col md={8}>
                            <Form.Check className="CustCheckForm d-flex justify-content-end" type="checkbox"/> 
                        </Col>
                    </Row>
                </Col>
                <div className="border-bottom mt-3"></div>

                <Col md={12}>
                    <Row className="mt-4">
                        <Col md={4}>
                            <span>Vanessa Fehr</span>
                        </Col>
                        <Col md={8}>
                            <Form.Check className="CustCheckForm d-flex justify-content-end" type="checkbox"/> 
                        </Col>
                    </Row>
                </Col>

            </Container>
        </Modal.Body>
    </Modal>

    </>
)
}

export default SettingCommunitySecond; 

    
