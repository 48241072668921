import React, { Component,useState ,useEffect} from 'react';
import { TextField } from '@material-ui/core';
import { Col, Container, Row } from 'react-bootstrap';
import {IonList, IonItemSliding, IonItem, IonLabel, IonItemOptions, IonItemOption, IonIcon, IonNote,IonToggle } from '@ionic/react';
import NoEntryIcon from '../asset/images/NavbarIcons/no-entry.svg';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { dynamicGetApi,dynamicPostApi } from '../api';
import { useCookies } from 'react-cookie';
import { FaTrash,FaEdit } from "react-icons/fa";

function SettingAutomaticBreaks(props) {
    const [showTextBoxItem, setshowTextBoxItem] = useState(false);
    const [BreakModel, setBreakModel] = useState(false);
    const [BreaksList, setBreaksList] = useState([]);
    const [cookies] = useCookies(['EmployeeID']);
    const [selectedRownum, setSelectedRownum] = useState(null);
    const [BreakobjForEdit, setBreakobjForEdit] = useState("");
    const [ShowEditModel, setShowEditModel] = useState(false);
    useEffect(() => {
        if(props.AddAutomicPerson===true)
        {
            setBreakModel(true)
        }
    }, [props.AddAutomicPerson]);

    useEffect(() => {
        if(BreakModel===false)
        {
            props.setAddAutomicPerson(false)
            getList()
        }
    }, [BreakModel]);

   useEffect(() => {
         getList()
   }, []);
   const getList=async()=>{
        // var data={
        //     id:cookies.EmployeeID
        // }
        var response = await dynamicGetApi('api/Mobile/AutomaticBreaks/AutomaticBreaksList')
        if(!!response&&response.status=== 200)
        {
            setBreaksList(response.data)
        }
   }
   const DeleteBreak=async(id)=>{
        var data={
            id:id
        }
        var response = await dynamicPostApi(data,'api/Mobile/AutomaticBreaks/DeleteAutomaticBreaks')
        if(!!response&&response.status=== 200)
        {
            getList()        
        }
   }
   const EditBreak=async(val)=>{
        setBreakobjForEdit(val)
        setShowEditModel(true)
   }
    return(
        <>
            {
                BreakModel===true?
                <BreaksModel setBreakModel={setBreakModel} BreakModel={BreakModel}/>
                :null
            }
            {
                ShowEditModel===true?
                <EditBreaksModel BreakobjForEdit={BreakobjForEdit} getList={getList} setShowEditModel={setShowEditModel} ShowEditModel={ShowEditModel}/>
                :null
            }
            <Container fluid className="BasicBoxThree">
                <Row className="EspicallyRowDesgin">
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                            <div className='showTable'>
                                    <table className='table'>
                                        <tbody>
                                           { 
                                            !!BreaksList&&BreaksList.length>0?BreaksList.map((val,index) =>{
                                                 
                                                 return <tr>
                                                            <td >{val.name} ({val.durationMinutes} Min)</td>
                                                            <td className='text-center'>
                                                                <a className="btnTblAction bgGrey" onClick={() => EditBreak(val)}><FaEdit /></a>
                                                                <a className="btnTblAction bgRed"  onClick={() => DeleteBreak(val.id)}><FaTrash /></a>
                                                            </td>
                                                         </tr>
                                                })
                                                :null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            {/* {
                                !!BreaksList&&BreaksList.length>0?BreaksList.map((val,key)=>{
                                    return  
                                    
                                    // <IonItemSliding>
                                    //             <IonItem className={key === selectedRownum ? "Rowselected" : null} onClick={() => setSelectedRownum(key)}>
                                    //             {
                                    //                 showTextBoxItem ?
                                    //                 <>  
                                    //                     <img src={NoEntryIcon} className="NavIcon Normal"/>&nbsp;   
                                    //             </>
                                    //             : null
                                    //             }
                                                    
                                    //                 <IonLabel>{val.name} ({val.durationMinutes} Min)</IonLabel>
                                    //             </IonItem>
                
                                    //             <IonItemOptions side="end">
                                    //                 <IonItemOption onClick={()=>DeleteBreak(val.id)} className="CustSlideDesgin" expandable>
                                    //                         Delete
                                    //                 </IonItemOption>
                                    //             </IonItemOptions>
                                    //         </IonItemSliding>
                                })
                                :null
                            } */}
                           </Col>
                        </Row>
                    </Col>
                    <Col md={12}>
                        <Row>
                            <Button onClick={() => setshowTextBoxItem(!showTextBoxItem)} className="CustDesginButtonEdit">To Edit</Button>
                            {
                                    showTextBoxItem ?
                                    <>  
                                        <Button className="CustDesginButtonEdit" onClick={() => setshowTextBoxItem(!showTextBoxItem)}>Finsihed</Button>
                                   </>
                                : null
                                }
                            

                        </Row>
                    </Col>
                </Row>
                {/* {
                    this.state.showTextBoxItem ?
                        <>
                            <IonItem>
                                <img src={NoEntryIcon} className="NavIcon Normal"/>&nbsp;
                            </IonItem>
                        </>
                    : null
                } */}
            </Container>
        </>
    )
}

export default SettingAutomaticBreaks;

function EditBreaksModel(props)
{
    const [minutes, setMinutes] = useState(props.BreakobjForEdit.durationMinutes);
    const [name, setName] = useState(props.BreakobjForEdit.name);
    const [cookies] = useCookies(['EmployeeID']);

    const save=async()=>{
        if(!!name&&minutes>=1)
        {
            var data={
                Id:props.BreakobjForEdit.id,
                name:name,
                DurationMinutes:parseInt(minutes)
            }
            var response = await dynamicPostApi(data,'api/Mobile/AutomaticBreaks/addAutomaticBreaks')
            if(!!response&&response.status=== 200)
            {
                props.getList()
                props.setShowEditModel(!props.ShowEditModel)
            }
        }
    }
    return (
        <Modal  show={props.ShowEditModel} onHide={() => props.setShowEditModel(!props.ShowEditModel)}> 
        <Modal.Header className="HeadDesginFrom">
             <Container>
                 <Col md={12}>
                     <Row>
                         <Col md={3} className="d-flex justify-content-start">
                             <div>
                                 <span className="ModalText" onClick={() => props.setShowEditModel(!props.ShowEditModel)}>Abort</span>
                             </div>
                         </Col>

                         <Col md={6} className="d-flex justify-content-center">
                             <div>
                                 <span className="ModalTextHName">
                                     Edit Automatic Pause
                                 </span>
                             </div>
                         </Col>
                     </Row>
                 </Col>
             </Container>
         </Modal.Header>
         <Modal.Body className="tModalDesginFrom" >
             <Container fluid className="CustRowModal">
                 <Col md={12}>
                     <Row>
                         <Col md={2} className="mb-2">
                             <span className="spanTextForm">Name</span>
                             
                         </Col>
                         <Col md={10}>
                             <Form>
                                 <Form.Control type="text" onChange={(e)=>setName(e.target.value)} defaultValue={props.BreakobjForEdit.name} className="CustDesginTextBox CustTextBoxForm" placeholder="Mitrag"/>
                             </Form>
                             <div ></div>
                         </Col>
                     </Row>
                     
                 </Col>
                 <div className="border-top border-dark"></div>
                     
                 <Col md={12}  >
                     <Row className="mt-3 ">
                         <Col md={6}>
                             <span  className="spanTextForm">Pause duaration (min)</span>
                         </Col>
                         <Col md={6}>
                             <Form>
                                 <Form.Control type="number" onChange={(e)=>setMinutes(e.target.value)} defaultValue={props.BreakobjForEdit.durationMinutes}  className="CustDesginTextBox CustTextBoxForm" placeholder="0"/>
                             </Form>
                         </Col>
                     </Row>
                 </Col>   
             </Container>

             <Container fluid className="p-0">
                 <Row>
                 <Col md={12}>
                     <Button className="CustButtonForm" onClick={() => save()} >Finished</Button>
                 </Col>
                 </Row>
             </Container>
         </Modal.Body>
         </Modal>
    )
}
function BreaksModel(props)
{
    const [minutes, setMinutes] = useState(0);
    const [name, setName] = useState("");
    const [cookies] = useCookies(['EmployeeID']);

    const save=async()=>{
        if(!!name&&minutes>=1)
        {
            var data={
                name:name,
                DurationMinutes:parseInt(minutes)
            }
            var response = await dynamicPostApi(data,'api/Mobile/AutomaticBreaks/addAutomaticBreaks')
            if(!!response&&response.status=== 200)
            {
                props.setBreakModel(!props.BreakModel)
            }
        }
    }
    return (
        <Modal  show={props.BreakModel} onHide={() => props.setBreakModel(!props.BreakModel)}> 
        <Modal.Header className="HeadDesginFrom">
             <Container>
                 <Col md={12}>
                     <Row>
                         <Col md={3} className="d-flex justify-content-start">
                             <div>
                                 <span className="ModalText" onClick={() => props.setBreakModel(!props.BreakModel)}>Abort</span>
                             </div>
                         </Col>

                         <Col md={6} className="d-flex justify-content-center">
                             <div>
                                 <span className="ModalTextHName">
                                     New Automatic Pause
                                 </span>
                             </div>
                         </Col>
                     </Row>
                 </Col>
             </Container>
         </Modal.Header>
         <Modal.Body className="tModalDesginFrom" >
             <Container fluid className="CustRowModal">
                 <Col md={12}>
                     <Row>
                         <Col md={2} className="mb-2">
                             <span className="spanTextForm">Name</span>
                             
                         </Col>
                         <Col md={10}>
                             <Form>
                                 <Form.Control type="text" onChange={(e)=>setName(e.target.value)} className="CustDesginTextBox CustTextBoxForm" placeholder="Mitrag"/>
                             </Form>
                             <div ></div>
                         </Col>
                     </Row>
                     
                 </Col>
                 <div className="border-top border-dark"></div>
                     
                 <Col md={12}  >
                     <Row className="mt-3 ">
                         <Col md={6}>
                             <span  className="spanTextForm">Pause duaration (min)</span>
                         </Col>
                         <Col md={6}>
                             <Form>
                                 <Form.Control type="number" onChange={(e)=>setMinutes(e.target.value)} className="CustDesginTextBox CustTextBoxForm" placeholder="0"/>
                             </Form>
                         </Col>
                     </Row>
                 </Col>   
             </Container>

             <Container fluid className="p-0">
                 <Row>
                 <Col md={12}>
                     <Button className="CustButtonForm" onClick={() => save()} >Finished</Button>
                 </Col>
                 </Row>
             </Container>
         </Modal.Body>
         </Modal>
    )
}
