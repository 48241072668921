import React, { useState,useEffect } from 'react';
import Container from 'react-bootstrap/Container'
import '../css/Navbar.css';
import Clock from 'react-live-clock';
// import Link from 'react-bootstrap/NavLink';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import NvaigationBar from '../components/Navigationbar';
import Modal from 'react-bootstrap/Modal';
import CheckedIconWhite from '../asset/images/NavbarIcons/checkedIconWhite.svg';
import UsersList from "../components/usersSidebarList"
import { login } from '../store/actions/appActions';
import { useDispatch } from 'react-redux';  // to set data inside store
import EmployeeDetails from './EmployeeDetails';
import { useCookies } from 'react-cookie';
import { BsFillNodePlusFill } from 'react-icons/bs';
import styled from 'styled-components';
import ListGroup from 'react-bootstrap/ListGroup';
import { dynamicGetApi,dynamicPostApi } from '../api';
import { CgChevronLeft } from 'react-icons/cg'; 
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

import Checkbox from '../components/checkbox';
import { useNavigate } from 'react-router-dom';



const TopHeaderContentDiv = styled.div`
display:flex;
width:50%;
justify-content:center;
align-items:center;
padding-top:15px;
border-right:1px solid #ccc
`



function Homepage() {
    let dispatch=useDispatch();
    const [ModalAfterSignDone, setModalAfterSignDone] = useState(false);
    const [ModalAfterPauseStarted, setModalAfterPauseStarted] = useState(false);
    const [ModalAfterPauseEnded, setModalAfterPauseEnded] = useState(false);
    const [ModalAfterFullDetail, setModalAfterFullDetail] = useState(false);
    const [DetailView, setDetailView] = useState(false);
    const [EmployeeIdFordetail, setEmployeeIdFordetail] = useState(0);
    const [empName, setEmpName] = useState("");
    const [ReloadListtrigger, setReloadListtrigger] = useState(false);
    const [ListLocationModel, setListLocationModel] = useState(false);
    const [Timetrackinfo, setTimetrackinfo] = useState({});
    const [cookies, setCookie, get] = useCookies(['EmployeeList','continueAs','FirstLoad','EmployeeID','TimeTrackStatusMessages','TimeTrackBreakDuration','TimeTrackIdForStatusInfo']);
    const [CurrentHolidayQuotta, setCurrentHolidayQuotta] = useState(0);
    const [CurrentBalance, setCurrentBalance] = useState(0);
    let Navigate=useNavigate();
    useEffect(() => {
        
        if(localStorage.getItem("token")===undefined)
        {
            Navigate('/login')
        }
        if(EmployeeIdFordetail!==0)
        {
            setDetailView(true)
        }
    }, [EmployeeIdFordetail]);
    useEffect(() => {
        if(!!cookies.EmployeeList&&cookies.EmployeeList.length>0)
        {
            setDetailView(false)
        }
    }, [cookies.EmployeeList]);
    useEffect(() => {
        checkInitial()
        if(!!cookies.continueAs&&cookies.continueAs==='single')
        {
            setEmployeeIdFordetail(parseInt(cookies.EmployeeID))
            setDetailView(true)
            UpdateCurrentHoliday(cookies.EmployeeID)
            UpdateCurrentRecord(cookies.EmployeeID)
        }
    }, []);
    // useEffect(() => {
    //     checkInitial()
    // }, [cookies.FirstLoad]);
    const checkInitial=()=>{

        if(!!localStorage.getItem("token")&&!!cookies.FirstLoad)
        {
            if(!!cookies.FirstLoad && cookies.FirstLoad==="true" && cookies.continueAs==="organisation")
            {
                setListLocationModel(true)
                setCookie('FirstLoad', false, { path: '/' })
            }
            else if(!!cookies.FirstLoad && cookies.FirstLoad==="true" && cookies.continueAs==='single')
            {
                setListLocationModel(true)
                setEmployeeIdFordetail(parseInt(cookies.EmployeeID))
                setCookie('FirstLoad', false, { path: '/' })
            }
        }
        else{
            localStorage.removeItem("token")
            Navigate('/login')
        }
    }
    const UpdateCurrentHoliday=async(empid)=>{
            var data={
                id:empid
            }
            var response=await dynamicPostApi(data,"api/Employee/GetRemainingVocationQuota")
            if (!!response && response.status === 200) {
                setCurrentHolidayQuotta(response.data)
            } 
    }
    const UpdateCurrentRecord=async(empid)=>{
            var data={
                id:empid
            }
            var response=await dynamicPostApi(data,"api/Employee/GetCurrentRecord")
            if (!!response && response.status === 200) {
                setCurrentBalance(response.data)
            } 
    }
    useEffect(async() => {
        if(cookies.TimeTrackStatusMessages==='Start')
        {
            setModalAfterSignDone(true)
            setCookie("TimeTrackStatusMessages","",{ path: '/' })
        }
        else if(cookies.TimeTrackStatusMessages==='startPause')
        {
            setModalAfterPauseStarted(true)
            setCookie("TimeTrackStatusMessages","",{ path: '/' })
        }
        else if(cookies.TimeTrackStatusMessages==='endPause')
        {
            setModalAfterPauseEnded(true)
            setCookie("TimeTrackStatusMessages","",{ path: '/' })
        }
        else if(cookies.TimeTrackStatusMessages==='endtracking')
        {
            var data={
                id:cookies.TimeTrackIdForStatusInfo,
            }
            var response = await dynamicPostApi(data,'api/Mobile/Home/GetEmployeeTimeTrackingByID')
            if(!!response&&response.status===200)
            {
                setTimetrackinfo(response.data);
                setModalAfterFullDetail(true)
            }
            
            setCookie("TimeTrackStatusMessages","",{ path: '/' })
            setCookie("TimeTrackIdForStatusInfo","",{ path: '/' })
        }
    }, [cookies.TimeTrackStatusMessages]);
    return(
        <>
            <NvaigationBar/>
            {
                ListLocationModel===true?
                <ListOfLocationsModal />
                :null
            }
            <>
                <Container fluid>
                    <Col md={12}>
                
                        {
                            cookies.continueAs==='single'?
                            <>
                            
                                <div className='d-flex' style={{background:"#e0f9f4"}}>
                                    <TopHeaderContentDiv>
                                        <div className='text-center'>
                                        <h6>Gesamte Uberstunden</h6>
                                        <p className='text-danger'>{CurrentBalance} h</p>
                                        </div>
                                    </TopHeaderContentDiv>
                                    <TopHeaderContentDiv>
                                    <div className='text-center'>
                                    <h6 >Unverglander Urlaasb</h6>
                                    <p className='text-dark'>{CurrentHolidayQuotta} days</p>
                                    </div>
        
                                    </TopHeaderContentDiv> 
                                </div>
                                <Row>
                                    {/* <Col md={4} className="mb-3 border-end border-dark">
                                    <UsersList ReloadListtrigger={ReloadListtrigger} fnDetailView={setDetailView} DetailView={DetailView} setEmpName={setEmpName} EmployeeIdFordetail={EmployeeIdFordetail} fnEmployeeIdFordetail={setEmployeeIdFordetail}/>
                                    </Col> */}
                                    {
                                        DetailView===true?
                                        <EmployeeDetails setReloadListtrigger={setReloadListtrigger} setDetailView={setDetailView} ReloadListtrigger={ReloadListtrigger} empName={empName} EmployeeIdFordetail={EmployeeIdFordetail}/>
                                        :
                                        <Col md={12} className="mb-3 ">
                                            <Row>
                                                <Clock format={'HH:mm'} ticking={true}  className="desginClock d-flex justify-content-center "/>
        
                                                <div className="d-flex justify-content-center textdesgin">
                                                    <span>Tap on your name on the left in the list select an action and confirm it </span>
                                                </div>
                                            </Row>
                                        </Col> 
                                    }
                                </Row>
                            </>
                                :null
        
                        }
                        {
                            cookies.continueAs==='organisation'?
                                <Row>
                                    <Col md={4} className="mb-3 border-end border-dark">
                                    <UsersList ReloadListtrigger={ReloadListtrigger} fnDetailView={setDetailView} DetailView={DetailView} setEmpName={setEmpName} EmployeeIdFordetail={EmployeeIdFordetail} fnEmployeeIdFordetail={setEmployeeIdFordetail}/>
                                    </Col>
                                    {
                                        DetailView===true?
                                        <EmployeeDetails setReloadListtrigger={setReloadListtrigger} setDetailView={setDetailView} ReloadListtrigger={ReloadListtrigger} empName={empName} EmployeeIdFordetail={EmployeeIdFordetail}/>
                                        :
                                        <Col md={8} className="mb-3 ">
                                            <Row>
                                                <Clock format={'HH:mm'} ticking={true}  className="desginClock d-flex justify-content-center "/>
        
                                                <div className="d-flex justify-content-center textdesgin">
                                                    <span>Tap on your name on the left in the list select an action and confirm it </span>
                                                </div>
                                            </Row>
                                        </Col> 
                                    }
                                </Row>
                                :null
                        }
                    </Col>
                </Container>
                {
                    ModalAfterSignDone===true?
                        <Modal size="sm" show={ModalAfterSignDone} onHide={() => setModalAfterSignDone(!ModalAfterSignDone)} centered> 
                                <Modal.Body className="ModalHomePage">
                                    <Container fluid>
                                    <Col sm={12}>
                                        <Row>
                                            <Col sm={12} className="mt-3">
                                                <img src={CheckedIconWhite} className="ModalIconHome"/>
                                            </Col>
                
                                            <Col sm={12} className="mt-3">
                                                <span className="SpanTextDesginHome">Working time started.</span><br/>
                                                <span className="SpanTextDesginHome">(Tap Somewhere to go back)</span>
                                            </Col>  
                                        </Row>
                                    </Col>
                                    </Container>
                                </Modal.Body>
                        </Modal>
                    :null
                }
                {
                  ModalAfterPauseStarted===true?
                    <Modal size="sm" show={ModalAfterPauseStarted} onHide={() => setModalAfterPauseStarted(!ModalAfterPauseStarted)} centered> 
                            <Modal.Body className="ModalHomePage">
                                <Container fluid>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={12} className="mt-3">
                                            <img src={CheckedIconWhite} className="ModalIconHome"/>
                                        </Col>
                
                                        <Col sm={12} className="mt-3">
                                            <span className="SpanTextDesginHome">Pause Started.</span><br/>
                                            <span className="SpanTextDesginHome">(Tap Somewhere to go back)</span>
                                        </Col>
                                    </Row>
                                </Col>
                                </Container>
                            </Modal.Body>
                        </Modal>
                    :null
                }
                {
                    ModalAfterPauseEnded===true?
                        <Modal size="sm" show={ModalAfterPauseEnded} onHide={() => setModalAfterPauseEnded(!ModalAfterPauseEnded)} centered> 
                            <Modal.Body className="ModalHomePage">
                                <Container fluid>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={12} className="mt-3">
                                            <img src={CheckedIconWhite} className="ModalIconHome"/>
                                        </Col>
                
                                        <Col sm={12} className="mt-3">
                                            <span className="SpanTextDesginHome">Pause Ended: {cookies.TimeTrackBreakDuration}.</span><br/>
                                            <span className="SpanTextDesginHome">(Tap Somewhere to go back)</span>
                                        </Col>
                                    </Row>
                                </Col>
                                </Container>
                            </Modal.Body>
                        </Modal>
                    :null
                }
                {
                    ModalAfterFullDetail===true?
                        <Modal size="sm" show={ModalAfterFullDetail} onHide={() => setModalAfterFullDetail(!ModalAfterFullDetail)} centered> 
                            <Modal.Body className="ModalHomePage">
                                <Container fluid>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={12} className="mt-3">
                                            <img src={CheckedIconWhite} className="ModalIconHome"/>
                                        </Col>
                                    </Row>
                                </Col>
                                        <div className="border mt-3 mb-4"></div>
                                <Col sm={12}>
                                    <Row >
                                        <Col sm={12}>
                                                <span className="SpanTextDesginHome ">Start at: {Timetrackinfo.startTime}</span><br/>
                                        </Col>
                                    </Row>
                
                                    <Row >
                                        <Col sm={12}>
                                                <span className="SpanTextDesginHome ">End at: {Timetrackinfo.endTime}</span><br/>
                                        </Col>
                                    </Row>
                
                                    <Row >
                                        <Col sm={12}>
                                                <span className="SpanTextDesginHome ">Total Break: {Timetrackinfo.pause} h</span><br/>
                                        </Col>
                                    </Row>
                
                                    <Row >
                                        <Col sm={12}>
                                                <span className="SpanTextDesginHome ">Net Time: {Timetrackinfo.net} h</span><br/>
                                        </Col>
                                    </Row>
                
                                    <Row className="mt-4">
                                        <Col sm={12}>
                                                <span className="SpanTextDesginHome ">(Tap any place to return)</span><br/>
                                        </Col>
                                    </Row>
                                </Col>
                                </Container>
                            </Modal.Body>
                        </Modal>
                    :null
                }
            </>
        </>
    )
}

export default Homepage;

const Header = styled.div`
background:#48c9b0;
`
const TransparentBtn = styled.button`
background:transparent;
border:0;
font-size:14px;
color:#fff;
`
function ListOfLocationsModal(props) {

    const [show, setShow] = useState(true);
    const [LocationList, setLocationList] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [cookies, setCookie,removeCookie] = useCookies(['continueAs','FirstLoad','EmployeeList']);
    const [EmployeeList, setEmployeeList] = useState([]);
    const [steps, setSteps] = useState(1);
    const [SelectedEmployee, setSelectedEmployee] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(true);


    // const [organisationEmpList, setOrganisationEmpList] = useState(false);
    useEffect(async() => {
        var response = await dynamicGetApi('api/Mobile/Admin/GetEmployeeLocations')
        if(!!response&&response.status===200)
        {
            setLocationList(response.data)
            if(response.data.length===1)
            {
                selectLocation(response.data[0].id,response.data[0].name)
            }
        }
    }, []);
    const selectLocation=async(id,name)=>{
        var data={
            id:id
        }
        var response = await dynamicPostApi(data,'api/Mobile/Admin/UpdateToken')
        localStorage.setItem("token",response.data)
        removeCookie('EmployeeList',{path:'/'})
        setCookie("locationId",id,{ path: '/' });
        setCookie('CompanyName', name, { path: '/' });
        if(cookies.continueAs==="organisation")
        {
            var responses = await dynamicGetApi('api/Mobile/Home/EmployeeList')
            if(!!responses && responses.data.length > 0)
            {
                let y = [];
                responses.data.map(x => y.push({...x , selected:true}))
                setIsSelectAll(true)
                setEmployeeList(y)
                setSelectedEmployee(y)
                setCookie('EmployeeList',y,{path:'/'})

                setSteps(2)
            }
        }else{
            setShow(false);
        }
    }
   const SelectEmployee=(emp,checked)=>{
    if(checked)
    {
        let prevState = [...EmployeeList];
        let index = EmployeeList.findIndex(x => x.empId ===  emp.empId);
        if(index != -1){
            prevState[index].selected = true;
            setEmployeeList(prevState);

           if(EmployeeList.filter(x => x.selected === true).length ===  EmployeeList.length){
            setIsSelectAll(true)
           }
        }

        setSelectedEmployee([...SelectedEmployee,emp]);
    }else{
       let newList= SelectedEmployee.filter(function(employee) { 
            return employee.empId !== emp.empId 
        })
        setSelectedEmployee(newList);
        let prevState = [...EmployeeList];
        let index = EmployeeList.findIndex(x => x.empId ===  emp.empId);
        if(index != -1){
            prevState[index].selected = false;
            setEmployeeList(prevState);
            setIsSelectAll(false)
        }

        
    }}
    useEffect(() => {
        setCookie('EmployeeList',SelectedEmployee,{path:'/'})
    }, [SelectedEmployee]);
    const finish=async ()=>{
        var EmpIds=cookies.EmployeeList.map(x=>x.empId);
        var response = await dynamicPostApi(EmpIds,'api/Mobile/Home/UpdateEmployeeLoginType')
        if(!!response && response.status=== 200)
        {
            if(cookies.EmployeeList.length>0)
            {
                setShow(false);
            }
        }
    }

    return (
        
            cookies.continueAs==="organisation" ?
            
            <Modal
            backdrop="static"
            keyboard={false}
             show={show} onHide={handleClose} contentClassName="bg-transparent rounded-0 border-0 ">
                { steps > 1 ? 
                
                <Modal.Header style={{backgroundColor:"#48c9b0"}} className='py-3 px-2 justify-content-between'>
                    <TransparentBtn onClick={() => setSteps(1)}>
                       <CgChevronLeft fontSize={20} /> Zuruck
                    </TransparentBtn>
              <Modal.Title className='h6 text-center text-white border-bottom-0'> Mitarbeiter synchronisieren</Modal.Title>
              <TransparentBtn onClick={()=>finish()}>
                Fertig
              </TransparentBtn>
            </Modal.Header> :
            
            <Modal.Header style={{backgroundColor:"#48c9b0"}} className='py-3 justify-content-center'>
              <Modal.Title className='h6 text-center text-white border-bottom-0'> Wahle einen Standort</Modal.Title>
            </Modal.Header>}
              
            <Modal.Body className='p-0'>
                {
                    steps > 1 ?
                    <>
                    <div className='d-flex justify-content-between align-items-center bg-white p-3 border-bottom border-dark'>
                        <h6 className='mb-0'>Alle Mitarbeiter</h6>
                        <Form.Group controlId="SelectAllCheckbox">
                            <Form.Check type="checkbox" checked={isSelectAll} onChange={(e) => { 
                                
                                setIsSelectAll(e.target.checked);
                                let prevState = [...EmployeeList];
                                
                                let newList = prevState.map(x => { return {...x, selected: e.target.checked}});
                                setEmployeeList(newList);

                                if(e.target.checked){
                                    setSelectedEmployee(newList);
                                } else {

                                    let newList2 = newList.filter(function(employee) { 
                                        return employee.selected === true 
                                    })
                                    setSelectedEmployee(newList2);
                                }
                              
                                }} label="" />
                        </Form.Group>
                    </div> 
                    <ListGroup style={{maxHeight:"430px" , overflowY:"auto"}} variant="flush">
                        {
                            !!EmployeeList&&EmployeeList.length>0?EmployeeList.map((value,key)=>{
                                return  <ListGroup.Item className='py-3 addgreenHover d-flex justify-content-between align-items-center'  >
                                    {value.empName}
                                    <Form.Group controlId={value.empId}>
                                        <Form.Check type="checkbox" checked={value.selected} label="" onChange={(e)=>SelectEmployee(value,e.currentTarget.checked)}/>
                                    </Form.Group>
                                    </ListGroup.Item>
                            })
                            :null
                        }
             
            
                    </ListGroup>
                    </>
                    : 
                    <ListGroup variant="flush">
                {
                    !!LocationList&&LocationList.length>0?LocationList.map((value,key)=>{
                        return  <ListGroup.Item className='py-3 addgreenHover' onClick={()=>selectLocation(value.id,value.name)} >{value.name}</ListGroup.Item>
                    })
                    :null
                }
         
        
        </ListGroup>
                }
            
            </Modal.Body>
           
          </Modal>
            
            : 
            <Modal
            backdrop="static"
            keyboard={false}
             show={show} onHide={handleClose} contentClassName="bg-transparent rounded-0 border-0 ">
              <Modal.Header style={{backgroundColor:"#48c9b0"}} className='py-3 justify-content-center'>
              <Modal.Title className='h6 text-center text-white border-bottom-0'> Wahle einen Standort</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
            <ListGroup variant="flush">
                {
                    !!LocationList&&LocationList.length>0?LocationList.map((value,key)=>{
                        return  <ListGroup.Item className='py-3' onClick={()=>selectLocation(value.id,value.name)} >{value.name}</ListGroup.Item>
                    })
                    :null
                }
         
        
        </ListGroup>
            </Modal.Body>
           
          </Modal>
        
      
     );
}


{/* <div className="justify-content-center">
<span>Tap on your name on the left in the list select an action and confirm it </span>
</div> */}